import { ChainId, ERC20Token } from '@pancakeswap/sdk'

export interface IVestingTime {
  title: string
  amountVested: number | string
  remaining: number | string
  yourCurrentXOX: number | string
  startTime: number[]
  statusClaim: boolean
  round?: number
}

export interface IYourInfo {
  id: number
  title: string
  amount: string
  field?: string
}

export interface IRefInfo {
  id: number | string
  title: string
  amountInit: string
  account?: string
  rewardXOXS?: string
  totalTransactionApplyReferral?: string
}

export interface Start {
  id: number
  title: string
  amount: string
  icon?: string
  field?: string
  decimal?: number
}

export enum TYPE_BY {
  BY_ERC20,
  BY_ETH,
}

export const USDC_TEST = '0xD898D309dAb33130EA57E8F106238ae4b76329f4'

export const NATIVE_TOKEN = '0x0000000000000000000000000000000000000000'
export const ADDRESS_CODE = '0x0000000000000000000000000000000000000000'

export enum ROUND {
  NOT_START,
  ONE,
  TWO,
  THREE,
}

export enum SALE_ROUND {
  ONE,
  TWO,
  THREE,
}
export const vestingTiming: IVestingTime[] = [
  {
    title: 'Sale 1',
    amountVested: 0,
    remaining: 0,
    yourCurrentXOX: 0,
    startTime: [],
    statusClaim: false,
    round: ROUND.ONE,
  },
  {
    title: 'Sale 2',
    amountVested: 0,
    remaining: 0,
    yourCurrentXOX: 0,
    startTime: [],
    statusClaim: false,
    round: ROUND.TWO,
  },
  {
    title: 'Sale 3',
    amountVested: 0,
    remaining: 0,
    yourCurrentXOX: 0,
    startTime: [],
    statusClaim: false,
    round: ROUND.THREE,
  },
]

export const initialRefInfo = [
  {
    id: 1,
    title: 'XOXS amount received from Referral',
    field: 'bonusXOXSByReferral',
  },
  {
    id: 2,
    title: 'Number of transactions applying your referral code',
    field: 'totalTransactionApplyReferral',
  },
]

export const initialTokenMetrics = [
  {
    id: 1,
    title: 'Team allocation (0% at TGE & 5 years vesting 20% Yearly)',
    tokenAllocationPercent: '7',
    tokenAllocation: '12600000',
    tge: '0',
    tokenAllocationatTge: '',
    initialMarketcap: '',
    tenMinsDelay: '',
    fullyDilitedMc: '',
  },
  {
    id: 2,
    title: 'Company Reserve (0% at TGE & 4 years vesting 25% Yearly)',
    tokenAllocationPercent: '5.4',
    tokenAllocation: '9720000',
    tge: '0',
    tokenAllocationatTge: '',
    initialMarketcap: '',
    tenMinsDelay: '',
    fullyDilitedMc: '',
  },
  {
    id: 3,
    title: 'Strategic Allocations (0% at TGE & 5 years vesting 20% Yearly)',
    tokenAllocationPercent: '5',
    tokenAllocation: '9000000',
    tge: '0',
    tokenAllocationatTge: '',
    initialMarketcap: '',
    tenMinsDelay: '',
    fullyDilitedMc: '',
  },
  {
    id: 4,
    title: 'Ecosystem Growth (10% at TGE & 4 years vesting 22.5% Yearly)',
    tokenAllocationPercent: '7',
    tokenAllocation: '12600000',
    tge: '0.7',
    tokenAllocationatTge: '1260000',
    initialMarketcap: '',
    tenMinsDelay: '126000',
    fullyDilitedMc: '',
  },
  {
    id: 5,
    title: 'Community Rewards (25% at TGE & 3 years vesting 25% Yearly)',
    tokenAllocationPercent: '1',
    tokenAllocation: '1800000',
    tge: '0.25',
    tokenAllocationatTge: '450000',
    initialMarketcap: '',
    tenMinsDelay: '45000',
    fullyDilitedMc: '',
  },
  // {
  //   id: 6,
  //   title: 'XOX labs Foundation (0% at TGE & 5 years vesting 20% Yearly)',
  //   tokenAllocationPercent: '3',
  //   tokenAllocation: '5400000',
  //   tge: '0',
  //   tokenAllocationatTge: '',
  //   tenMinsDelay: '',
  //   fullyDilitedMc: '',
  // },
  {
    id: 7,
    title: 'LP Farming (20% TGE cliff in 1 month, then 10% unlock Each year for 8 years)',
    tokenAllocationPercent: '10',
    tokenAllocation: '18000000',
    tge: '2',
    tokenAllocationatTge: '3600000',
    initialMarketcap: '',
    tenMinsDelay: '360000',
    fullyDilitedMc: '',
  },
  {
    id: 8,
    title: 'Seed Sale (10% TGE - 20% Unlock Monthly - 10% Last Month)',
    tokenAllocationPercent: '1.6',
    tokenAllocation: '2880000',
    tge: '0.16',
    tokenAllocationatTge: '288000',
    initialMarketcap: '',
    tenMinsDelay: '28800',
    fullyDilitedMc: '',
  },
  // {
  //   id: 9,
  //   title: 'Partners Sale (10% release at TGE then 5% release Monthly)',
  //   tokenAllocationPercent: '3',
  //   tokenAllocation: '5400000',
  //   tge: '0.3',
  //   tokenAllocationatTge: '540000',
  //   tenMinsDelay: '32400',
  //   fullyDilitedMc: '',
  // },
  {
    id: 10,
    title: 'Pre Sale (10% release at TGE then 10% release Monthly)',
    tokenAllocationPercent: '40',
    tokenAllocation: '72000000',
    tge: '4',
    tokenAllocationatTge: '7200000',
    initialMarketcap: '',
    tenMinsDelay: '720000',
    fullyDilitedMc: '',
  },
  {
    id: 11,
    title: 'Public sale (30% release at TGE then 10% Monthly)',
    tokenAllocationPercent: '7',
    tokenAllocation: '12600000',
    tge: '2.1',
    tokenAllocationatTge: '3780000',
    initialMarketcap: '',
    tenMinsDelay: '378000',
    fullyDilitedMc: '',
  },
  {
    id: 12,
    title: 'Liquidity Pools DEX (100% lock 5 years)',
    tokenAllocationPercent: '6',
    tokenAllocation: '10800000',
    tge: '6',
    tokenAllocationatTge: '10800000',
    initialMarketcap: '1080000',
    tenMinsDelay: '1080000',
    fullyDilitedMc: '',
  },
  {
    id: 13,
    title: 'CEX Listing (30% at TGE - then 10% Monthly Release)',
    tokenAllocationPercent: '10',
    tokenAllocation: '18000000',
    tge: '3',
    tokenAllocationatTge: '5400000',
    initialMarketcap: '',
    tenMinsDelay: '540000',
    fullyDilitedMc: '',
  },
  {
    id: 14,
    title: 'Total Include LP',
    tokenAllocationPercent: '100',
    tokenAllocation: '180000000',
    tge: '18.21',
    tokenAllocationatTge: '32778000',
    initialMarketcap: '1080000',
    tenMinsDelay: '3277800',
    fullyDilitedMc: '18000000',
  },
  {
    id: 15,
    title: 'Total Exclude LP',
    tokenAllocationPercent: '',
    tokenAllocation: '',
    tge: '12.21',
    tokenAllocationatTge: '21978000',
    initialMarketcap: '0',
    tenMinsDelay: '2197800',
    fullyDilitedMc: '',
  },
]

export const initStat: Start[] = [
  {
    id: 1,
    title: 'Total Current Raised',
    amount: '',
    icon: '$',
    field: 'total_raised_usd',
    decimal: 6,
  },
  {
    id: 2,
    title: 'Total XOX Sold',
    amount: '',
    field: 'xox_amount_bought',
    decimal: 18,
  },
  {
    id: 3,
    title: 'Number of Investors',
    amount: '',
    icon: '',
    field: 'total_investor',
  },
  {
    id: 4,
    title: 'Total XOXS Amount Rewarded',
    amount: '',
    field: 'xoxs_amount_reward',
    decimal: 6,
  },
]

export const INIT_INFO: Start[] = [
  {
    id: 1,
    title: 'Amount invested',
    amount: '',
    icon: '$',
    field: 'amountInvestUSD',
  },
  {
    id: 2,
    title: 'XOX amount bought',
    amount: '',
    field: 'amountBoughtXOX',
  },
  {
    id: 3,
    title: 'XOX amount claimed',
    amount: '',
    icon: '',
    field: 'amountClaimXOX',
  },
  {
    id: 4,
    title: 'XOXS amount received',
    amount: '',
    field: 'amountBoughtXOXS',
  },
]

export const ROUND_INFO = [
  {
    tokenPrice: 0.05,
    xoxsPercent: 0.12,
    xoxMax: 5000000,
  },
  {
    tokenPrice: 0.054,
    xoxsPercent: 0.11,
    xoxMax: 6000000,
  },
  {
    tokenPrice: 0.058,
    xoxsPercent: 0.1,
    xoxMax: 6000000,
  },
  {
    tokenPrice: 0.062,
    xoxsPercent: 0.09,
    xoxMax: 7000000,
  },
  {
    tokenPrice: 0.066,
    xoxsPercent: 0.08,
    xoxMax: 7000000,
  },
  {
    tokenPrice: 0.07,
    xoxsPercent: 0.07,
    xoxMax: 8000000,
  },
  {
    tokenPrice: 0.074,
    xoxsPercent: 0.06,
    xoxMax: 8000000,
  },
  {
    tokenPrice: 0.078,
    xoxsPercent: 0.05,
    xoxMax: 8000000,
  },
  {
    tokenPrice: 0.082,
    xoxsPercent: 0.04,
    xoxMax: 8000000,
  },
  {
    tokenPrice: 0.086,
    xoxsPercent: 0.03,
    xoxMax: 9000000,
  },
]

export const SALE_REFERRAL_PROGRAM = [
  {
    round: '1',
    referrer: '3%',
    referee: '2%',
  },
  {
    round: '2',
    referrer: '3%',
    referee: '2%',
  },
  {
    round: '3',
    referrer: '3%',
    referee: '2%',
  },
  {
    round: '4',
    referrer: '3%',
    referee: '2%',
  },
  {
    round: '5',
    referrer: '3%',
    referee: '2%',
  },
  {
    round: '6',
    referrer: '3%',
    referee: '2%',
  },
  {
    round: '7',
    referrer: '3%',
    referee: '2%',
  },
  {
    round: '8',
    referrer: '3%',
    referee: '2%',
  },
  {
    round: '9',
    referrer: '3%',
    referee: '2%',
  },
  {
    round: '10',
    referrer: '3%',
    referee: '2%',
  },
]
