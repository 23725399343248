import { ChainId, ERC20Token, LINEA_TESTNET } from '@pancakeswap/sdk'

export const XOX_BSC_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=56',
)

export const XOX_BSC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xdd94e8a963BbD1513840aC38C5727fa5Da4a3095',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=97',
)

export const XOX_MAINNET = new ERC20Token(
  ChainId.ETHEREUM,
  '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=1',
)

export const XOX_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0x7C06f79a65Ff6d9520bf8604a37F20deA000A3d2',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=5',
)

export const XOX_ARBITRUM_MAINNET = new ERC20Token(
  ChainId.ARBITRUM,
  '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=42161',
)

export const XOX_ARBITRUM_TESTNET = new ERC20Token(
  ChainId.ARBITRUM_TESTNET,
  '0x90fded525b5E613c9dc0fE600d9b52a648386e2E',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=421613',
)

export const XOX_POLYGON_MAINNET = new ERC20Token(
  ChainId.POLYGON,
  '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=137',
)

export const XOX_POLYGON_TESTNET = new ERC20Token(
  ChainId.POLYGON_TESTNET,
  '0x47869A5dcECd2593F02d27f65cB4A5278DE16CD2',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=80001',
)

export const XOX_ZKSYNC_MAINNET = new ERC20Token(
  ChainId.ZKSYNC,
  '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=324',
)

export const XOX_ZKSYNC_TESTNET = new ERC20Token(
  ChainId.ZKSYNC_TESTNET,
  '0x34a12190B65b684A5CC1ec27a6f8d924D1a317be',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=280',
)

export const XOX_OPTIMISM_MAINNET = new ERC20Token(
  ChainId.OPTIMISM,
  '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=10',
)

export const XOX_OPTIMISM_TESTNET = new ERC20Token(
  ChainId.OPTIMISM_TESTNET,
  '0xce221120F145B456ba41b370F11D5E536eCD2BcB',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=420',
)

export const XOX_NEO_TESTNET = new ERC20Token(
  ChainId.NEO_EVM_TESTNET,
  '0x3B6dFC89760d5B6F64C043A74210ddF91264Beef',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=2970385',
)

export const XOX_ZETA_TESTNET = new ERC20Token(
  ChainId.ZETA_TESTNET,
  '0x3B6dFC89760d5B6F64C043A74210ddF91264Beef',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=2970385',
)

export const XOX_MANTA_TESTNET = new ERC20Token(
  ChainId.MANTA_TESTNET,
  '0x56B09E39168b141E43c49eeF261ed9A5f105A6DF',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=3441005',
)

export const XOX_BLAST = new ERC20Token(
  ChainId.BLAST,
  '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  18,
  'XOX',
  'http://localhost:3001/swap?chainId=81457',
)

export const XOX_BLAST_TESTNET = new ERC20Token(
  ChainId.BLAST_TESTNET,
  '0x90fded525b5E613c9dc0fE600d9b52a648386e2E',
  18,
  'XOX',
  'XOX',
  'http://localhost:3001/swap?chainId=168587773',
)

export const XOX_USDT_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x6e2f695A731DA11fce2F996eD26BffDd9E35e0AF',
  18,
  'XOX-USDT',
  'XOX-USDT',
  'http://localhost:3001/pools?chainId=97',
)
export const XOX_USDT_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x6e2f695A731DA11fce2F996eD26BffDd9E35e0AF',
  18,
  'XOX-USDT',
  'XOX-USDT',
  'http://localhost:3001/pools?chainId=56',
)

export const XOX_USDC_TESTNET = new ERC20Token(
  ChainId.GOERLI,
  '0x4B6b6cAD103521832D78F140E89A22c70703d72c',
  18,
  'XOX-USDC',
  'XOX-USDC',
  'http://localhost:3001/pools?chainId=5',
)

export const XOX_USDC_MAINNET = new ERC20Token(
  ChainId.ETHEREUM,
  '0xaAd96063144D0d7D9395db418A5060512f71d41F',
  18,
  'XOX-USDC',
  'XOX-USDC',
  'http://localhost:3001/pools?chainId=1',
)

export const XOX_ETH_TESTNET = new ERC20Token(
  ChainId.GOERLI,
  '0x4cDF5e879027da0151507aE3F9073c7B604a70C4',
  18,
  'XOX-ETH',
  'XOX-ETH',
  'http://localhost:3001/pools?chainId=5',
)

export const XOX_ETH_MAINNET = new ERC20Token(
  ChainId.ETHEREUM,
  '0xA32F2cb7787696e75ffABEB6EBf88bdf72E65efE',
  18,
  'XOX-ETH',
  'XOX-ETH',
  'http://localhost:3001/pools?chainId=1',
)
export const XOX_BNB_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xeBaA9Ab545de44F38170218082fE25ff30C69D7F',
  18,
  'XOX-BNB',
  'XOX-BNB',
  'http://localhost:3001/pools?chainId=97',
)
export const XOX_BNB_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x8d32796a536A73AC81208e8579047ba9D42cbb22',
  18,
  'XOX-BNB',
  'XOX-BNB',
  'http://localhost:3001/pools?chainId=56',
)
export const XOX_GAS_TESTNET = new ERC20Token(
  ChainId.NEO_EVM_TESTNET,
  '0xc08501Bc4031F559ac033C35e7Fa5850EDCb87Fa',
  18,
  'XOX-GAS',
  'XOX-GAS',
  'http://localhost:3001/pools?chainId=2970385',
)

export const XOX_ZETA_ZETA_TESTNET = new ERC20Token(
  ChainId.ZETA_TESTNET,
  '0x26395Ac8E0B0E3215b8967C5c90b3bc32F65ebFC',
  18,
  'XOX-ZETA',
  'XOX-ZETA',
  'http://localhost:3001/pools?chainId=7001',
)

export const XOX_ETH_MANTA_TESTNET = new ERC20Token(
  ChainId.MANTA_TESTNET,
  '0x588be3F16FB1969E4cd792eEAD63d69B02c3b36E',
  18,
  'XOX-ETH',
  'XOX-ETH',
  'http://localhost:3001/pools?chainId=3441005',
)

export const XOX_USDC_NEO_TESTNET = new ERC20Token(
  ChainId.NEO_EVM_TESTNET,
  '0xFd8D6370eb37B62194d5CC0Dcdeb24A61878c8e7',
  18,
  'XOX-USDC',
  'XOX-USDC',
  'http://localhost:3001/pools?chainId=2970385',
)

export const XOX_USDC_ZETA_TESTNET = new ERC20Token(
  ChainId.ZETA_TESTNET,
  '0x76F4F0fcB3E39Df09D4A3617BF71BFFca528c5D5',
  18,
  'XOX-USDC',
  'XOX-USDC',
  'http://localhost:3001/pools?chainId=7001',
)

export const XOX_USDC_MANTA_TESTNET = new ERC20Token(
  ChainId.MANTA_TESTNET,
  '0xC1A128E71C799eDF22785e80fB33a77a87296C8f',
  18,
  'XOX-USDC',
  'XOX-USDC',
  'http://localhost:3001/pools?chainId=3441005',
)

export const XOX: Record<ChainId, ERC20Token> = {
  [ChainId.ETHEREUM]: XOX_MAINNET,
  [ChainId.RINKEBY]: XOX_GOERLI,
  [ChainId.GOERLI]: XOX_GOERLI,
  [ChainId.BSC]: XOX_BSC_MAINNET,
  [ChainId.BSC_TESTNET]: XOX_BSC_TESTNET,
  [ChainId.ARBITRUM]: XOX_ARBITRUM_MAINNET,
  [ChainId.ARBITRUM_TESTNET]: XOX_ARBITRUM_TESTNET,
  [ChainId.POLYGON]: XOX_POLYGON_MAINNET,
  [ChainId.POLYGON_TESTNET]: XOX_POLYGON_TESTNET,
  [ChainId.ZKSYNC]: XOX_ZKSYNC_MAINNET,
  [ChainId.ZKSYNC_TESTNET]: XOX_ZKSYNC_TESTNET,
  [ChainId.OPTIMISM]: XOX_OPTIMISM_MAINNET,
  [ChainId.OPTIMISM_TESTNET]: XOX_OPTIMISM_TESTNET,
  [ChainId.NEO_EVM_TESTNET]: XOX_NEO_TESTNET,
  [ChainId.ZETA_TESTNET]: XOX_ZETA_TESTNET,
  [ChainId.MANTA_TESTNET]: XOX_MANTA_TESTNET,
  [ChainId.BLAST]: XOX_BLAST,
  [ChainId.BLAST_TESTNET]: XOX_BLAST_TESTNET,
  [ChainId.BASE]: undefined,
}

export const XOXLP: Record<ChainId, ERC20Token> = {
  [ChainId.ETHEREUM]: XOX_USDC_MAINNET,
  [ChainId.RINKEBY]: XOX_USDC_TESTNET,
  [ChainId.GOERLI]: XOX_USDC_TESTNET,
  [ChainId.BSC]: XOX_USDT_MAINNET,
  [ChainId.BSC_TESTNET]: XOX_USDT_TESTNET,
  [ChainId.ARBITRUM]: undefined,
  [ChainId.ARBITRUM_TESTNET]: undefined,
  [ChainId.POLYGON]: undefined,
  [ChainId.POLYGON_TESTNET]: undefined,
  [ChainId.ZKSYNC]: undefined,
  [ChainId.ZKSYNC_TESTNET]: undefined,
  [ChainId.OPTIMISM]: undefined,
  [ChainId.OPTIMISM_TESTNET]: undefined,
  [ChainId.BLAST]: undefined,
  [ChainId.BLAST_TESTNET]: undefined,
  [ChainId.NEO_EVM_TESTNET]: XOX_USDC_NEO_TESTNET,
  [ChainId.ZETA_TESTNET]: XOX_ZETA_ZETA_TESTNET,
  [ChainId.MANTA_TESTNET]: XOX_ETH_MANTA_TESTNET,
  [ChainId.BASE]: undefined,
}
export const XOXNATIVELP: Record<ChainId, ERC20Token> = {
  [ChainId.ETHEREUM]: XOX_ETH_MAINNET,
  [ChainId.RINKEBY]: XOX_ETH_TESTNET,
  [ChainId.GOERLI]: XOX_ETH_TESTNET,
  [ChainId.BSC]: XOX_BNB_MAINNET,
  [ChainId.BSC_TESTNET]: XOX_BNB_TESTNET,
  [ChainId.ARBITRUM]: undefined,
  [ChainId.ARBITRUM_TESTNET]: undefined,
  [ChainId.POLYGON]: undefined,
  [ChainId.POLYGON_TESTNET]: undefined,
  [ChainId.ZKSYNC]: undefined,
  [ChainId.ZKSYNC_TESTNET]: undefined,
  [ChainId.OPTIMISM]: undefined,
  [ChainId.OPTIMISM_TESTNET]: undefined,
  [ChainId.BLAST]: undefined,
  [ChainId.BLAST_TESTNET]: undefined,
  [ChainId.NEO_EVM_TESTNET]: XOX_GAS_TESTNET,
  [ChainId.ZETA_TESTNET]: XOX_ZETA_TESTNET,
  [ChainId.MANTA_TESTNET]: XOX_MANTA_TESTNET,
  [ChainId.BASE]: undefined,
}

export const CAKE_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xFa60D973F7642B748046464e165A65B7323b0DEE',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const USDC_BSC = new ERC20Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD Coin',
)

export const USDC_RINKEBY = new ERC20Token(
  ChainId.RINKEBY,
  '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDC_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0xD898D309dAb33130EA57E8F106238ae4b76329f4',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDT_BSC = new ERC20Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether',
  'https://tether.to/',
)

export const USDT_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether',
  'https://tether.to/',
)

export const USDC_NEO_TESTNET = new ERC20Token(
  ChainId.NEO_EVM_TESTNET,
  '0xce221120F145B456ba41b370F11D5E536eCD2BcB',
  6,
  'tUSDC',
  'test USD Coin',
  'https://tether.to/',
)

export const USDC_ZETA_TESTNET = new ERC20Token(
  ChainId.ZETA_TESTNET,
  '0x3e3d551CAB18feca293b059B281f3b8D7C688A0A',
  6,
  'tUSDC',
  'test USD Coin',
  'https://tether.to/',
)

export const USDC_MANTA_TESTNET = new ERC20Token(
  ChainId.MANTA_TESTNET,
  '0x9b8AAb91ea36857763e9552916874ceA18F35b56',
  6,
  'tUSDC',
  'test USD Coin',
  'https://tether.to/',
)

export const USDB_BLAST_TESTNET = new ERC20Token(
  ChainId.BLAST_TESTNET,
  '0x3B6dFC89760d5B6F64C043A74210ddF91264Beef',
  18,
  'USDB',
  'USDB',
)

export const USDB_BLAST = new ERC20Token(
  ChainId.BLAST,
  '0x4300000000000000000000000000000000000003',
  18,
  'USDB',
  'USDB',
)

export const BUSD_BSC = new ERC20Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x04CAD66eF1842A91E6cDE275E1e3A49Ee9106c56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const USDT_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xc60a52351918c13eF3B27F72e5E71877ca3cB13A',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_RINKEBY = new ERC20Token(
  ChainId.RINKEBY,
  '0x4e2442A6f7AeCE64Ca33d31756B5390860BF973E',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BNB_ETHEREUM = new ERC20Token(ChainId.ETHEREUM, '0xB8c77482e45F1F44dE1745F52C74426C631bDD52', 18, 'BNB')
export const MATIC_ETHEREUM = new ERC20Token(
  ChainId.ETHEREUM,
  '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
  18,
  'MATIC',
)
export const UNI_ETHEREUM = new ERC20Token(ChainId.ETHEREUM, '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984', 18, 'UNI')
export const SUSHI_ETHEREUM = new ERC20Token(
  ChainId.ETHEREUM,
  '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
  18,
  'SUSHI',
)
export const LINK_ETHEREUM = new ERC20Token(ChainId.ETHEREUM, '0x514910771AF9Ca656af840dff83E8264EcF986CA', 18, 'LINK')
export const SHIB_ETHEREUM = new ERC20Token(ChainId.ETHEREUM, '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE', 18, 'SHIB')
export const USDC_ETHEREUM = new ERC20Token(ChainId.ETHEREUM, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 18, 'USDC')

export const WBTC_GOERLI = new ERC20Token(ChainId.GOERLI, '0x2a924bcD60450C7a354413d8b2c906A3173F9208', 8, 'WBTC')
export const BNB_GOERLI = new ERC20Token(ChainId.GOERLI, '0x013cB3419Dd78A3A24dF6F9151514752228C6960', 18, 'BNB')
export const MATIC_GOERLI = new ERC20Token(ChainId.GOERLI, '0xB4BdF7ADdeE19c35ecCa122C852103FAE0b3fe0B', 18, 'MATIC')
export const UNI_GOERLI = new ERC20Token(ChainId.GOERLI, '0x1bC4803310B723B4f0A2c27411315432b7db52Df', 18, 'UNI')
export const SUSHI_GOERLI = new ERC20Token(ChainId.GOERLI, '0x28362Cd5D11a8ce0371B4768Cd8EDF8a943CC2A9', 18, 'SUSHI')
export const LINK_GOERLI = new ERC20Token(ChainId.GOERLI, '0xCfc1B83B17084e744423Db6314B7e811729c6514', 18, 'LINK')
export const SHIB_GOERLI = new ERC20Token(ChainId.GOERLI, '0x5f933e0F9D17A4B28F229a2882e2E18dcef266c7', 18, 'SHIB')

export const BTCB_BSC = new ERC20Token(
  ChainId.BSC,
  '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  18,
  'BTCB',
  'Binance BTC',
  'https://bitcoin.org/',
)
export const ETH_BSC = new ERC20Token(ChainId.BSC, '0x2170Ed0880ac9A755fd29B2688956BD959F933F8', 18, 'ETH')
export const DOGE_BSC = new ERC20Token(ChainId.BSC, '0xbA2aE424d960c26247Dd6c32edC70B295c744C43', 18, 'DOGE')
export const MATIC_BSC = new ERC20Token(ChainId.BSC, '0xCC42724C6683B7E57334c4E856f4c9965ED682bD', 18, 'MATIC')
export const CAKE_BSC = new ERC20Token(ChainId.BSC, '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', 18, 'CAKE')
export const LINK_BSC = new ERC20Token(ChainId.BSC, '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD', 18, 'LINK')
export const ADA_BSC = new ERC20Token(ChainId.BSC, '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47', 18, 'ADA')

export const BTCB_BSC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xa3961366f8A7A5772C22A00788f1824d233B26ed',
  18,
  'BTCB',
  'Binance BTC',
  'https://bitcoin.org/',
)
export const ETH_BSC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x65eCB72aDEb9b5da68aA7DdD4A334546E7Cd36e2',
  18,
  'ETH',
)
export const USDT_BSC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xc60a52351918c13eF3B27F72e5E71877ca3cB13A',
  18,
  'USDT',
)
export const DOGE_BSC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xbB5a2336330C07c3b2B532210A1E5B93d747fb93',
  18,
  'DOGE',
)
export const MATIC_BSC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x897f93Cc7E82cC78F076C32aA45c723f84959A0D',
  18,
  'MATIC',
)
export const CAKE_BSC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x638666506b6875a8755884090c41Dee985aC1F6D',
  18,
  'CAKE',
)
export const LINK_BSC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x07bbDcd736cFc0406a3CeD96B9F2289ACBCc4c76',
  18,
  'LINK',
)
export const ADA_BSC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x624bcfBb991cCef8e790E58eD973D8e24cc66eCc',
  18,
  'ADA',
)

export const USDT_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0x1b57aF4ab903fD70f96Ff033498dF44D7B61201C',
  6,
  'tUSDT',
  'test USD Tether',
)
// export const XOX_BSC_TESTNET = new ERC20Token(ChainId.BSC_TESTNET, '0x798bb5B1CD7ed74654EBfD5D58a24d3D9Cc67847', 18, 'XOX')

export const BUSD: Record<ChainId, ERC20Token> = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.RINKEBY]: BUSD_RINKEBY,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
  [ChainId.ARBITRUM]: undefined,
  [ChainId.ARBITRUM_TESTNET]: undefined,
  [ChainId.POLYGON]: undefined,
  [ChainId.POLYGON_TESTNET]: undefined,
  [ChainId.ZKSYNC]: undefined,
  [ChainId.ZKSYNC_TESTNET]: undefined,
  [ChainId.OPTIMISM]: undefined,
  [ChainId.OPTIMISM_TESTNET]: undefined,
  [ChainId.BLAST]: undefined,
  [ChainId.BLAST_TESTNET]: undefined,
  [ChainId.NEO_EVM_TESTNET]: undefined,
  [ChainId.ZETA_TESTNET]: undefined,
  [ChainId.MANTA_TESTNET]: undefined,
  [ChainId.BASE]: undefined,
}

export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.RINKEBY]: USDC_RINKEBY,
  [ChainId.GOERLI]: USDC_GOERLI,
  [ChainId.NEO_EVM_TESTNET]: USDC_NEO_TESTNET,
  [ChainId.ZETA_TESTNET]: USDC_ZETA_TESTNET,
  [ChainId.MANTA_TESTNET]: USDC_MANTA_TESTNET,
  [ChainId.BLAST_TESTNET]: undefined,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.ETHEREUM]: USDT_ETH,
  [ChainId.GOERLI]: USDT_GOERLI,
  [ChainId.BSC_TESTNET]: USDT_BSC_TESTNET,
}

export const USD = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.BSC_TESTNET]: USDT_BSC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.RINKEBY]: USDC_RINKEBY,
  [ChainId.GOERLI]: USDC_GOERLI,
  [ChainId.NEO_EVM_TESTNET]: USDC_NEO_TESTNET,
  [ChainId.ZETA_TESTNET]: USDC_ZETA_TESTNET,
  [ChainId.MANTA_TESTNET]: USDC_MANTA_TESTNET,
  [ChainId.BLAST_TESTNET]: USDB_BLAST_TESTNET,
  [ChainId.BLAST]: USDB_BLAST,
}

export const WBTC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped Bitcoin',
)

export const USDC_LINEA = new ERC20Token(LINEA_TESTNET, '0xf56dc6695cF1f5c364eDEbC7Dc7077ac9B586068', 6, 'USDC')
export const ETH_LINEA = new ERC20Token(LINEA_TESTNET, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 18, 'ETH')
export const WETH_ARBITRUM = new ERC20Token(ChainId.ARBITRUM, '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', 18, 'WETH')

export const USDC_OPTIMISM = new ERC20Token(ChainId.OPTIMISM, '0x7F5c764cBc14f9669B88837ca1490cCa17c31607', 6, 'USDC')
export const USDC_ZKSYNC = new ERC20Token(ChainId.ZKSYNC, '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4', 6, 'USDC')
export const USDC_POLYGON = new ERC20Token(ChainId.POLYGON, '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', 6, 'USDC')
export const USDC_ARBITRUM = new ERC20Token(ChainId.ARBITRUM, '0xaf88d065e77c8cC2239327C5EDb3A432268e5831', 6, 'USDC')
export const USDC_BASE = new ERC20Token(ChainId.BASE, '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', 6, 'USDC')

export const BNB_MAINNET = new ERC20Token(ChainId.BSC, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 18, 'ETH')
export const ETH_MAINNET = new ERC20Token(ChainId.ETHEREUM, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 18, 'ETH')
export const ETH_ARBITRUM = new ERC20Token(ChainId.ARBITRUM, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 18, 'ETH')
export const ETH_OPTIMISM = new ERC20Token(ChainId.OPTIMISM, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 18, 'ETH')
export const ETH_ZKSYN = new ERC20Token(ChainId.ZKSYNC, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 18, 'ETH')
export const ETH_BLAST = new ERC20Token(ChainId.BLAST, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 18, 'ETH')
export const ETH_BASE = new ERC20Token(ChainId.BLAST, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 18, 'ETH')
export const ETH_BLAST_TESTNET = new ERC20Token(
  ChainId.BLAST_TESTNET,
  '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  18,
  'ETH',
)
export const MATIC_POLYGON = new ERC20Token(ChainId.POLYGON, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', 18, 'MATIC')

export const KYBERSWAP_DEFAULT_TOKEN = {
  [LINEA_TESTNET]: {
    TOKEN_IN: {
      token: ETH_LINEA,
      imgURL: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png',
    },
    TOKEN_OUT: {
      token: USDC_LINEA,
      imgURL: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
    },
  },
  [ChainId.ETHEREUM]: {
    TOKEN_IN: {
      token: ETH_MAINNET,
    },
    TOKEN_OUT: {
      token: USDC_ETHEREUM,
      imgURL: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
    },
  },
  [ChainId.BSC]: {
    TOKEN_IN: {
      token: BNB_MAINNET,
    },
    TOKEN_OUT: {
      token: USDT_BSC,
      imgURL: 'https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707',
    },
  },
  [ChainId.POLYGON]: {
    TOKEN_IN: {
      token: MATIC_POLYGON,
      imgURL: 'https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912',
    },
    TOKEN_OUT: {
      token: USDC_POLYGON,
      imgURL: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
    },
  },
  [ChainId.ARBITRUM]: {
    TOKEN_IN: {
      token: ETH_ARBITRUM,
    },
    TOKEN_OUT: {
      token: USDC_ARBITRUM,
      imgURL: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
    },
  },
  [ChainId.ZKSYNC]: {
    TOKEN_IN: {
      token: ETH_ZKSYN,
    },
    TOKEN_OUT: {
      token: USDC_ZKSYNC,
      imgURL: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
    },
  },
  [ChainId.OPTIMISM]: {
    TOKEN_IN: {
      token: ETH_OPTIMISM,
    },
    TOKEN_OUT: {
      token: USDC_OPTIMISM,
      imgURL: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
    },
  },
  [ChainId.BLAST]: {
    TOKEN_IN: {
      token: ETH_BLAST,
    },
    TOKEN_OUT: {
      token: XOX_BLAST,
      imgURL: '	https://cdn.xoxlabs.io/images/tokens/xox-icon.svg',
    },
  },
  [ChainId.BLAST_TESTNET]: {
    TOKEN_IN: {
      token: ETH_BLAST_TESTNET,
    },
    TOKEN_OUT: {
      token: XOX_BLAST_TESTNET,
      imgURL: '	https://cdn.xoxlabs.io/images/tokens/xox-icon.svg',
    },
  },
  [ChainId.BASE]: {
    TOKEN_IN: {
      token: ETH_BASE,
    },
    TOKEN_OUT: {
      token: USDC_BASE,
      imgURL: '	https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
    },
  },
}
