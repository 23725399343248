import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="14.5" stroke="url(#paint0_linear_14_84)" />
      <g clipPath="url(#clip0_14_84)">
        <path
          d="M22.1621 8.22876H19.5499L15.6499 12.1491V14.7748H18.2988L22.199 10.8545L22.1621 8.22876Z"
          fill="white"
        />
        <path d="M7.8362 8.2428H10.4485L14.3484 12.1628V14.7886H11.6995L7.79932 10.8686L7.8362 8.2428Z" fill="white" />
        <path
          d="M22.1621 22.6288H19.5499L15.6499 18.7088V16.083H18.2988L22.199 20.0033L22.1621 22.6288Z"
          fill="white"
        />
        <path
          d="M7.8362 22.6152H10.4485L14.3484 18.6949V16.0691H11.6995L7.79932 19.9894L7.8362 22.6152Z"
          fill="white"
        />
        <path d="M7.79932 16.982V13.8755L9.95009 15.4289L7.79932 16.982Z" fill="white" />
        <path d="M22.1991 16.982V13.8755L20.0483 15.4289L22.1991 16.982Z" fill="white" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_14_84"
          x1="-2.37624"
          y1="8.85217e-09"
          x2="36.7596"
          y2="3.64161"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B809B5" />
          <stop offset="0.510417" stopColor="#ED1C51" />
          <stop offset="1" stopColor="#FFB000" />
        </linearGradient>
        <clipPath id="clip0_14_84">
          <rect width="16" height="14.8571" fill="white" transform="translate(7 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
