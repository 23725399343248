/* eslint-disable react/no-unknown-property */
import React from 'react'
import { SvgProps } from '@pancakeswap/uikit/src/components/Svg/types'

const BlastSaleIcon: React.FC<React.PropsWithChildren<SvgProps>> = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 1.1875C4.90957 1.1875 1.1875 4.90957 1.1875 9.5C1.1875 14.0904 4.90957 17.8125 9.5 17.8125C14.0904 17.8125 17.8125 14.0904 17.8125 9.5C17.8125 4.90957 14.0904 1.1875 9.5 1.1875ZM9.5 16.4023C5.68887 16.4023 2.59766 13.3111 2.59766 9.5C2.59766 5.68887 5.68887 2.59766 9.5 2.59766C13.3111 2.59766 16.4023 5.68887 16.4023 9.5C16.4023 13.3111 13.3111 16.4023 9.5 16.4023Z"
        fill="#515151"
      />
      <path
        d="M12.6674 9.43729L14.402 8.60505L15 6.83795L13.8041 6H5.84123L4 7.31678H13.3601L12.8628 8.79886H9.10926L8.74812 9.88192H12.5016L11.4478 13L13.2061 12.1621L13.8337 10.2923L12.6555 9.46009L12.6674 9.43729Z"
        fill="#515151"
      />
      <path
        d="M6.64639 11.6604L7.72982 8.41125L6.52799 7.5448L4.72228 13L11.4478 13L11.8977 11.6604H6.64639Z"
        fill="#515151"
      />
    </svg>
  )
}

export default BlastSaleIcon
