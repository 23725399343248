/* eslint-disable react/no-unknown-property */
import { SvgProps } from '@pancakeswap/uikit'
import React from 'react'

const DataSite: React.FC<React.PropsWithChildren<SvgProps>> = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4375 4.54167C15.4375 6.29125 12.2471 7.70833 8.3125 7.70833C4.37792 7.70833 1.1875 6.29125 1.1875 4.54167M15.4375 4.54167C15.4375 2.79208 12.2471 1.375 8.3125 1.375C4.37792 1.375 1.1875 2.79208 1.1875 4.54167M15.4375 4.54167V8.5M1.1875 4.54167V8.5M1.1875 8.5C1.1875 10.2496 4.37792 11.6667 8.3125 11.6667M1.1875 8.5V12.4583C1.1875 14.2079 4.37792 15.625 8.3125 15.625M13.0625 10.875V13.25M13.0625 13.25V15.625M13.0625 13.25H15.4375M13.0625 13.25H10.6875"
        stroke="#515151"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default DataSite
