import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <circle cx="15" cy="15" r="14.5" stroke="url(#paint0_linear_10957_46042)" />
      <path
        d="M21.9857 18.3984V21.3607C21.9857 21.5365 21.7828 21.6335 21.6475 21.5253L21.4153 21.3381C21.3064 21.25 21.1885 21.1747 21.0643 21.1127C21.0417 21.1014 21.0275 21.0789 21.0275 21.0539V18.7055C21.0275 18.6802 21.0417 18.6579 21.0643 18.6466C21.1883 18.5846 21.3064 18.5093 21.4153 18.4212L21.6475 18.2341C21.7828 18.1256 21.9857 18.2226 21.9857 18.3984ZM20.6069 20.947C20.6492 20.9569 20.6894 20.9267 20.6894 20.8832V18.8757C20.6894 18.8322 20.6492 18.802 20.6069 18.8119C20.4567 18.8473 20.302 18.8649 20.146 18.8649H18.9863C18.4574 18.8649 17.9846 19.2504 17.9328 19.7768C17.8733 20.3816 18.3469 20.8938 18.9424 20.8938L19.4954 21.9978C19.5718 22.1502 19.7276 22.2464 19.898 22.2464H20.1821L19.9567 20.8938H20.146C20.3023 20.894 20.4567 20.9116 20.6069 20.947Z"
        fill="white"
      />
      <path
        d="M21.9785 9.23439C22.0996 7.89381 20.6518 8.44585 20.6518 8.44585H20.651C19.5791 8.87978 18.4735 9.32077 17.356 9.76651L17.3557 9.76662C13.9031 11.1437 10.3366 12.5663 7.29806 13.9663C5.64902 14.5577 6.61422 15.1491 6.61422 15.1491L9.22884 15.938C10.4352 16.2929 11.0788 15.8983 11.0788 15.8983L16.7097 12.1524C18.7206 10.8118 18.238 11.9159 17.7554 12.3889L13.5328 16.3323C12.8888 16.8843 13.2108 17.3573 13.4925 17.5942C14.1973 18.2021 15.8112 19.2559 16.7687 19.8725C16.7981 17.9769 18.3439 16.4492 20.2465 16.4492C20.4609 16.4492 20.6708 16.4686 20.8745 16.5057L21.295 13.8085C21.4394 12.7371 21.605 11.7071 21.7373 10.8833L21.7373 10.8832C21.8632 10.1002 21.959 9.5034 21.9785 9.23439Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10957_46042"
          x1="-2.37624"
          y1="8.85217e-09"
          x2="36.7596"
          y2="3.64161"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B809B5" />
          <stop offset="0.510417" stopColor="#ED1C51" />
          <stop offset="1" stopColor="#FFB000" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Icon;
