import { Button, Modal, Text, Grid, Box, Message, MessageText } from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import Image from 'next/future/image'
import { useSwitchNetwork, useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { chains } from 'utils/wagmi'
import { useTranslation } from '@pancakeswap/localization'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'contexts/HistoryContext'
import NextLink from 'next/link'
import { useMenuItems } from 'components/Menu/hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem } from 'components/Menu/utils'
import { useRouter } from 'next/router'
import useAuth from 'hooks/useAuth'
import styled from 'styled-components'
import { createWallets, getDocLink } from 'config/wallet'
import { WalletModalV2 } from '@pancakeswap/ui-wallets'
import { useConnect } from 'wagmi'

const BoxContainer = styled(Box)`
  display: flex;
  justify-content: center;
`

export function PageNetworkSupportPreSaleModal() {
  const {
    t,
    currentLanguage: { code },
  } = useTranslation()
  const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
  const { chainId, account } = useActiveWeb3React()
  const { login, setLoggedOut } = useAuth()
  const { connectAsync } = useConnect()
  const foundChain = useMemo(() => chains.find((c) => c.id === chainId), [chainId])
  const historyManager = useHistory()

  const lastValidPath = historyManager?.history?.find((h) => ['/swap', 'liquidity', '/', '/info'].includes(h))

  const menuItems = useMenuItems()
  const { pathname, push } = useRouter()
  const wallets = useMemo(() => createWallets(chainId, connectAsync), [chainId, connectAsync])
  const [openConnect, setOpenConnect] = useState(false)
  const docLink = useMemo(() => getDocLink(code), [code])

  const { title, image } = useMemo(() => {
    const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
    const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

    return {
      title: activeSubMenuItem?.disabled ? activeSubMenuItem?.label : activeMenuItem?.label,
      image: activeSubMenuItem?.image || activeMenuItem?.image,
    }
  }, [menuItems, pathname])

  const handleSwitch = useCallback((network: number) => {
    if (account) {
      switchNetworkAsync(network)
    } else {
      setOpenConnect(true)
    }
  },[account])

  useEffect(() => {
    if (account) setOpenConnect(false)
  }, [account])

  return (
    <Modal title={title || t('Check your network')} hideCloseButton headerBackground="gradientCardHeader">
      <Grid style={{ gap: '16px' }} maxWidth="360px" marginBottom="26px">
        <Text bold color="white">
          This feature is not supported on this network
        </Text>

        {image && (
          <BoxContainer mx="auto" my="8px" width="100%" minHeight="250px">
            <img
              src={`${process.env.NEXT_PUBLIC_ASSETS_URI}/images/Group33047.png`}
              alt="ref_xox"
              className="ref_xox"
              style={{ width: '200px', height: '200px', marginTop: '25px' }}
            />
          </BoxContainer>
        )}
        {canSwitch ? (
          <>
            <Button
              variant={foundChain && lastValidPath ? 'secondary' : 'primary'}
              isLoading={isLoading}
              onClick={() => handleSwitch(ChainId.ETHEREUM)}
            >
              {t('Switch to %chain%', { chain: 'Ethereum' })}
            </Button>
            <Button
              variant={foundChain && lastValidPath ? 'secondary' : 'primary'}
              isLoading={isLoading}
              onClick={() => handleSwitch(ChainId.BSC)}
            >
              {t('Switch to %chain%', { chain: 'BNB Chain' })}
            </Button>
          </>
        ) : (
          <Message variant="danger">
            <MessageText>{t('Unable to switch network. Please try it on your wallet')}</MessageText>
          </Message>
        )}
      </Grid>
      <WalletModalV2
          docText={t('Learn How to Connect')}
          docLink={docLink}
          isOpen={openConnect}
          wallets={wallets}
          login={login}
          onDismiss={() => setOpenConnect(false)}
          setLoggedOut={setLoggedOut}
        />
    </Modal>
  )
}
