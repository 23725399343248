/* eslint-disable @typescript-eslint/no-unused-vars */
import { GraphQLClient, request, gql } from 'graphql-request'
import { ChainId } from '@pancakeswap/sdk'
import { ENDPOINT_GRAPHQL_WITH_CHAIN } from 'config/constants/endpoints'
import { SUPPORT_CHAIN_DAPP, SUPPORT_ONLY_BLAST } from 'config/constants/supportChains'
import BigNumber from 'bignumber.js'

const DECIMAL_18 = 1000000000000000000
const DECIMAL_12 = 1000000000000
const dataSaleStatZero = {
  id: 'STATS_ALL',
  total_raised_usd: 0,
  xox_amount_bought: 0,
  xoxs_amount_reward: 0,
  total_investor: 0,
}

const dataUserPreSaleInfoZero = {
  id: '',
  amountInvestUSD: 0,
  amountBoughtXOX: 0,
  amountBoughtXOXS: 0,
  amountClaimXOX: 0,
}

const dataAnalysisSaleReferralZero = {
  id: 'ANALYSIS_SALE-account',
  account: 'account',
  rewardXOXS: 0,
  bonusXOXSByReferral: 0,
  totalTransactionApplyReferral: 0,
}

interface SaleStat {
  saleStats: {
    id: string
    total_raised_usd: number
    xox_amount_bought: number
    xoxs_amount_reward: number
    total_investor: number
  }
}

interface UserPreSaleData {
  userPreSaleDatas: {
    id: string
    amountInvestUSD: number
    amountBoughtXOX: number
    amountBoughtXOXS: number
    amountClaimXOX: number
  }
}

interface AnalysisSaleReferral {
  analysisSaleReferrals: {
    id: string
    account: string
    rewardXOXS: number
    bonusXOXSByReferral: number
    totalTransactionApplyReferral: number
  }
}

interface raisedData {
  id: string
  date: number
  volumeUSD: number
}

interface RaiseDaily {
  raiseDailies: raisedData[]
}

const getSaleStats = async (chainId: ChainId) => {
  try {
    const response: SaleStat = await request(
      ENDPOINT_GRAPHQL_WITH_CHAIN[chainId],
      gql`
        query getStatsSale {
          saleStats {
            id
            total_raised_usd
            xox_amount_bought
            total_investor
            xoxs_amount_reward
          }
        }
      `,
    )

    return response?.saleStats[0] || null
  } catch (error) {
    console.warn('error getSaleStats: ', error)
    return null
  }
}

const getUserPreSaleInfo = async (account: string, chainId: ChainId) => {
  try {
    const response: UserPreSaleData = await request(
      ENDPOINT_GRAPHQL_WITH_CHAIN[chainId],
      gql`
            query getInfoUserPreSale {
              userPreSaleDatas (where : {id :"${account?.toLowerCase()}"}) {
                id
                amountInvestUSD
                amountBoughtXOX
                amountBoughtXOXS
                amountClaimXOX
              }
            }
          `,
    )
    return response?.userPreSaleDatas[0] || null
  } catch (error) {
    console.warn('error getUserPreSaleInfo: ', error)
    return null
  }
}

const getDataReferralPresale = async (account: string, chainId: ChainId) => {
  try {
    const response: AnalysisSaleReferral = await request(
      ENDPOINT_GRAPHQL_WITH_CHAIN[chainId],
      gql`
            query getRefPresale {
              analysisSaleReferrals(where: { account: "${account?.toLowerCase()}" }) {
                id
                account
                rewardXOXS
                bonusXOXSByReferral
                totalTransactionApplyReferral
              }
            }
          `,
    )
    return response?.analysisSaleReferrals[0] || null
  } catch (error) {
    console.warn('error getDataReferralPresale:', error)
    return null
  }
}

const getRaiseDailies = async (from: number, to: number, chainId: ChainId) => {
  try {
    const response: RaiseDaily = await request(
      ENDPOINT_GRAPHQL_WITH_CHAIN[chainId],
      gql`
            query getRaise {
              raiseDailies(where: { date_gte: ${from}, date_lte: ${to} }, orderBy: date, orderDirection: desc) {
                id,
                volumeUSD,
                date
              }
            }
          `,
    )
    return response?.raiseDailies || []
  } catch (error) {
    console.warn('getRaiseDailies: ', error)
    return []
  }
}

// PRE_SALE

const fetchDataSaleStats = async () => {
  let [eth, bsc] = await Promise.all([getSaleStats(ChainId.ETHEREUM), getSaleStats(ChainId.BSC)])
  if (!bsc) bsc = dataSaleStatZero
  if (!eth) eth = dataSaleStatZero
  return {
    id: 'STATS_ALL',
    total_raised_usd: new BigNumber(eth.total_raised_usd)
      .times(DECIMAL_12)
      .plus(new BigNumber(bsc.total_raised_usd))
      .div(DECIMAL_18)
      .toFixed(6),
    xox_amount_bought: new BigNumber(eth.xox_amount_bought)
      .plus(new BigNumber(bsc.xox_amount_bought))
      .div(DECIMAL_18)
      .toFixed(6),
    xoxs_amount_reward: new BigNumber(eth.xoxs_amount_reward)
      .times(DECIMAL_12)
      .plus(new BigNumber(bsc.xoxs_amount_reward))
      .div(DECIMAL_18)
      .toFixed(6),
    total_investor: new BigNumber(eth.total_investor).plus(new BigNumber(bsc.total_investor)).toNumber(),
  }
}

const fetchDataUserPreSaleInfo = async (account: string) => {
  let [eth, bsc] = await Promise.all([
    getUserPreSaleInfo(account, ChainId.ETHEREUM),
    getUserPreSaleInfo(account, ChainId.BSC),
  ])
  if (!bsc) bsc = dataUserPreSaleInfoZero
  if (!eth) eth = dataUserPreSaleInfoZero
  return {
    id: account,
    amountInvestUSD: new BigNumber(eth.amountInvestUSD)
      .times(DECIMAL_12)
      .plus(new BigNumber(bsc.amountInvestUSD))
      .div(DECIMAL_18)
      .toFixed(6),
    amountBoughtXOX: new BigNumber(eth.amountBoughtXOX)
      .plus(new BigNumber(bsc.amountBoughtXOX))
      .div(DECIMAL_18)
      .toFixed(6),
    amountBoughtXOXS: new BigNumber(eth.amountBoughtXOXS)
      .times(DECIMAL_12)
      .plus(new BigNumber(bsc.amountBoughtXOXS))
      .div(DECIMAL_18)
      .toFixed(6),
    amountClaimXOX: new BigNumber(eth.amountClaimXOX)
      .plus(new BigNumber(bsc.amountClaimXOX))
      .div(DECIMAL_18)
      .toFixed(6),
  }
}

const fetchDataAnalysisSaleReferral = async (account: string) => {
  let [eth, bsc] = await Promise.all([
    getDataReferralPresale(account, ChainId.ETHEREUM),
    getDataReferralPresale(account, ChainId.BSC),
  ])
  if (!bsc) bsc = dataAnalysisSaleReferralZero
  if (!eth) eth = dataAnalysisSaleReferralZero

  return {
    id: `ANALYSIS_SALE-${account?.toLowerCase()}`,
    account: `${account?.toLowerCase()}`,
    rewardXOXS: new BigNumber(eth.rewardXOXS)
      .times(DECIMAL_12)
      .plus(new BigNumber(bsc.rewardXOXS))
      .div(DECIMAL_18)
      .toFixed(6),
    bonusXOXSByReferral: new BigNumber(eth.bonusXOXSByReferral)
      .times(DECIMAL_12)
      .plus(new BigNumber(bsc.bonusXOXSByReferral))
      .div(DECIMAL_18)
      .toFixed(6),
    totalTransactionApplyReferral:
      new BigNumber(eth.totalTransactionApplyReferral)
        .plus(new BigNumber(bsc.totalTransactionApplyReferral))
        .toNumber() || 0,
  }
}

const fetchDataRaisedDailied = async (from: number, to: number) => {
  let [eth, bsc] = await Promise.all([
    getRaiseDailies(from, to, ChainId.ETHEREUM),
    getRaiseDailies(from, to, ChainId.BSC),
  ])

  if (eth.length > 0) {
    eth = eth.map((e) => {
      return {
        id: e.id,
        volumeUSD: new BigNumber(e.volumeUSD).times(DECIMAL_12).toNumber(),
        date: e.date,
      }
    })
  }
  const resultDateId = {}
  const resultDateVol = {}

  eth.forEach((item) => {
    const { id, volumeUSD, date } = item
    resultDateVol[date] = new BigNumber(resultDateVol[date] || 0).plus(new BigNumber(volumeUSD))
    resultDateId[date] = id
  })

  bsc.forEach((item) => {
    const { id, volumeUSD, date } = item
    resultDateVol[date] = new BigNumber(resultDateVol[date] || 0).plus(new BigNumber(volumeUSD))
    resultDateId[date] = id
  })

  const resultArray = Object.keys(resultDateId).map((key) => ({
    id: resultDateId[key],
    date: key,
    volumeUSD: resultDateVol[key],
  }))

  return resultArray
}

export const getPreSaleData = async (account: string) => {
  const [saleStats, userData, referalData] = await Promise.all([
    fetchDataSaleStats(),
    fetchDataUserPreSaleInfo(account),
    fetchDataAnalysisSaleReferral(account),
  ])
  return {
    saleStats: saleStats,
    userData: userData,
    referalData: referalData,
  }
}

export const getRaisedDailies = async (from: number, to: number) => {
  return await fetchDataRaisedDailied(from, to)
}

export const getPresaleV2Stats = async (chainId: ChainId) => {
  const response = await request(
    ENDPOINT_GRAPHQL_WITH_CHAIN[!SUPPORT_ONLY_BLAST.includes(chainId) ? ChainId.BLAST : chainId],
    gql`
      query getAnalysisPresaleV2 {
        analysisDataPreSaleV2(id: "ANALYSIS_PRESALE_V2") {
          id
          total_eth_amount
          total_usd_amount
          total_investors
        }
      }
    `,
  )
  return response
}

export const getUserDataPresaleV2 = async (chainId: ChainId, address: string) => {
  const response = await request(
    ENDPOINT_GRAPHQL_WITH_CHAIN[!SUPPORT_ONLY_BLAST.includes(chainId) ? ChainId.BLAST : chainId],
    gql`
        query GetUserPresaleV2 {
        userPreSaleV2Data(id: "${address?.toLowerCase()}") {
            id
            amountETH
            amountUSD
            amountXOX
        }
        }
    `,
  )
  return response
}

export const getUserTransactionListPresaleV2 = async (chainId: ChainId, address: string) => {
  const response = await request(
    ENDPOINT_GRAPHQL_WITH_CHAIN[!SUPPORT_ONLY_BLAST.includes(chainId) ? ChainId.BLAST : chainId],
    gql`
        query getListStakingByUser {
        transactionPreSaleV2S(
            first: 1000
            orderBy: timestamp
            orderDirection: desc
            where: {sender: "${address?.toLowerCase()}"}
        ) {
            id
            blockNumber
            timestamp
            sender
            isETH
            amount
        }
        }
    `,
  )
  return response
}
