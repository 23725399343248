/* eslint-disable react/no-unknown-property */
import { SvgProps } from '@pancakeswap/uikit'
import React from 'react'

const PresalesIcon: React.FC<React.PropsWithChildren<SvgProps>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Layer_1" width="19" height="19" viewBox="0 0 19 19">
    <style>{'.st0{fill:#515151}'}</style>
    <path
      d="M9.5 0C4.3 0 0 4.3 0 9.5S4.3 19 9.5 19 19 14.7 19 9.5 14.7 0 9.5 0zm0 18.2C4.7 18.2.8 14.3.8 9.5S4.7.8 9.5.8s8.7 3.9 8.7 8.7-3.9 8.7-8.7 8.7z"
      className="st0"
    />
    <path
      d="M9.5 2C5.4 2 2.1 5.4 2.1 9.5S5.4 17 9.5 17 17 13.6 17 9.5 13.6 2.1 9.5 2zm0 14.1c-3.7 0-6.6-3-6.6-6.6s3-6.6 6.6-6.6 6.6 3 6.6 6.6-2.9 6.6-6.6 6.6z"
      className="st0"
    />
    <path
      d="M5.5 12.1c0 .1 0 .2-.1.2H5c-.1 0-.1-.1-.1-.2V6.9c0-.1 0-.2.1-.2h.4c.1 0 .1.1.1.2v5.2zM8.7 6.7c.7 0 1.1.7 1.1 1.7v2.2c0 1.1-.4 1.7-1.1 1.7H6.4c-.1 0-.1-.1-.1-.2V6.9c0-.1 0-.2.1-.2h2.3zm-1.8 4.8h1.5c.6 0 .8-.3.8-1V8.6c0-.7-.2-1-.8-1H7c0-.1 0 3.9-.1 3.9zM14.1 11c0 .9-.3 1.3-.9 1.3h-1.9c-.6 0-.9-.4-.9-1.3V8c0-.9.3-1.3.9-1.3h1.9c.6 0 .9.4.9 1.3v3zm-.6-2.8c0-.6-.1-.7-.5-.7h-1.3c-.4 0-.5.1-.5.7v2.6c0 .6.1.7.5.7H13c.4 0 .5-.1.5-.7V8.2z"
      className="st0"
    />
  </svg>
)

export default PresalesIcon
