/* eslint-disable react/no-unknown-property */
import React from "react";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="37"
      viewBox="0 0 58 37"
      fill="none"
      className={className}
    >
      <path
        d="M27.1129 21.3428L35.7547 29.9846C36.5249 30.7548 36.9579 31.7996 36.9579 32.8888V32.89C36.9579 35.1584 35.119 36.9984 32.8494 36.9984H32.5954C31.5062 36.9984 30.4614 36.5655 29.6912 35.7953L21.3023 27.4063C20.5321 26.6361 20.0991 25.5914 20.0991 24.5022V24.2481C20.0991 21.9797 21.938 20.1396 24.2076 20.1396H24.2087C25.2979 20.1396 26.3427 20.5726 27.1129 21.3428Z"
        fill="white"
      />
      <path
        d="M57.0975 24.2481V24.5033C57.0975 25.5925 56.6646 26.6373 55.8944 27.4086L47.5055 35.7964C46.7353 36.5666 45.6905 36.9995 44.6013 36.9995H44.3472C42.0788 36.9995 40.2388 35.1607 40.2388 32.8911V32.89C40.2388 31.8008 40.6717 30.756 41.4419 29.9847L50.0849 21.3428C50.8551 20.5726 51.8999 20.1396 52.9891 20.1396C55.2586 20.1396 57.0975 21.9785 57.0975 24.2481Z"
        fill="white"
      />
      <path
        d="M32.5954 0H32.8506C35.119 0 36.959 1.83889 36.959 4.10845C36.959 5.19765 36.5261 6.24242 35.7559 7.01261L27.114 15.6556C26.3438 16.4258 25.2991 16.8588 24.2087 16.8588H24.2076C21.9391 16.8588 20.0991 15.0199 20.0991 12.7503V12.4962C20.0991 11.407 20.5321 10.3623 21.3023 9.59207L29.6901 1.20314C30.4602 0.432949 31.505 0 32.5954 0Z"
        fill="white"
      />
      <path
        d="M57.0975 12.4961V12.7513C57.0975 15.0197 55.2586 16.8597 52.9891 16.8597C51.8999 16.8597 50.8551 16.4268 50.0849 15.6566L41.4419 7.01359C40.6717 6.2434 40.2388 5.19863 40.2388 4.10942C40.2388 1.841 42.0777 0.000976562 44.3472 0.000976562H44.6024C45.6916 0.000976562 46.7364 0.433924 47.5066 1.20411L55.8944 9.5919C56.6646 10.361 57.0975 11.4057 57.0975 12.4961Z"
        fill="white"
      />
      <path
        d="M16.8588 24.2481V24.5033C16.8588 25.5925 16.4258 26.6373 15.6556 27.4086L7.26668 35.7964C6.49649 36.5666 5.45172 36.9995 4.36252 36.9995H4.10844C1.84003 36.9995 0 35.1607 0 32.8911V32.89C0 31.8008 0.432947 30.756 1.20314 29.9847L9.84614 21.3428C10.6163 20.5726 11.6611 20.1396 12.7503 20.1396C15.0199 20.1396 16.8588 21.9785 16.8588 24.2481Z"
        fill="white"
      />
      <path
        d="M16.8588 12.4961V12.7513C16.8588 15.0197 15.0199 16.8597 12.7503 16.8597C11.6611 16.8597 10.6163 16.4268 9.84614 15.6566L1.20314 7.01359C0.432947 6.2434 0 5.19863 0 4.10942C0 1.841 1.83889 0.000976562 4.10844 0.000976562H4.36366C5.45286 0.000976562 6.49763 0.433924 7.26782 1.20411L15.6556 9.5919C16.4258 10.361 16.8588 11.4057 16.8588 12.4961Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
