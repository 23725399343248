/* eslint-disable react/no-unknown-property */
import { SvgProps } from '@pancakeswap/uikit'
import React from 'react'

const SeedSaleIcon: React.FC<React.PropsWithChildren<SvgProps>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Layer_1" width="19" height="19" viewBox="0 0 19 19">
    <style>{'.st0{fill:#515151}'}</style>
    <path
      d="M13.5 9c-.3 0-.5.2-.5.5v8.2c0 .1-.1.2-.2.2H1.2c-.1 0-.2-.1-.2-.2V5.8h3.6c.6 0 1.2-.5 1.2-1.2V1.1H12.7c.2-.1.3-.2.3-.4s-.2-.4-.5-.4H5.3c-.1 0-.2 0-.3.1L.2 5s-.1.1-.1.2v12.5c0 .6.5 1.1 1.1 1.1h11.6c.6 0 1.1-.5 1.1-1.1V9.5c.1-.3-.1-.5-.4-.5zM4.8 1.7v3c0 .1-.1.2-.2.2h-3l3.2-3.2z"
      className="st0"
    />
    <path
      d="M10.6 9.3H6.4c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h4.2c.2 0 .5.2.5.5s-.2.5-.5.5zM4.2 9.3h-.8c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h.9c.2 0 .5.2.5.5s-.3.5-.6.5zM10.6 12.2H6.4c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h4.2c.2 0 .5.2.5.5s-.2.5-.5.5zM4.2 12.2h-.8c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h.9c.2 0 .5.2.5.5s-.3.5-.6.5zM10.6 15.2H6.4c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h4.2c.2 0 .5.2.5.5s-.2.5-.5.5zM4.2 15.2h-.8c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h.9c.2 0 .5.2.5.5s-.3.5-.6.5zM13.1 6.5c0-.1.1-.2.2-.2h.6c.1 0 .2.1.2.2v.2c0 .4.1.6.5.6h1.2v-2h-1.2c-.9 0-1.3-.5-1.3-1.6v-.8c0-1.1.4-1.6 1.3-1.6h1.2V.4c0-.1.1-.2.2-.2h.3c.1 0 .2.1.2.2v.8h1.2c1 0 1.3.4 1.3 1.6V3c0 .1-.1.2-.2.2h-.6c-.1 0-.2-.1-.2-.2v-.1c0-.4-.1-.6-.5-.6h-1.1v1.9h1.3c.9 0 1.3.5 1.3 1.6v.8c0 1.1-.4 1.6-1.3 1.6h-1.3v.9c0 .1-.1.2-.2.2h-.3c-.1 0-.2-.1-.2-.2v-.8h-1.3c-1 0-1.3-.4-1.3-1.6v-.2zm1-3.1c0 .6.2.8.7.8h.8V2.4h-.8c-.6 0-.7.2-.7.8v.2zm2.2 1.9v2h.9c.6 0 .7-.2.7-.8v-.4c0-.6-.1-.8-.7-.8h-.9z"
      className="st0"
    />
  </svg>
)

export default SeedSaleIcon
