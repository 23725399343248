/* eslint-disable react/no-unknown-property */
import { SvgProps } from '@pancakeswap/uikit'
import React from 'react'

const QuestPlatform: React.FC<React.PropsWithChildren<SvgProps>> = () => {
  return (
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.874 2.58938H12.4924C13.4979 2.58938 14.3125 3.40711 14.3125 4.41638V12.5121C14.3125 13.5214 13.4979 14.3396 12.4924 14.3396H10.756M11.874 2.58938C11.6 1.76134 10.8524 1.32031 10.3587 1.32031H8.77392C8.23915 1.32031 7.45778 1.8026 7.28653 2.58938M11.874 2.58938L11.9493 3.05448C11.9493 3.49502 11.6729 3.67428 11.3318 3.67428H7.82816C7.48713 3.67428 7.18329 3.46801 7.21069 3.05448L7.28653 2.58938M4.86903 6.05576V4.41638C4.86903 3.40711 5.68416 2.58938 6.68913 2.58938H7.28653M3.3125 11.5004H8.3125M3.3125 13.8396H8.3125M3.3125 9.00037H8.3125M2.21766 6.05576H9.8513C10.3512 6.05576 10.7565 6.46255 10.7565 6.96434V15.7717C10.7565 16.2735 10.3512 16.6803 9.8513 16.6803H2.21766C1.71775 16.6803 1.3125 16.2735 1.3125 15.7717V6.96434C1.3125 6.46255 1.71775 6.05576 2.21766 6.05576Z"
        stroke="#515151"
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default QuestPlatform
