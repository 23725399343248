import { ChainId } from '@pancakeswap/sdk'

export const SUPPORT_ONLY_BSC = [ChainId.BSC]
export const SUPPORT_ONLY_ETH = [ChainId.ETHEREUM, ChainId.GOERLI]
export const SUPPORT_FARMS = [ChainId.BSC, ChainId.BSC_TESTNET, ChainId.ETHEREUM, ChainId.GOERLI]
export const SUPPORT_CHAIN_DAPP = [ChainId.BSC, ChainId.BSC_TESTNET, ChainId.ETHEREUM, ChainId.GOERLI]
export const SUPPORT_CHAIN_DAPP_MAINNET = [ChainId.BSC, ChainId.ETHEREUM]
export const SUPPORT_ZAP = [ChainId.BSC, ChainId.BSC_TESTNET]
export const SUPPORT_CHAIN_STABLE_COIN_TESTNET = [
  ChainId.ETHEREUM,
  ChainId.BSC,
  ChainId.ARBITRUM,
  ChainId.POLYGON,
  ChainId.ZKSYNC,
  ChainId.OPTIMISM,
  ChainId.GOERLI,
  ChainId.BSC_TESTNET,
  ChainId.POLYGON_TESTNET,
  ChainId.BLAST_TESTNET,
]
export const SUPPORT_CHAIN_STABLE_COIN_MAINNET = [
  ChainId.ETHEREUM,
  ChainId.BSC,
  ChainId.ARBITRUM,
  ChainId.POLYGON,
  ChainId.ZKSYNC,
  ChainId.OPTIMISM,
  ChainId.BLAST,
]
export const SUPPORT_ONLY_BLAST = [ChainId.BLAST, ChainId.BLAST_TESTNET]
