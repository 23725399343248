import React from "react";

const Icon = ({ width }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6 11.9961H18" stroke="#515151" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12 18L6 12L12 6"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

export default Icon;
