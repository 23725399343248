/* eslint-disable react/no-unknown-property */
import { SvgProps } from '@pancakeswap/uikit'
import React from 'react'

const FarmingIcon: React.FC<React.PropsWithChildren<SvgProps>> = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9388 2.50689L16.8552 2.01985L16.3791 2.00056C16.2885 2.00056 14.0708 1.9234 11.6743 3.58224C10.209 4.5949 9.38462 6.06567 8.95036 7.11691C8.90624 7.22541 8.86444 7.33632 8.82729 7.44964C8.42554 6.82276 7.85893 6.17417 7.06009 5.67989C4.7402 4.25011 2.50856 5.3761 2.418 5.42673L2 5.64132L2.10218 6.11148C2.12308 6.20551 2.62467 8.4406 4.68447 9.85109C5.85022 10.6468 7.18781 10.9047 8.54166 10.8396V14.4274C6.89986 14.601 5.7852 15.358 5.21625 15.8475C5.21161 15.8499 5.20697 15.8547 5.20232 15.8596C5.1791 15.8764 5.15356 15.8981 5.13266 15.9198C5.13266 15.9198 5.13111 15.9214 5.12801 15.9247C5.01655 16.038 4.9492 16.1947 4.9492 16.3683C4.9492 16.7179 5.2209 17 5.55762 17C5.66676 17 5.76662 16.9711 5.85486 16.918C5.91756 16.8819 5.97562 16.8336 6.02206 16.7758C6.02438 16.7734 6.02438 16.7709 6.02671 16.7709C6.591 16.3177 7.67315 15.6233 9.23367 15.6233H9.23832C11.0009 15.6233 12.0691 16.3611 12.5289 16.7709C12.5335 16.7758 12.5382 16.7806 12.5428 16.7854C12.58 16.8168 12.6125 16.8481 12.6403 16.877C12.7402 16.9542 12.8633 17 12.998 17C13.3347 17 13.6064 16.7179 13.6064 16.3683C13.6064 16.2501 13.5948 16.1441 13.5181 16.0452C13.4578 15.9656 13.3579 15.8909 13.2488 15.7969C12.6566 15.2833 11.4978 14.5334 9.7283 14.4105V10.6974C11.5768 10.3526 13.3208 9.47978 14.4702 8.61902C17.5541 6.30437 16.9643 2.6612 16.9388 2.50689ZM5.33933 8.82156C4.227 8.05965 3.67199 6.97465 3.42583 6.34536C4.06444 6.1597 5.24877 5.99334 6.45399 6.73596C7.80784 7.57261 8.33266 9.02891 8.49289 9.60757C7.38287 9.66062 6.27054 9.45809 5.34165 8.82156H5.33933ZM13.7736 7.61842C12.8145 8.33934 11.3144 9.10124 9.7283 9.4388V9.3086C9.7283 8.67207 9.8328 8.09581 10.0395 7.59913C10.411 6.69979 11.1077 5.44843 12.3315 4.60213C13.748 3.62323 15.1019 3.33872 15.8148 3.25915C15.838 4.21154 15.6383 6.21998 13.7736 7.61842Z"
        fill="#515151"
      />
    </svg>
  )
}

export default FarmingIcon
