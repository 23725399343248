import { ChainId, LINEA_TESTNET } from '@pancakeswap/sdk'

const Bsc = `/images/chains/56.svg`
const Ethereum = `/images/chains/1.svg`
const Neo = `/images/chains/2970385.svg`
const Zeta = `/images/chains/7001.svg`
const Manta = `/images/chains/3441005.svg`
const Blast = `/images/chains/81457.svg`
const Arbitrum = `${process.env.NEXT_PUBLIC_ASSETS_URI}/images/Arbitrum.svg`
const Polygon = `${process.env.NEXT_PUBLIC_ASSETS_URI}/images/Polygon.svg`
const zkSync = `${process.env.NEXT_PUBLIC_ASSETS_URI}/images/zkSync.svg`
const Optimism = `${process.env.NEXT_PUBLIC_ASSETS_URI}/images/Optimism.svg`
const Base = `${process.env.NEXT_PUBLIC_ASSETS_URI}/images/Base.svg`

export const NETWORK_ICON = {
  [ChainId.BSC]: Bsc,
  [ChainId.BSC_TESTNET]: Bsc,
  [ChainId.ETHEREUM]: Ethereum,
  [ChainId.GOERLI]: Ethereum,
  [ChainId.RINKEBY]: Ethereum,
  [ChainId.ARBITRUM]: Arbitrum,
  [ChainId.ARBITRUM_TESTNET]: Arbitrum,
  [ChainId.POLYGON]: Polygon,
  [ChainId.POLYGON_TESTNET]: Polygon,
  [ChainId.ZKSYNC]: zkSync,
  [ChainId.ZKSYNC_TESTNET]: zkSync,
  [ChainId.OPTIMISM]: Optimism,
  [ChainId.OPTIMISM_TESTNET]: Optimism,
  [ChainId.NEO_EVM_TESTNET]: Neo,
  [ChainId.ZETA_TESTNET]: Zeta,
  [ChainId.MANTA_TESTNET]: Manta,
  [ChainId.BLAST_TESTNET]: Blast,
  [ChainId.BLAST]: Blast,
  [ChainId.BASE]: Base,
}

export const DAPP_CHAINS =
  process.env.NEXT_PUBLIC_TEST_MODE === '1'
    ? [
        ChainId.BSC,
        ChainId.ETHEREUM,
        ChainId.BSC_TESTNET,
        ChainId.GOERLI,
        ChainId.NEO_EVM_TESTNET,
        ChainId.ZETA_TESTNET,
        ChainId.MANTA_TESTNET,
        ChainId.BLAST_TESTNET,
      ]
    : [ChainId.BSC, ChainId.ETHEREUM]

export const MAINNET_CHAINS = [
  ChainId.BSC,
  ChainId.ETHEREUM,
  ChainId.ARBITRUM,
  ChainId.POLYGON,
  ChainId.ZKSYNC,
  ChainId.OPTIMISM,
  ChainId.BLAST,
]

export const SWAP_MAINNET_CHAINS = [
  ChainId.BSC,
  ChainId.ETHEREUM,
  ChainId.ARBITRUM,
  ChainId.POLYGON,
  ChainId.ZKSYNC,
  ChainId.OPTIMISM,
  ChainId.BLAST,
  ChainId.BASE,
]

export const TESTNET_CHAINS =
  process.env.NEXT_PUBLIC_TEST_MODE === '1'
    ? [
        ChainId.BSC_TESTNET,
        ChainId.GOERLI,
        ChainId.NEO_EVM_TESTNET,
        ChainId.ZETA_TESTNET,
        ChainId.MANTA_TESTNET,
        ChainId.ARBITRUM_TESTNET,
        ChainId.POLYGON_TESTNET,
        ChainId.ZKSYNC_TESTNET,
        ChainId.OPTIMISM_TESTNET,
        LINEA_TESTNET,
        ChainId.BLAST_TESTNET,
      ]
    : [
        ChainId.BSC_TESTNET,
        ChainId.GOERLI,
        ChainId.NEO_EVM_TESTNET,
        ChainId.ZETA_TESTNET,
        ChainId.MANTA_TESTNET,
        ChainId.BLAST_TESTNET,
      ]

export const BRIDGE_CHAINS_ONLY =
  process.env.NEXT_PUBLIC_TEST_MODE === '1'
    ? [
        ChainId.ARBITRUM,
        ChainId.POLYGON,
        ChainId.ZKSYNC,
        ChainId.OPTIMISM,
        ChainId.ARBITRUM_TESTNET,
        ChainId.POLYGON_TESTNET,
        ChainId.ZKSYNC_TESTNET,
        ChainId.OPTIMISM_TESTNET,
        LINEA_TESTNET,
        ChainId.NEO_EVM_TESTNET,
        ChainId.ZETA_TESTNET,
        ChainId.MANTA_TESTNET,
        ChainId.BLAST_TESTNET,
      ]
    : [ChainId.ARBITRUM, ChainId.POLYGON, ChainId.ZKSYNC, ChainId.OPTIMISM, ChainId.BLAST]

export const NETWORK_LABEL =
  process.env.NEXT_PUBLIC_TEST_MODE === '1'
    ? {
        [ChainId.RINKEBY]: 'Rinkeby',
        [ChainId.BSC_TESTNET]: 'BSC Testnet',
        [ChainId.BSC]: 'BSC',
        [ChainId.ETHEREUM]: 'Ethereum',
        [ChainId.GOERLI]: 'Goerli',
        [ChainId.ARBITRUM]: 'Arbitrum',
        [ChainId.ARBITRUM_TESTNET]: 'ArbitrumTest',
        [ChainId.POLYGON]: 'Polygon',
        [ChainId.POLYGON_TESTNET]: 'PolygonTest',
        [ChainId.ZKSYNC]: 'zkSync',
        [ChainId.ZKSYNC_TESTNET]: 'zkSyncTest',
        [ChainId.OPTIMISM]: 'Optimism',
        [ChainId.OPTIMISM_TESTNET]: 'OptimismTest',
        [ChainId.NEO_EVM_TESTNET]: 'NeoEVMTest',
        [ChainId.ZETA_TESTNET]: 'ZetaChainTest',
        [ChainId.MANTA_TESTNET]: 'MantaChainTest',
        [ChainId.BLAST_TESTNET]: 'BlastSepoliaTestnet',
      }
    : {
        [ChainId.BSC]: 'BSC',
        [ChainId.ETHEREUM]: 'Ethereum',
        [ChainId.ARBITRUM]: 'Arbitrum',
        [ChainId.POLYGON]: 'Polygon',
        [ChainId.ZKSYNC]: 'zkSync',
        [ChainId.OPTIMISM]: 'Optimism',
        [ChainId.BLAST]: 'Blast',
        [ChainId.BASE]: 'Base',
      }

export const NETWORK_LINK = {
  [ChainId.ETHEREUM]: 'https://etherscan.io',
  [ChainId.RINKEBY]: 'https://rinkeby.etherscan.io',
  [ChainId.GOERLI]: 'https://goerli.etherscan.io',
  [ChainId.BSC]: 'https://bscscan.com',
  [ChainId.BSC_TESTNET]: 'https://testnet.bscscan.com',
  [ChainId.ARBITRUM]: 'https://arbiscan.io',
  [ChainId.ARBITRUM_TESTNET]: 'https://goerli-rollup-explorer.arbitrum.io',
  [ChainId.POLYGON]: 'https://polygonscan.com',
  [ChainId.POLYGON_TESTNET]: 'https://mumbai.polygonscan.com',
  [ChainId.ZKSYNC]: 'https://zkscan.io',
  [ChainId.ZKSYNC_TESTNET]: 'https://goerli.explorer.zksync.io',
  [ChainId.OPTIMISM]: 'https://optimistic.etherscan.io',
  [ChainId.OPTIMISM_TESTNET]: 'https://goerli-optimism.etherscan.io',
  [LINEA_TESTNET]: 'https://goerli.lineascan.build',
  [ChainId.NEO_EVM_TESTNET]: 'https://evm.ngd.network',
  [ChainId.ZETA_TESTNET]: 'https://explorer.zetachain.com',
  [ChainId.MANTA_TESTNET]: 'https://pacific-explorer.testnet.manta.network',
  [ChainId.BLAST]: 'https://blastscan.io/',
  [ChainId.BLAST_TESTNET]: 'https://sepolia.blastscan.io/',
  [ChainId.BASE]: 'https://basescan.org/',
}

export const SITE_NAME =
  process.env.NEXT_PUBLIC_TEST_MODE === '1'
    ? {
        [ChainId.ETHEREUM]: 'Etherscan',
        [ChainId.RINKEBY]: 'Etherscan',
        [ChainId.GOERLI]: 'Etherscan',
        [ChainId.BSC]: 'Bscscan',
        [ChainId.BSC_TESTNET]: 'Bscscan',
        [ChainId.ARBITRUM]: 'Arbiscan',
        [ChainId.ARBITRUM_TESTNET]: 'Arbiscan',
        [ChainId.POLYGON]: 'Polygonscan',
        [ChainId.POLYGON_TESTNET]: 'Polygonscan',
        [ChainId.ZKSYNC]: 'Zkscan',
        [ChainId.ZKSYNC_TESTNET]: 'Zkscan',
        [ChainId.OPTIMISM]: 'Optimismscan',
        [ChainId.OPTIMISM_TESTNET]: 'Optimismscan',
        [LINEA_TESTNET]: 'Lineascan',
        [ChainId.NEO_EVM_TESTNET]: 'Neoscan',
        [ChainId.ZETA_TESTNET]: 'Zetascan',
        [ChainId.MANTA_TESTNET]: 'Mantascan',
        [ChainId.BLAST_TESTNET]: 'Blastscan',
      }
    : {
        [ChainId.ETHEREUM]: 'Etherscan',
        [ChainId.BSC]: 'Bscscan',
        [ChainId.ARBITRUM]: 'Arbiscan',
        [ChainId.POLYGON]: 'Polygonscan',
        [ChainId.ZKSYNC]: 'Zkscan',
        [ChainId.OPTIMISM]: 'Optimismscan',
        [ChainId.NEO_EVM_TESTNET]: 'Neoscan',
        [ChainId.ZETA_TESTNET]: 'Zetascan',
        [ChainId.MANTA_TESTNET]: 'Mantascan',
        [ChainId.BLAST]: 'Blastscan',
        [ChainId.BASE]: 'Basescan',
      }

export const CONTRACT_BRIDGE_POOL = {
  [ChainId.GOERLI]: '0x73343603ba308b292abD75980Ad4703caD275E37',
}

export const KYBERSWAP_NETWORK_CHAIN = {
  [ChainId.BSC]: 'bsc',
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.ARBITRUM]: 'arbitrum',
  [ChainId.POLYGON]: 'polygon',
  [ChainId.ZKSYNC]: 'zksync',
  [ChainId.OPTIMISM]: 'optimism',
  [LINEA_TESTNET]: 'linea-goerli',
  [ChainId.NEO_EVM_TESTNET]: 'neo-evm',
  [ChainId.ZETA_TESTNET]: 'zeta-chain',
  [ChainId.MANTA_TESTNET]: 'manta-chain',
  [ChainId.BLAST]: 'blast',
  [ChainId.BASE]: 'base',
}
