/* eslint-disable react/no-unknown-property */
import { SvgProps } from '@pancakeswap/uikit'
import React from 'react'

const XOXLock: React.FC<React.PropsWithChildren<SvgProps>> = () => {
  return (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.86531 7.63158V3.94737C3.86531 2.31958 5.18489 1 6.81268 1C8.44047 1 9.76004 2.31935 9.76004 3.94714C9.76004 5.44389 9.76004 7.06404 9.76004 7.63158M3.12846 7.63158H10.4969C11.3108 7.63158 11.9706 8.29137 11.9706 9.10526V13.5263C11.9706 14.3402 11.3108 15 10.4969 15H3.12846C2.31457 15 1.65479 14.3402 1.65479 13.5263V9.10526C1.65479 8.29137 2.31457 7.63158 3.12846 7.63158Z"
        stroke="#515151"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  )
}

export default XOXLock
