import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9004 21C17.871 21 21.9004 16.9706 21.9004 12C21.9004 7.02944 17.871 3 12.9004 3C7.92983 3 3.90039 7.02944 3.90039 12C3.90039 16.9706 7.92983 21 12.9004 21Z"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9004 14.25V13.5C13.4937 13.5 14.0738 13.3241 14.5671 12.9944C15.0604 12.6648 15.445 12.1962 15.672 11.6481C15.8991 11.0999 15.9585 10.4967 15.8427 9.91473C15.727 9.33279 15.4413 8.79824 15.0217 8.37868C14.6022 7.95912 14.0676 7.6734 13.4857 7.55765C12.9037 7.44189 12.3005 7.5013 11.7523 7.72836C11.2042 7.95543 10.7356 8.33994 10.406 8.83329C10.0763 9.32664 9.90039 9.90666 9.90039 10.5"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9004 18.375C13.5217 18.375 14.0254 17.8713 14.0254 17.25C14.0254 16.6287 13.5217 16.125 12.9004 16.125C12.2791 16.125 11.7754 16.6287 11.7754 17.25C11.7754 17.8713 12.2791 18.375 12.9004 18.375Z"
        fill="#515151"
      />
    </svg>
  );
};

export default Icon;
