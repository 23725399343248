/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit'
import { ChainId } from '@pancakeswap/sdk'
import {
  toggleFarmTransactionModal,
  pickFarmTransactionTx,
  getDataStats,
  getUserData,
  getReferralData,
  updateTotalXOXSoldInETH,
} from './actions'

export interface GlobalState {
  showFarmTransactionModal: boolean
  pickedFarmTransactionModalTx: {
    tx: string
    chainId: ChainId
  }
  dataStats: any
  userData: any
  referralData: any
  totalXOXSoldInETH: number
}

export const initialState: GlobalState = {
  showFarmTransactionModal: false,
  pickedFarmTransactionModalTx: {
    tx: '',
    chainId: ChainId.BSC,
  },
  dataStats: {},
  userData: {},
  referralData: {},
  totalXOXSoldInETH: 0,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(toggleFarmTransactionModal, (state, { payload: { showModal } }) => {
      state.showFarmTransactionModal = showModal
    })
    .addCase(pickFarmTransactionTx, (state, { payload: { tx, chainId } }) => {
      state.pickedFarmTransactionModalTx = { tx, chainId }
      state.showFarmTransactionModal = true
    })
    .addCase(getDataStats, (state, action) => {
      state.dataStats = action.payload
    })
    .addCase(getUserData, (state, action) => {
      state.userData = action.payload
    })
    .addCase(getReferralData, (state, action) => {
      state.referralData = action.payload
    })
    .addCase(updateTotalXOXSoldInETH, (state, action) => {
      state.totalXOXSoldInETH = action.payload
    }),
)
