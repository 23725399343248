/* eslint-disable react/no-unknown-property */
import React from "react";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="67"
      height="43"
      viewBox="0 0 67 43"
      fill="none"
      className={className}
    >
      <path
        d="M31.5095 24.8045L41.5528 34.8478C42.4479 35.7429 42.9511 36.9571 42.9511 38.2229V38.2242C42.9511 40.8605 40.814 42.9989 38.1764 42.9989H37.8811C36.6153 42.9989 35.4011 42.4958 34.506 41.6007L24.7566 31.8513C23.8616 30.9563 23.3584 29.7421 23.3584 28.4762V28.1809C23.3584 25.5447 25.4955 23.4062 28.1331 23.4062H28.1344C29.4003 23.4062 30.6145 23.9094 31.5095 24.8045Z"
        fill="white"
      />
      <path
        d="M66.3573 28.1809V28.4775C66.3573 29.7434 65.8542 30.9576 64.9591 31.854L55.2097 41.602C54.3147 42.4971 53.1005 43.0002 51.8346 43.0002H51.5393C48.9031 43.0002 46.7646 40.8632 46.7646 38.2255V38.2242C46.7646 36.9584 47.2678 35.7442 48.1629 34.8478L58.2075 24.8045C59.1026 23.9094 60.3168 23.4062 61.5826 23.4062C64.2202 23.4062 66.3573 25.5433 66.3573 28.1809Z"
        fill="white"
      />
      <path
        d="M37.8811 0H38.1777C40.814 0 42.9524 2.13709 42.9524 4.7747C42.9524 6.04053 42.4492 7.25473 41.5541 8.14982L31.5109 18.1944C30.6158 19.0895 29.4016 19.5927 28.1344 19.5927H28.1331C25.4968 19.5927 23.3584 17.4556 23.3584 14.818V14.5227C23.3584 13.2569 23.8616 12.0427 24.7566 11.1476L34.5046 1.39825C35.3997 0.503159 36.6139 0 37.8811 0Z"
        fill="white"
      />
      <path
        d="M66.3573 14.5224V14.8189C66.3573 17.4552 64.2202 19.5936 61.5826 19.5936C60.3168 19.5936 59.1026 19.0905 58.2075 18.1954L48.1629 8.15079C47.2678 7.25571 46.7646 6.04151 46.7646 4.77567C46.7646 2.13939 48.9017 0.000976562 51.5393 0.000976562H51.8359C53.1018 0.000976562 54.316 0.504133 55.2111 1.39922L64.9591 11.1472C65.8542 12.041 66.3573 13.2552 66.3573 14.5224Z"
        fill="white"
      />
      <path
        d="M19.5927 28.1809V28.4775C19.5927 29.7434 19.0895 30.9576 18.1944 31.854L8.44509 41.602C7.55 42.4971 6.33581 43.0002 5.06997 43.0002H4.77469C2.13842 43.0002 0 40.8632 0 38.2255V38.2242C0 36.9584 0.503157 35.7442 1.39825 34.8478L11.4429 24.8045C12.3379 23.9094 13.5521 23.4062 14.818 23.4062C17.4556 23.4062 19.5927 25.5433 19.5927 28.1809Z"
        fill="white"
      />
      <path
        d="M19.5927 14.5224V14.8189C19.5927 17.4552 17.4556 19.5936 14.818 19.5936C13.5521 19.5936 12.3379 19.0905 11.4429 18.1954L1.39825 8.15079C0.503157 7.25571 0 6.04151 0 4.77567C0 2.13939 2.13709 0.000976562 4.77469 0.000976562H5.07129C6.33713 0.000976562 7.55133 0.504133 8.44642 1.39922L18.1944 11.1472C19.0895 12.041 19.5927 13.2552 19.5927 14.5224Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
