import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreateProposalState, IProposalCreate } from './type'

const initialState: CreateProposalState = {
  submitted: false,
  mode: 'edit',
  contributing: false,
  requireRaiseTarget: true,

  proposal: {
    id: 'N',
    title: undefined,
    description: undefined,
    status: 'Ongoing',
    votingPowerRewardToken: 'USDT',
    votingRewardAmount: undefined,
    discussionGroupLink: undefined,
    votingPeriodStart: undefined,
    votingPeriodEnd: undefined,
    imageURL: undefined,
    options: [
      { id: 1, value: undefined },
      { id: 2, value: undefined },
      { id: 3, value: undefined },
    ],
    tags: [],

    contribute: {
      title: undefined,
      subtitle: undefined,
      raiseTarget: undefined,
      description: undefined,
      contributeTokens: ['ETH'],
      contributePeriodStart: undefined,
      contributePeriodEnd: undefined,
    },
  },
  errors: {},
}

export const createProposalSlice = createSlice({
  name: 'createProposal',
  initialState,
  reducers: {
    setProposal: (state, action: PayloadAction<IProposalCreate>) => {
      state.proposal = action.payload
    },
    setMode: (state, action: PayloadAction<'edit' | 'preview'>) => {
      state.mode = action.payload
    },
    setSubmitted: (state, action: PayloadAction<boolean>) => {
      state.submitted = action.payload
    },
    setContribute: (state, action: PayloadAction<boolean>) => {
      state.contributing = action.payload
    },
    setRequireRaiseTarget: (state, action: PayloadAction<boolean>) => {
      state.requireRaiseTarget = action.payload
    },
    setErrors: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.errors = action.payload
    },
    clearCreateProposal: () => {
      return initialState
    },
  },
})

export const {
  setProposal,
  setMode,
  setContribute,
  clearCreateProposal,
  setErrors,
  setSubmitted,
  setRequireRaiseTarget,
} = createProposalSlice.actions

export const createProposalReducer = createProposalSlice.reducer
