import { ModalV2 } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { CHAIN_IDS } from 'utils/wagmi'
import { ChainId } from '@pancakeswap/sdk'
import { useMemo, useState } from 'react'
import { useNetwork } from 'wagmi'
import { atom, useAtom } from 'jotai'
import { SUPPORT_ONLY_BSC, SUPPORT_ONLY_ETH } from 'config/constants/supportChains'
import { useRouter } from 'next/router'
import { UnsupportedNetworkModal } from './UnsupportedNetworkModal'
import { WrongNetworkModal } from './WrongNetworkModal'
import { PageNetworkSupportModal } from './PageNetworkSupportModal'
import { PageNetworkSupportPreSaleModal } from './PageNetworkSupportPreSaleModal'

export const hideWrongNetworkModalAtom = atom(false)

export const NetworkModal = ({ pageSupportedChains = SUPPORT_ONLY_ETH }: { pageSupportedChains?: number[] }) => {
  const { chainId, chain, isWrongNetwork } = useActiveWeb3React()
  const { chains } = useNetwork()
  const [dismissWrongNetwork, setDismissWrongNetwork] = useAtom(hideWrongNetworkModalAtom)
  const [openPreSales, setOpenPreSales] = useState(true)

  const router = useRouter()

  // const isBNBOnlyPage = useMemo(() => {
  //   return pageSupportedChains?.length === 1 && pageSupportedChains[0] === ChainId.BSC
  // }, [pageSupportedChains])

  const isPageNotSupported = useMemo(
    () => Boolean(pageSupportedChains.length) && !pageSupportedChains.includes(chainId),
    [chainId, pageSupportedChains],
  )

  const chainSupportPreSale = [ChainId.BSC, ChainId.ETHEREUM]
  
  if (!chainSupportPreSale.includes(chainId) && router.pathname === '/pre-sales') {
    return (
      <ModalV2 isOpen={openPreSales} closeOnOverlayClick onDismiss={() => setOpenPreSales(false)}>
        <PageNetworkSupportPreSaleModal />
      </ModalV2>
    )
  }
  // const chainSupportSeedSale = [ChainId.GOERLI, ChainId.ETHEREUM]
  // if (!chainSupportSeedSale.includes(chainId) && router.pathname === '/vesting-info') {
  //   return (
  //     <ModalV2 isOpen closeOnOverlayClick={false}>
  //       <PageNetworkSupportModal />
  //     </ModalV2>
  //   )
  // }

  // if ((chain?.unsupported ?? false) || isPageNotSupported) {
  //   return (
  //     <ModalV2 isOpen closeOnOverlayClick={false}>
  //       <UnsupportedNetworkModal pageSupportedChains={pageSupportedChains?.length ? pageSupportedChains : CHAIN_IDS} />
  //     </ModalV2>
  //   )
  // }

  if (isWrongNetwork && !dismissWrongNetwork) {
    const currentChain = chains.find((c) => c.id === chainId)
    if (!currentChain) return null
    return (
      <ModalV2 isOpen={isWrongNetwork} closeOnOverlayClick onDismiss={() => setDismissWrongNetwork(true)}>
        <WrongNetworkModal currentChain={currentChain} onDismiss={() => setDismissWrongNetwork(true)} />
      </ModalV2>
    )
  }

  return null
}
