import { CHAIN_ID } from '@gelatonetwork/limit-orders-lib/dist/constants'
import { multicallAddresses } from '@pancakeswap/multicall'
import { ChainId } from '@pancakeswap/sdk'

export default {
  masterChef: {
    [ChainId.BSC_TESTNET]: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    [ChainId.BSC]: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
  },
  masterChefV1: {
    [ChainId.BSC_TESTNET]: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    [ChainId.BSC]: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    [ChainId.BSC_TESTNET]: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    [ChainId.BSC]: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    [ChainId.BSC_TESTNET]: '0x5790c3534F30437641541a0FA04C992799602998',
    [ChainId.BSC]: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: multicallAddresses,
  pancakeProfile: {
    [ChainId.BSC]: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    [ChainId.BSC_TESTNET]: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeBunnies: {
    [ChainId.BSC]: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    [ChainId.BSC_TESTNET]: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    [ChainId.BSC]: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    [ChainId.BSC_TESTNET]: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    [ChainId.BSC]: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    [ChainId.BSC_TESTNET]: '',
  },
  pointCenterIfo: {
    [ChainId.BSC]: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    [ChainId.BSC_TESTNET]: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    [ChainId.BSC]: '0xFee8A195570a18461146F401d6033f5ab3380849',
    [ChainId.BSC_TESTNET]: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionEaster: {
    [ChainId.BSC]: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    [ChainId.BSC_TESTNET]: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  tradingCompetitionFanToken: {
    [ChainId.BSC]: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    [ChainId.BSC_TESTNET]: '',
  },
  tradingCompetitionMobox: {
    [ChainId.BSC]: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    [ChainId.BSC_TESTNET]: '',
  },
  tradingCompetitionMoD: {
    [ChainId.BSC]: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    [ChainId.BSC_TESTNET]: '',
  },
  easterNft: {
    [ChainId.BSC]: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    [ChainId.BSC_TESTNET]: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    [ChainId.BSC]: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
    [ChainId.BSC_TESTNET]: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
  },
  cakeFlexibleSideVault: {
    [ChainId.BSC]: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    [ChainId.BSC_TESTNET]: '',
  },
  predictionsBNB: {
    [ChainId.BSC]: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    [ChainId.BSC_TESTNET]: '',
  },
  predictionsCAKE: {
    [ChainId.BSC]: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    [ChainId.BSC_TESTNET]: '',
  },
  chainlinkOracleBNB: {
    [ChainId.BSC]: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    [ChainId.BSC_TESTNET]: '',
  },
  chainlinkOracleCAKE: {
    [ChainId.BSC]: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    [ChainId.BSC_TESTNET]: '',
  },
  predictionsV1: {
    [ChainId.BSC]: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    [ChainId.BSC_TESTNET]: '',
  },
  bunnySpecialCakeVault: {
    [ChainId.BSC]: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    [ChainId.BSC_TESTNET]: '',
  },
  bunnySpecialPrediction: {
    [ChainId.BSC]: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    [ChainId.BSC_TESTNET]: '',
  },
  bunnySpecialLottery: {
    [ChainId.BSC]: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    [ChainId.BSC_TESTNET]: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    [ChainId.BSC]: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    [ChainId.BSC_TESTNET]: '',
  },
  farmAuction: {
    [ChainId.BSC]: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    [ChainId.BSC_TESTNET]: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    [ChainId.BSC]: '0xF839286bD9D14b358496829F3BaB3145C16ad3C1',
    [ChainId.BSC_TESTNET]: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    [ChainId.BSC]: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    [ChainId.BSC_TESTNET]: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    [ChainId.BSC]: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    [ChainId.BSC_TESTNET]: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    [ChainId.BSC]: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    [ChainId.BSC_TESTNET]: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  potteryDraw: {
    [ChainId.BSC]: '0x01871991587d5671f3A2d4E2BcDC22F4E026396e',
    [ChainId.BSC_TESTNET]: '0xDB9D365b50E62fce747A90515D2bd1254A16EbB9',
  },
  // TODO: multi
  zap: {
    [ChainId.BSC]: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    [ChainId.BSC_TESTNET]: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
  },
  iCake: {
    [ChainId.BSC]: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    [ChainId.BSC_TESTNET]: '',
  },
  bCakeFarmBooster: {
    [ChainId.BSC]: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    [ChainId.BSC_TESTNET]: '',
  },
  bCakeFarmBoosterProxyFactory: {
    [ChainId.BSC]: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    [ChainId.BSC_TESTNET]: '',
  },
  nonBscVault: {
    [ChainId.BSC]: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841', // Only for pass contracts test
    [ChainId.ETHEREUM]: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
    [ChainId.GOERLI]: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
  },
  crossFarmingSender: {
    [ChainId.BSC]: '0x327d26dE30f92600620A99043034e0A5FD9402C8', // Only for pass contracts test
    [ChainId.ETHEREUM]: '0x8EA90Ef07f37c77137453C7A1B72B7886d51eCFb',
    [ChainId.GOERLI]: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
  },
  crossFarmingReceiver: {
    [ChainId.BSC]: '0x0726a8C8206b9eC0AfB788df5adb36a8AEDB13c2',
    [ChainId.BSC_TESTNET]: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
  },
  treasuryXOX: {
    [ChainId.BSC_TESTNET]: '0x75953D881A92BB4Ad928782d749a95B7c7291Cc8',
    [ChainId.GOERLI]: '0xc7952Aef9983a084F91dE96622a84e70792970A5',
    [ChainId.ETHEREUM]: '0x741cfAF30f4B9b08FD2c15A695B488c9fa8e6414',
    [ChainId.BSC]: '0x741cfAF30f4B9b08FD2c15A695B488c9fa8e6414',
    [ChainId.ARBITRUM]: '0xB29C3282761AFf7603a95a62B638d64bf701b79F',
    [ChainId.POLYGON]: '0xB29C3282761AFf7603a95a62B638d64bf701b79F',
    [ChainId.OPTIMISM]: '0xB29C3282761AFf7603a95a62B638d64bf701b79F',
    [ChainId.ZKSYNC]: '0x320897daC1C0f7F0c033118f3ec13EC1039160C9',
    [ChainId.NEO_EVM_TESTNET]: '0xEb2899a96f2017567e95ab9E7fDa3C0A216Ce8F9',
    [ChainId.ZETA_TESTNET]: '0xb1E192009A7230D359352A849Fe869617722182b',
    [ChainId.MANTA_TESTNET]: '0x9d2fDbF84fd2f2D2D9F4383960A9565f6f5F78C1',
    [ChainId.POLYGON_TESTNET]: '0xD898D309dAb33130EA57E8F106238ae4b76329f4',
    [ChainId.BLAST]: '0x5f4614021C03A27F6E5c807DB878670939B72993',
    [ChainId.BLAST_TESTNET]: '0xEb2899a96f2017567e95ab9E7fDa3C0A216Ce8F9',
  },
  contractXOXToken: {
    [ChainId.BSC_TESTNET]: '0xdd94e8a963BbD1513840aC38C5727fa5Da4a3095',
    [ChainId.GOERLI]: '0x7C06f79a65Ff6d9520bf8604a37F20deA000A3d2',
    [ChainId.ETHEREUM]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748', // TODO: change address
    [ChainId.BSC]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
    [ChainId.ARBITRUM]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748', // TODO: change address
    [ChainId.ARBITRUM_TESTNET]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748', // TODO: change address
    [ChainId.POLYGON]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748', // TODO: change address
    [ChainId.POLYGON_TESTNET]: '0x8cC55D86a49A5FdCCb5011224Bb18F56a75f6b53', // TODO: change address
    [ChainId.ZKSYNC]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748', // TODO: change address
    [ChainId.ZKSYNC_TESTNET]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748', // TODO: change address
    [ChainId.OPTIMISM]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748', // TODO: change address
    [ChainId.OPTIMISM_TESTNET]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748', // TODO: change address
    [ChainId.NEO_EVM_TESTNET]: '0x3B6dFC89760d5B6F64C043A74210ddF91264Beef',
    [ChainId.ZETA_TESTNET]: '0x3B6dFC89760d5B6F64C043A74210ddF91264Beef',
    [ChainId.MANTA_TESTNET]: '0x56B09E39168b141E43c49eeF261ed9A5f105A6DF',
    [ChainId.BLAST]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
    [ChainId.BLAST_TESTNET]: '0x90fded525b5E613c9dc0fE600d9b52a648386e2E',
  },
  contractXOXPool: {
    [ChainId.BSC_TESTNET]: '0x6e2f695A731DA11fce2F996eD26BffDd9E35e0AF',
    [ChainId.GOERLI]: '0x4B6b6cAD103521832D78F140E89A22c70703d72c',
    [ChainId.NEO_EVM_TESTNET]: '0xFd8D6370eb37B62194d5CC0Dcdeb24A61878c8e7',
    [ChainId.ZETA_TESTNET]: '0x76F4F0fcB3E39Df09D4A3617BF71BFFca528c5D5',
    [ChainId.MANTA_TESTNET]: '0xC1A128E71C799eDF22785e80fB33a77a87296C8f',
    [ChainId.BLAST]: '',
    [ChainId.BLAST_TESTNET]: '',
  },
  contractXOXSecondPool: {
    [ChainId.ETHEREUM]: '0xA32F2cb7787696e75ffABEB6EBf88bdf72E65efE',
    [ChainId.BSC]: '0x8d32796a536A73AC81208e8579047ba9D42cbb22',
    [ChainId.BSC_TESTNET]: '0xebaa9ab545de44f38170218082fe25ff30c69d7f',
    [ChainId.GOERLI]: '0x4cDF5e879027da0151507aE3F9073c7B604a70C4',
    [ChainId.NEO_EVM_TESTNET]: '0xc08501Bc4031F559ac033C35e7Fa5850EDCb87Fa',
    [ChainId.ZETA_TESTNET]: '0x26395Ac8E0B0E3215b8967C5c90b3bc32F65ebFC',
    [ChainId.MANTA_TESTNET]: '0x588be3F16FB1969E4cd792eEAD63d69B02c3b36E',
    [ChainId.BLAST]: '',
    [ChainId.BLAST_TESTNET]: '',
  },
  contractBridgeToken: {
    [ChainId.ETHEREUM]: '0x73343603ba308b292abD75980Ad4703caD275E37', // TODO: change address
    [ChainId.GOERLI]: '0x8BB2033C0874514eE39900c8aF3B94F5ac895968', // TODO: change address
    [ChainId.BSC]: '0x73343603ba308b292abD75980Ad4703caD275E37', // TODO: change address
    [ChainId.BSC_TESTNET]: '0x73343603ba308b292abD75980Ad4703caD275E37', // TODO: change address
    [ChainId.ARBITRUM]: '0x73343603ba308b292abD75980Ad4703caD275E37', // TODO: change address
    [ChainId.ARBITRUM_TESTNET]: '0x73343603ba308b292abD75980Ad4703caD275E37', // TODO: change address
    [ChainId.POLYGON]: '0x73343603ba308b292abD75980Ad4703caD275E37', // TODO: change address
    [ChainId.POLYGON_TESTNET]: '0x73343603ba308b292abD75980Ad4703caD275E37', // TODO: change address
    [ChainId.ZKSYNC]: '0x73343603ba308b292abD75980Ad4703caD275E37', // TODO: change address
    [ChainId.ZKSYNC_TESTNET]: '0x73343603ba308b292abD75980Ad4703caD275E37', // TODO: change address
    [ChainId.OPTIMISM]: '0x73343603ba308b292abD75980Ad4703caD275E37', // TODO: change address
    [ChainId.OPTIMISM_TESTNET]: '0x73343603ba308b292abD75980Ad4703caD275E37', // TODO: change address
  },
  xoxToken: {
    [ChainId.BSC_TESTNET]: '0xdd94e8a963BbD1513840aC38C5727fa5Da4a3095',
    [ChainId.GOERLI]: '0x7C06f79a65Ff6d9520bf8604a37F20deA000A3d2',
    [ChainId.ETHEREUM]: '', // TODO: change address
    [ChainId.BSC]: '',
    [ChainId.NEO_EVM_TESTNET]: '0x3B6dFC89760d5B6F64C043A74210ddF91264Beef',
    [ChainId.ZETA_TESTNET]: '0x3B6dFC89760d5B6F64C043A74210ddF91264Beef',
    [ChainId.MANTA_TESTNET]: '0x56B09E39168b141E43c49eeF261ed9A5f105A6DF',
    [ChainId.BLAST]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
    [ChainId.BLAST_TESTNET]: '0x90fded525b5E613c9dc0fE600d9b52a648386e2E',
  },
  farmingLP: {
    [ChainId.BSC_TESTNET]: '0xA5002aFA8B19a6B4A6040bB0b82a07dA56291E4d',
    [ChainId.GOERLI]: '0xB35A67a64aC6da4fB755Eb3913E60eD282eFf6c1',
    [ChainId.ETHEREUM]: '0x39978c4F3885C83D78C3f178Dc7f080031aBeEcA',
    [ChainId.BSC]: '0xc962347390B78ecd3C6694ffE3bA6428758A8068',
    [ChainId.NEO_EVM_TESTNET]: '0x20d51b9f4591713C6c6CE83bEf892a3FA09D560f',
    [ChainId.ZETA_TESTNET]: '0x638EfB2e09dd91feD89F282E455E6D791736dA09',
    [ChainId.MANTA_TESTNET]: '0x4a8790723e076C8E5DBEBeF6F10924169bD88f2D',
    [ChainId.BLAST]: '',
    [ChainId.BLAST_TESTNET]: '',
  },
  preSaleContactAddress: {
    [ChainId.GOERLI]: '0x154208405ab59e7B664C65Dc79E08f1C4B78Cd6b',
    [ChainId.BSC_TESTNET]: '0x417e8DFa92601Ee5E4782cEec62f9aCd534C87E6',
    [ChainId.BSC]: '0xEd9668A07C14117f782aF3e0119ba16F7CC6Fe6C',
    [ChainId.ETHEREUM]: '0xEd9668A07C14117f782aF3e0119ba16F7CC6Fe6C',
    [ChainId.BLAST]: '0xC04Fe416bC842A3D2F46f739B67345Fc6937c19F',
    [ChainId.BLAST_TESTNET]: '0x956A8817b836D2a8b724376AFb651832b7c364ed',
  },
  seedSaleContactAddress: {
    [ChainId.GOERLI]: '0x201b23431B1D1c7485D39FFCddE16b90dF06F588',
    [ChainId.BSC_TESTNET]: '',
    [ChainId.BSC]: '',
    [ChainId.ETHEREUM]: '0xD6077773C921F0fE3E5b3233af0D263336258D36', // TODO: change address
  },
  airdropContractAddress: {
    [ChainId.BSC]: '0xee93017A071E2489DB82D48079F69b968B7a0e4c',
    [ChainId.BSC_TESTNET]: '0xd1FCc34E9eEd2ecFbEBAcFe4c47D5273753BCeD2',
  },
  stakingContractAddress: {
    [ChainId.GOERLI]: '0xCDa475af4be1AAB7771fcfD28F09073817e98CB0',
    [ChainId.BSC_TESTNET]: '0xAa287F1fFf8dA05764690DD34dcBaAF0D9D5103d',
    [ChainId.BSC]: '0x9BbAa1B24d87855e6321d3Ecf24AB9dE4Ccd567B',
    [ChainId.ETHEREUM]: '0x640a4Bc070328Ef23971B0D9ec3cb3A120f706Fe',
    [ChainId.BLAST]: '',
  },
  AITECH: {
    [ChainId.BSC]: '0x2d060ef4d6bf7f9e5edde373ab735513c0e4f944',
    [ChainId.ETHEREUM]: '',
    [ChainId.POLYGON]: '',
    [ChainId.ARBITRUM]: '',
    [ChainId.OPTIMISM]: '',
    [ChainId.ZKSYNC]: '',
    [ChainId.BLAST]: '',
  },
  ORION: {
    [ChainId.BSC]: '0xe4ca1f75eca6214393fce1c1b316c237664eaa8e',
    [ChainId.ETHEREUM]: '0x0258F474786DdFd37ABCE6df6BBb1Dd5dfC4434a', // TODO: change address
    [ChainId.POLYGON]: '',
    [ChainId.ARBITRUM]: '',
    [ChainId.OPTIMISM]: '',
    [ChainId.ZKSYNC]: '',
    [ChainId.BLAST]: '',
  },
  FRAX: {
    [ChainId.POLYGON_TESTNET]: '0x1b57aF4ab903fD70f96Ff033498dF44D7B61201C',
    [ChainId.BSC]: '0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40',
    [ChainId.ETHEREUM]: '0x853d955acef822db058eb8505911ed77f175b99e',
    [ChainId.POLYGON]: '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89',
    [ChainId.ARBITRUM]: '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
    [ChainId.OPTIMISM]: '0x2E3D870790dC77A83DD1d18184Acc7439A53f475',
    [ChainId.ZKSYNC]: '',
    [ChainId.BLAST]: '',
  },
  RBC: {
    [ChainId.BSC]: '',
    [ChainId.ETHEREUM]: '0x3330bfb7332ca23cd071631837dc289b09c33333',
    [ChainId.POLYGON]: '',
    [ChainId.ARBITRUM]: '0x10aAeD289a7b1B0155bF4b86c862f297E84465e0',
    [ChainId.OPTIMISM]: '',
    [ChainId.ZKSYNC]: '',
    [ChainId.BLAST]: '',
  },
  ELK: {
    [ChainId.GOERLI]: '',
    [ChainId.BSC]: '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
    [ChainId.ETHEREUM]: '', // TODO: change address
    [ChainId.POLYGON]: '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
    [ChainId.ARBITRUM]: '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
    [ChainId.OPTIMISM]: '',
    [ChainId.ZKSYNC]: '',
    [ChainId.BLAST]: '',
  },
  VELOCORE: {
    [ChainId.BSC]: '',
    [ChainId.ETHEREUM]: '', // TODO: change address
    [ChainId.POLYGON]: '',
    [ChainId.ARBITRUM]: '',
    [ChainId.OPTIMISM]: '',
    [ChainId.ZKSYNC]: '0x85D84c774CF8e9fF85342684b0E795Df72A24908',
    [ChainId.BLAST]: '',
  },
  WOMBAT: {
    [ChainId.BSC]: '0xad6742a35fb341a9cc6ad674738dd8da98b94fb1',
    [ChainId.ETHEREUM]: '0xc0B314a8c08637685Fc3daFC477b92028c540CFB', // TODO: change address
    [ChainId.POLYGON]: '',
    [ChainId.ARBITRUM]: '0x7b5eb3940021ec0e8e463d5dbb4b7b09a89ddf96',
    [ChainId.OPTIMISM]: '',
    [ChainId.ZKSYNC]: '',
    [ChainId.BLAST]: '',
  },
  DAO_REWARD: {
    [CHAIN_ID.BSC]: '0x393bD012D027BB4337193e03f93261a0FD67bF04',
  },
}
