/* eslint-disable react/no-unknown-property */
import { SvgProps } from '@pancakeswap/uikit'
import React from 'react'

const ClaimIcon: React.FC<React.PropsWithChildren<SvgProps>> = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 0.1875C3.90957 0.1875 0.1875 3.90957 0.1875 8.5C0.1875 13.0904 3.90957 16.8125 8.5 16.8125C13.0904 16.8125 16.8125 13.0904 16.8125 8.5C16.8125 3.90957 13.0904 0.1875 8.5 0.1875ZM8.5 15.4023C4.68887 15.4023 1.59766 12.3111 1.59766 8.5C1.59766 4.68887 4.68887 1.59766 8.5 1.59766C12.3111 1.59766 15.4023 4.68887 15.4023 8.5C15.4023 12.3111 12.3111 15.4023 8.5 15.4023ZM9.38506 8.06953L8.91377 7.96006V5.46816C9.61885 5.56465 10.0549 6.00625 10.1291 6.54805C10.1384 6.62227 10.2015 6.67607 10.2757 6.67607H11.1088C11.196 6.67607 11.2646 6.6 11.2572 6.51279C11.144 5.35684 10.1922 4.61465 8.92119 4.48662V3.87988C8.92119 3.79824 8.85439 3.73145 8.77275 3.73145H8.25137C8.16973 3.73145 8.10293 3.79824 8.10293 3.87988V4.49219C6.78926 4.62021 5.76133 5.3457 5.76133 6.7002C5.76133 7.95449 6.68535 8.55937 7.65576 8.79131L8.11406 8.9082V11.556C7.29395 11.4465 6.83379 11.0086 6.73916 10.4186C6.72803 10.348 6.66494 10.2961 6.59258 10.2961H5.73535C5.64814 10.2961 5.57949 10.3703 5.58691 10.4575C5.67041 11.478 6.44414 12.4169 8.09551 12.5375V13.1201C8.09551 13.2018 8.1623 13.2686 8.24395 13.2686H8.7709C8.85254 13.2686 8.91934 13.2018 8.91934 13.1183L8.91563 12.5301C10.3685 12.4021 11.4075 11.6246 11.4075 10.2293C11.4057 8.9416 10.5874 8.36641 9.38506 8.06953ZM8.11221 7.76895C8.0083 7.73926 7.92109 7.71143 7.83389 7.67617C7.20674 7.4498 6.91543 7.08428 6.91543 6.61299C6.91543 5.93945 7.42568 5.55537 8.11221 5.46816V7.76895ZM8.91377 11.5615V9.08076C8.97129 9.09746 9.02324 9.11045 9.07705 9.12158C9.95469 9.38877 10.2497 9.75986 10.2497 10.3295C10.2497 11.055 9.7042 11.491 8.91377 11.5615Z"
      fill="#515151"
    />
  </svg>
)

export default ClaimIcon
