import { useEffect, useState } from "react";
import styled from "styled-components";

const FixedContainer = styled.div`
  position: fixed;
  right: 2%;
  bottom: calc(20px + env(safe-area-inset-bottom));
  cursor: pointer;

  .x-close-icon {
    cursor: pointer;
    position absolute;
    right: 20px;
    top: 20px;
    z-index: 99;
  }

  .airdrop-link {
    width: 282px;
    height: 282px;
    transition: width 1s, height 1s;
    &:hover {
      width: 320px;
      height: 320px;
    }

    @media screen and (max-width: 575px) {
      width: 220px;
      height: 220px;
      &:hover {
        width: 300px;
        height: 300px;
      }
    }
  }
`;

const AirdropLinkButton = () => {
  const [visible, setVisible] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Set the initial window width
    setWindowWidth(window.innerWidth);

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <FixedContainer style={{ display: visible ? "inline" : "none", zIndex: 99 }}>
      <img
        src={`${process.env.NEXT_PUBLIC_ASSETS_URI}/images/close-one.svg`}
        alt="close-one"
        className="x-close-icon"
        aria-hidden="true"
        onClick={() => setVisible(false)}
      />
      <video
        className="airdrop-link"
        autoPlay
        playsInline
        muted
        loop
        onClick={() => window.open("https://airdrop.xoxlabs.io/", "_blank")}
      >
        {windowWidth < 735 ? (
          <source src={`${process.env.NEXT_PUBLIC_ASSETS_URI}/videos/home/airdrop_link.mov`} type="video/mp4" />
        ) : (
          <source src={`${process.env.NEXT_PUBLIC_ASSETS_URI}/videos/home/airdrop_link.webm`} type="video/webm" />
        )}
      </video>
    </FixedContainer>
  );
};

export default AirdropLinkButton;
