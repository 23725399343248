/* eslint-disable react/no-unknown-property */
import { SvgProps } from '@pancakeswap/uikit'
import React from 'react'

const SwapIcon: React.FC<React.PropsWithChildren<SvgProps>> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <path
        d="M17.187 7.35457L14.0204 4.1879C13.9465 4.11409 13.8589 4.05553 13.7625 4.01559C13.666 3.97564 13.5627 3.95508 13.4583 3.95508C13.2475 3.95508 13.0453 4.03883 12.8962 4.1879C12.7471 4.33697 12.6634 4.53916 12.6634 4.74998C12.6634 4.96081 12.7471 5.16299 12.8962 5.31207L14.717 7.12498H5.54161C5.33165 7.12498 5.13029 7.20839 4.98182 7.35686C4.83336 7.50532 4.74995 7.70669 4.74995 7.91665C4.74995 8.12661 4.83336 8.32798 4.98182 8.47644C5.13029 8.62491 5.33165 8.70832 5.54161 8.70832H16.6249C16.7812 8.70754 16.9338 8.66051 17.0634 8.57316C17.193 8.48582 17.2939 8.36206 17.3533 8.21748C17.4139 8.07331 17.4305 7.91442 17.4009 7.76084C17.3713 7.60726 17.2969 7.46589 17.187 7.35457ZM13.4583 10.2917H2.37495C2.21865 10.2924 2.06609 10.3395 1.93647 10.4268C1.80686 10.5142 1.70601 10.6379 1.64661 10.7825C1.58599 10.9267 1.56943 11.0856 1.59901 11.2391C1.6286 11.3927 1.70301 11.5341 1.81286 11.6454L4.97953 14.8121C5.05313 14.8863 5.14069 14.9452 5.23716 14.9854C5.33363 15.0255 5.4371 15.0462 5.54161 15.0462C5.64612 15.0462 5.7496 15.0255 5.84607 14.9854C5.94254 14.9452 6.0301 14.8863 6.1037 14.8121C6.1779 14.7385 6.23679 14.6509 6.27699 14.5544C6.31718 14.458 6.33787 14.3545 6.33787 14.25C6.33787 14.1455 6.31718 14.042 6.27699 13.9455C6.23679 13.8491 6.1779 13.7615 6.1037 13.6879L4.28286 11.875H13.4583C13.6682 11.875 13.8696 11.7916 14.0181 11.6431C14.1665 11.4946 14.2499 11.2933 14.2499 11.0833C14.2499 10.8734 14.1665 10.672 14.0181 10.5235C13.8696 10.3751 13.6682 10.2917 13.4583 10.2917Z"
        fill="#515151"
      />
    </svg>
  )
}

export default SwapIcon
