// src/index.ts
import JSBI4 from "jsbi";

// src/entities/token.ts
import { Token } from "@pancakeswap/swap-sdk-core";

// src/utils.ts
import { getAddress } from "@ethersproject/address";
import JSBI from "jsbi";
import invariant from "tiny-invariant";
import warning from "tiny-warning";
import { Percent } from "@pancakeswap/swap-sdk-core";
function validateAndParseAddress(address) {
  try {
    const checksummedAddress = getAddress(address);
    warning(address === checksummedAddress, `${address} is not checksummed.`);
    return checksummedAddress;
  } catch (error) {
    invariant(false, `${address} is not a valid address.`);
  }
}
function sortedInsert(items, add, maxSize, comparator) {
  invariant(maxSize > 0, "MAX_SIZE_ZERO");
  invariant(items.length <= maxSize, "ITEMS_SIZE");
  if (items.length === 0) {
    items.push(add);
    return null;
  } else {
    const isFull = items.length === maxSize;
    if (isFull && comparator(items[items.length - 1], add) <= 0) {
      return add;
    }
    let lo = 0, hi = items.length;
    while (lo < hi) {
      const mid = lo + hi >>> 1;
      if (comparator(items[mid], add) <= 0) {
        lo = mid + 1;
      } else {
        hi = mid;
      }
    }
    items.splice(lo, 0, add);
    return isFull ? items.pop() : null;
  }
}
function computePriceImpact(midPrice, inputAmount, outputAmount) {
  const quotedOutputAmount = midPrice.quote(inputAmount);
  const priceImpact = quotedOutputAmount.subtract(outputAmount).divide(quotedOutputAmount);
  return new Percent(priceImpact.numerator, priceImpact.denominator);
}

// src/entities/token.ts
var ERC20Token = class extends Token {
  constructor(chainId, address, decimals, symbol, name, projectLink) {
    super(chainId, validateAndParseAddress(address), decimals, symbol, name, projectLink);
  }
};

// src/constants.ts
var ChainId = /* @__PURE__ */ ((ChainId2) => {
  ChainId2[ChainId2["ETHEREUM"] = 1] = "ETHEREUM";
  ChainId2[ChainId2["RINKEBY"] = 4] = "RINKEBY";
  ChainId2[ChainId2["GOERLI"] = 5] = "GOERLI";
  ChainId2[ChainId2["BSC"] = 56] = "BSC";
  ChainId2[ChainId2["BSC_TESTNET"] = 97] = "BSC_TESTNET";
  ChainId2[ChainId2["ARBITRUM"] = 42161] = "ARBITRUM";
  ChainId2[ChainId2["ARBITRUM_TESTNET"] = 421613] = "ARBITRUM_TESTNET";
  ChainId2[ChainId2["POLYGON"] = 137] = "POLYGON";
  ChainId2[ChainId2["POLYGON_TESTNET"] = 80001] = "POLYGON_TESTNET";
  ChainId2[ChainId2["ZKSYNC"] = 324] = "ZKSYNC";
  ChainId2[ChainId2["ZKSYNC_TESTNET"] = 280] = "ZKSYNC_TESTNET";
  ChainId2[ChainId2["OPTIMISM"] = 10] = "OPTIMISM";
  ChainId2[ChainId2["OPTIMISM_TESTNET"] = 420] = "OPTIMISM_TESTNET";
  ChainId2[ChainId2["NEO_EVM_TESTNET"] = 2970385] = "NEO_EVM_TESTNET";
  ChainId2[ChainId2["ZETA_TESTNET"] = 7001] = "ZETA_TESTNET";
  ChainId2[ChainId2["MANTA_TESTNET"] = 3441005] = "MANTA_TESTNET";
  ChainId2[ChainId2["BLAST"] = 81457] = "BLAST";
  ChainId2[ChainId2["BLAST_TESTNET"] = 168587773] = "BLAST_TESTNET";
  ChainId2[ChainId2["BASE"] = 8453] = "BASE";
  return ChainId2;
})(ChainId || {});
var LINEA_TESTNET = 59140;
var NEO_EVM_TESTNET = 2970385;
var FACTORY_ADDRESS = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";
var FACTORY_ADDRESS_ETH = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";
var FACTORY_ADDRESS_MAP = {
  [1 /* ETHEREUM */]: FACTORY_ADDRESS_ETH,
  [4 /* RINKEBY */]: FACTORY_ADDRESS_ETH,
  [5 /* GOERLI */]: FACTORY_ADDRESS_ETH,
  [56 /* BSC */]: FACTORY_ADDRESS,
  [97 /* BSC_TESTNET */]: "0x6725F303b657a9451d8BA641348b6761A6CC7a17",
  [2970385 /* NEO_EVM_TESTNET */]: "0xB0d08De7A9439a6d16d5b34ad8Ea6C8236Bd393b",
  [7001 /* ZETA_TESTNET */]: "0x985c7865d1C525B2937AcFF711A61A826bd01139",
  [3441005 /* MANTA_TESTNET */]: "0xdbB76E719C324c9b3BB7168FA229215926061E7e",
  [81457 /* BLAST */]: "0xb4A7D971D0ADea1c73198C97d7ab3f9CE4aaFA13",
  [168587773 /* BLAST_TESTNET */]: "0x0d0b41644210dC4BF4D10dc06279eA4790c4A96F"
};
var PAIR_XOX_BUSD = {
  [5 /* GOERLI */]: "0x4B6b6cAD103521832D78F140E89A22c70703d72c",
  [97 /* BSC_TESTNET */]: "0x6e2f695A731DA11fce2F996eD26BffDd9E35e0AF",
  [2970385 /* NEO_EVM_TESTNET */]: "0xFd8D6370eb37B62194d5CC0Dcdeb24A61878c8e7",
  [7001 /* ZETA_TESTNET */]: "0x76F4F0fcB3E39Df09D4A3617BF71BFFca528c5D5",
  [3441005 /* MANTA_TESTNET */]: "0xC1A128E71C799eDF22785e80fB33a77a87296C8f"
};
var XOX_ADDRESS = {
  [1 /* ETHEREUM */]: "0x0f5C78f152152dDA52a2ea45B0a8C10733010748",
  [5 /* GOERLI */]: "0x7C06f79a65Ff6d9520bf8604a37F20deA000A3d2",
  [97 /* BSC_TESTNET */]: "0xdd94e8a963BbD1513840aC38C5727fa5Da4a3095",
  [421613 /* ARBITRUM_TESTNET */]: "0xCc7283a00481de9AdBE379c3c2459691a6ee274a",
  [80001 /* POLYGON_TESTNET */]: "0xCc7283a00481de9AdBE379c3c2459691a6ee274a",
  [324 /* ZKSYNC */]: "0x7eCf006f7c45149B457e9116392279CC8A630F14",
  [280 /* ZKSYNC_TESTNET */]: "0xCc7283a00481de9AdBE379c3c2459691a6ee274a",
  [420 /* OPTIMISM_TESTNET */]: "0xCc7283a00481de9AdBE379c3c2459691a6ee274a",
  [2970385 /* NEO_EVM_TESTNET */]: "0x3B6dFC89760d5B6F64C043A74210ddF91264Beef",
  [7001 /* ZETA_TESTNET */]: "0x3B6dFC89760d5B6F64C043A74210ddF91264Beef",
  [3441005 /* MANTA_TESTNET */]: "0x56B09E39168b141E43c49eeF261ed9A5f105A6DF",
  [81457 /* BLAST */]: "0x0f5C78f152152dDA52a2ea45B0a8C10733010748",
  [168587773 /* BLAST_TESTNET */]: "0x90fded525b5E613c9dc0fE600d9b52a648386e2E"
};
var USD_ADDRESS = {
  [1 /* ETHEREUM */]: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  [4 /* RINKEBY */]: "",
  [5 /* GOERLI */]: "0xD898D309dAb33130EA57E8F106238ae4b76329f4",
  [80001 /* POLYGON_TESTNET */]: "0x242D918B740358829b56b0CCb7b467323D21846D",
  [56 /* BSC */]: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  [97 /* BSC_TESTNET */]: "0xc60a52351918c13eF3B27F72e5E71877ca3cB13A",
  [2970385 /* NEO_EVM_TESTNET */]: "0xce221120F145B456ba41b370F11D5E536eCD2BcB",
  [7001 /* ZETA_TESTNET */]: "0x3e3d551CAB18feca293b059B281f3b8D7C688A0A",
  [3441005 /* MANTA_TESTNET */]: "0x9b8AAb91ea36857763e9552916874ceA18F35b56",
  [81457 /* BLAST */]: "0x4300000000000000000000000000000000000003",
  [168587773 /* BLAST_TESTNET */]: "0x3B6dFC89760d5B6F64C043A74210ddF91264Beef"
};
var INIT_CODE_HASH = "0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5";
var INIT_CODE_HASH_ETH = "0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f";
var INIT_CODE_HASH_MAP = {
  [1 /* ETHEREUM */]: INIT_CODE_HASH_ETH,
  [4 /* RINKEBY */]: INIT_CODE_HASH_ETH,
  [5 /* GOERLI */]: INIT_CODE_HASH_ETH,
  [56 /* BSC */]: INIT_CODE_HASH,
  [97 /* BSC_TESTNET */]: "0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66",
  [2970385 /* NEO_EVM_TESTNET */]: "0x341a03f2b9cf74ef0c097b2765048edc7e200166b174a3934d8e04d606fc2cb0",
  [7001 /* ZETA_TESTNET */]: "0x341a03f2b9cf74ef0c097b2765048edc7e200166b174a3934d8e04d606fc2cb0",
  [3441005 /* MANTA_TESTNET */]: "0x341a03f2b9cf74ef0c097b2765048edc7e200166b174a3934d8e04d606fc2cb0",
  [81457 /* BLAST */]: "0x341a03f2b9cf74ef0c097b2765048edc7e200166b174a3934d8e04d606fc2cb0",
  [168587773 /* BLAST_TESTNET */]: "0x341a03f2b9cf74ef0c097b2765048edc7e200166b174a3934d8e04d606fc2cb0"
};
var WETH9 = {
  [1 /* ETHEREUM */]: new ERC20Token(1 /* ETHEREUM */, "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", 18, "WETH", "Wrapped Ether", "https://weth.io"),
  [4 /* RINKEBY */]: new ERC20Token(4 /* RINKEBY */, "0xc778417E063141139Fce010982780140Aa0cD5Ab", 18, "WETH", "Wrapped Ether", "https://weth.io"),
  [5 /* GOERLI */]: new ERC20Token(5 /* GOERLI */, "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6", 18, "WETH", "Wrapped Ether", "https://weth.io"),
  [2970385 /* NEO_EVM_TESTNET */]: new ERC20Token(2970385 /* NEO_EVM_TESTNET */, "0x07E56622aC709e2458dd5189e11e55A42e681fB6", 18, "GAS", "Wrapped GAS", ""),
  [7001 /* ZETA_TESTNET */]: new ERC20Token(7001 /* ZETA_TESTNET */, "0xdbB76E719C324c9b3BB7168FA229215926061E7e", 18, "ZETA", "Wrapped ZETA", ""),
  [3441005 /* MANTA_TESTNET */]: new ERC20Token(3441005 /* MANTA_TESTNET */, "0xCc7283a00481de9AdBE379c3c2459691a6ee274a", 18, "ETH", "Wrapped Ether", ""),
  [42161 /* ARBITRUM */]: new ERC20Token(42161 /* ARBITRUM */, "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1", 18, "WETH", "Wrapped Ether", "https://weth.io"),
  [10 /* OPTIMISM */]: new ERC20Token(10 /* OPTIMISM */, "0x4200000000000000000000000000000000000006", 18, "WETH", "Wrapped Ether", "https://weth.io"),
  [324 /* ZKSYNC */]: new ERC20Token(324 /* ZKSYNC */, "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91", 18, "WETH", "Wrapped Ether", "https://weth.io"),
  [81457 /* BLAST */]: new ERC20Token(81457 /* BLAST */, "0x4300000000000000000000000000000000000004", 18, "WETH", "Wrapped Ether"),
  [168587773 /* BLAST_TESTNET */]: new ERC20Token(168587773 /* BLAST_TESTNET */, "0x9d2fDbF84fd2f2D2D9F4383960A9565f6f5F78C1", 18, "WETH", "Wrapped Ether"),
  [8453 /* BASE */]: new ERC20Token(8453 /* BASE */, "0x4200000000000000000000000000000000000006", 18, "WETH", "Wrapped Ether")
};
var WBNB = {
  [1 /* ETHEREUM */]: new ERC20Token(1 /* ETHEREUM */, "0x418D75f65a02b3D53B2418FB8E1fe493759c7605", 18, "WBNB", "Wrapped BNB", "https://www.binance.org"),
  [56 /* BSC */]: new ERC20Token(56 /* BSC */, "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", 18, "WBNB", "Wrapped BNB", "https://www.binance.org"),
  [97 /* BSC_TESTNET */]: new ERC20Token(97 /* BSC_TESTNET */, "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd", 18, "WBNB", "Wrapped BNB", "https://www.binance.org"),
  [2970385 /* NEO_EVM_TESTNET */]: new ERC20Token(2970385 /* NEO_EVM_TESTNET */, "0x07E56622aC709e2458dd5189e11e55A42e681fB6", 18, "WETH", "Wrapped ETHEREUM", "https://www.binance.org"),
  [7001 /* ZETA_TESTNET */]: new ERC20Token(7001 /* ZETA_TESTNET */, "0xdbB76E719C324c9b3BB7168FA229215926061E7e", 18, "WETH", "Wrapped ETHEREUM", "https://www.binance.org"),
  [3441005 /* MANTA_TESTNET */]: new ERC20Token(3441005 /* MANTA_TESTNET */, "0xCc7283a00481de9AdBE379c3c2459691a6ee274a", 18, "WETH", "Wrapped ETHEREUM", "https://www.binance.org"),
  [81457 /* BLAST */]: new ERC20Token(81457 /* BLAST */, "0x4300000000000000000000000000000000000004", 18, "WETH", "Wrapped Ether"),
  [168587773 /* BLAST_TESTNET */]: new ERC20Token(168587773 /* BLAST_TESTNET */, "0x9d2fDbF84fd2f2D2D9F4383960A9565f6f5F78C1", 18, "WETH", "Wrapped Ether")
};
var WMATIC = {
  [137 /* POLYGON */]: new ERC20Token(137 /* POLYGON */, "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270", 18, "WMATIC", "Wrapped Matic", "https://polygon.technology")
};
var WNATIVE = {
  [1 /* ETHEREUM */]: WETH9[1 /* ETHEREUM */],
  [4 /* RINKEBY */]: WETH9[4 /* RINKEBY */],
  [5 /* GOERLI */]: WETH9[5 /* GOERLI */],
  [56 /* BSC */]: WBNB[56 /* BSC */],
  [97 /* BSC_TESTNET */]: WBNB[97 /* BSC_TESTNET */],
  [42161 /* ARBITRUM */]: WETH9[42161 /* ARBITRUM */],
  [137 /* POLYGON */]: WMATIC[137 /* POLYGON */],
  [10 /* OPTIMISM */]: WETH9[10 /* OPTIMISM */],
  [324 /* ZKSYNC */]: WETH9[324 /* ZKSYNC */],
  [2970385 /* NEO_EVM_TESTNET */]: WETH9[2970385 /* NEO_EVM_TESTNET */],
  [7001 /* ZETA_TESTNET */]: WETH9[7001 /* ZETA_TESTNET */],
  [3441005 /* MANTA_TESTNET */]: WETH9[3441005 /* MANTA_TESTNET */],
  [81457 /* BLAST */]: WETH9[81457 /* BLAST */],
  [168587773 /* BLAST_TESTNET */]: WETH9[168587773 /* BLAST_TESTNET */],
  [8453 /* BASE */]: WETH9[8453 /* BASE */]
};
var NATIVE = {
  [1 /* ETHEREUM */]: { name: "Ether", symbol: "ETH", decimals: 18 },
  [4 /* RINKEBY */]: { name: "Rinkeby Ether", symbol: "RIN", decimals: 18 },
  [5 /* GOERLI */]: { name: "Goerli Ether", symbol: "ETH", decimals: 18 },
  [56 /* BSC */]: {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
  },
  [97 /* BSC_TESTNET */]: {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
  },
  [42161 /* ARBITRUM */]: { name: "Ether", symbol: "ETH", decimals: 18 },
  [421613 /* ARBITRUM_TESTNET */]: { name: "AGOR", symbol: "AGOR", decimals: 18 },
  [137 /* POLYGON */]: { name: "MATIC", symbol: "MATIC", decimals: 18 },
  [80001 /* POLYGON_TESTNET */]: { name: "MATIC", symbol: "MATIC", decimals: 18 },
  [324 /* ZKSYNC */]: { name: "Ether", symbol: "ETH", decimals: 18 },
  [280 /* ZKSYNC_TESTNET */]: { name: "Ether", symbol: "ETH", decimals: 18 },
  [10 /* OPTIMISM */]: { name: "Ether", symbol: "ETH", decimals: 18 },
  [420 /* OPTIMISM_TESTNET */]: { name: "Ether", symbol: "ETH", decimals: 18 },
  [LINEA_TESTNET]: { name: "Ether", symbol: "ETH", decimals: 18 },
  [2970385 /* NEO_EVM_TESTNET */]: { name: "GAS", symbol: "GAS", decimals: 18 },
  [7001 /* ZETA_TESTNET */]: { name: "ZETA", symbol: "ZETA", decimals: 18 },
  [3441005 /* MANTA_TESTNET */]: { name: "Ether", symbol: "ETH", decimals: 18 },
  [81457 /* BLAST */]: { name: "Ether", symbol: "ETH", decimals: 18 },
  [168587773 /* BLAST_TESTNET */]: { name: "Ether", symbol: "ETH", decimals: 18 },
  [8453 /* BASE */]: { name: "Ether", symbol: "ETH", decimals: 18 }
};

// src/entities/pair.ts
import {
  InsufficientInputAmountError,
  InsufficientReservesError,
  sqrt,
  CurrencyAmount as CurrencyAmount2,
  Price as Price2,
  FIVE,
  ONE,
  ZERO,
  _100,
  _10000,
  _9975,
  MINIMUM_LIQUIDITY
} from "@pancakeswap/swap-sdk-core";
import { getCreate2Address } from "@ethersproject/address";
import { keccak256, pack } from "@ethersproject/solidity";
import JSBI2 from "jsbi";
import invariant2 from "tiny-invariant";
var PAIR_ADDRESS_CACHE = {};
var _90 = JSBI2.BigInt(90);
var _9970 = JSBI2.BigInt(9970);
var LP_FEE = {
  [1 /* ETHEREUM */]: _9970,
  [5 /* GOERLI */]: _9970,
  [56 /* BSC */]: _9975,
  [97 /* BSC_TESTNET */]: _9975,
  [2970385 /* NEO_EVM_TESTNET */]: _9970,
  [7001 /* ZETA_TESTNET */]: _9970,
  [3441005 /* MANTA_TESTNET */]: _9970,
  [81457 /* BLAST */]: _9970,
  [168587773 /* BLAST_TESTNET */]: _9970
};
var composeKey = (token0, token1) => `${token0.chainId}-${token0.address}-${token1.address}`;
var computePairAddress = ({
  factoryAddress,
  tokenA,
  tokenB
}) => {
  const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
  const key = composeKey(token0, token1);
  if ((PAIR_ADDRESS_CACHE == null ? void 0 : PAIR_ADDRESS_CACHE[key]) === void 0) {
    PAIR_ADDRESS_CACHE = {
      ...PAIR_ADDRESS_CACHE,
      [key]: getCreate2Address(factoryAddress, keccak256(["bytes"], [pack(["address", "address"], [token0.address, token1.address])]), INIT_CODE_HASH_MAP[token0.chainId])
    };
  }
  return PAIR_ADDRESS_CACHE[key];
};
var Pair = class {
  static getAddress(tokenA, tokenB) {
    return computePairAddress({ factoryAddress: FACTORY_ADDRESS_MAP[tokenA.chainId], tokenA, tokenB });
  }
  constructor(currencyAmountA, tokenAmountB) {
    const tokenAmounts = currencyAmountA.currency.sortsBefore(tokenAmountB.currency) ? [currencyAmountA, tokenAmountB] : [tokenAmountB, currencyAmountA];
    if (tokenAmounts[0].currency.address == XOX_ADDRESS[tokenAmounts[0].currency.chainId] && tokenAmounts[1].currency.address == USD_ADDRESS[tokenAmounts[1].currency.chainId]) {
      this.liquidityToken = new ERC20Token(tokenAmounts[0].currency.chainId, PAIR_XOX_BUSD[tokenAmounts[0].currency.chainId], 18, "XOX-LP", "XOX LPs");
    } else if (tokenAmounts[0].currency.address == USD_ADDRESS[tokenAmounts[0].currency.chainId] && tokenAmounts[1].currency.address == XOX_ADDRESS[tokenAmounts[1].currency.chainId]) {
      this.liquidityToken = new ERC20Token(tokenAmounts[0].currency.chainId, PAIR_XOX_BUSD[tokenAmounts[0].currency.chainId], 18, "XOX-LP", "XOX LPs");
    } else {
      this.liquidityToken = new ERC20Token(tokenAmounts[0].currency.chainId, Pair.getAddress(tokenAmounts[0].currency, tokenAmounts[1].currency), 18, "XOX-LP", "XOX LPs");
    }
    this.tokenAmounts = tokenAmounts;
  }
  involvesToken(token) {
    return token.equals(this.token0) || token.equals(this.token1);
  }
  get token0Price() {
    const result = this.tokenAmounts[1].divide(this.tokenAmounts[0]);
    return new Price2(this.token0, this.token1, result.denominator, result.numerator);
  }
  get token1Price() {
    const result = this.tokenAmounts[0].divide(this.tokenAmounts[1]);
    return new Price2(this.token1, this.token0, result.denominator, result.numerator);
  }
  priceOf(token) {
    invariant2(this.involvesToken(token), "TOKEN");
    return token.equals(this.token0) ? this.token0Price : this.token1Price;
  }
  get chainId() {
    return this.token0.chainId;
  }
  get token0() {
    return this.tokenAmounts[0].currency;
  }
  get token1() {
    return this.tokenAmounts[1].currency;
  }
  get reserve0() {
    return this.tokenAmounts[0];
  }
  get reserve1() {
    return this.tokenAmounts[1];
  }
  reserveOf(token) {
    invariant2(this.involvesToken(token), "TOKEN");
    return token.equals(this.token0) ? this.reserve0 : this.reserve1;
  }
  getOutputAmount(inputAmount) {
    invariant2(this.involvesToken(inputAmount.currency), "TOKEN");
    if (JSBI2.equal(this.reserve0.quotient, ZERO) || JSBI2.equal(this.reserve1.quotient, ZERO)) {
      throw new InsufficientReservesError();
    }
    const inputReserve = this.reserveOf(inputAmount.currency);
    const outputReserve = this.reserveOf(inputAmount.currency.equals(this.token0) ? this.token1 : this.token0);
    const inputAmountWithFee = JSBI2.multiply(inputAmount.quotient, LP_FEE[inputAmount.currency.chainId]);
    const numerator = JSBI2.multiply(inputAmountWithFee, outputReserve.quotient);
    const denominator = JSBI2.add(JSBI2.multiply(inputReserve.quotient, _10000), inputAmountWithFee);
    const outputAmount = CurrencyAmount2.fromRawAmount(inputAmount.currency.equals(this.token0) ? this.token1 : this.token0, JSBI2.divide(numerator, denominator));
    if (JSBI2.equal(outputAmount.quotient, ZERO)) {
      throw new InsufficientInputAmountError();
    }
    return [outputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))];
  }
  getOutputAmountXOX(inputAmount) {
    invariant2(this.involvesToken(inputAmount.currency), "TOKEN");
    if (JSBI2.equal(this.reserve0.quotient, ZERO) || JSBI2.equal(this.reserve1.quotient, ZERO)) {
      throw new InsufficientReservesError();
    }
    const inputReserve = this.reserveOf(inputAmount.currency);
    const outputReserve = this.reserveOf(inputAmount.currency.equals(this.token0) ? this.token1 : this.token0);
    if (inputAmount.currency.equals(this.token0)) {
      const inputAmountWithFee = JSBI2.multiply(inputAmount.quotient, LP_FEE[inputAmount.currency.chainId]);
      const numerator = JSBI2.multiply(inputAmountWithFee, outputReserve.quotient);
      const denominator = JSBI2.add(JSBI2.multiply(inputReserve.quotient, _10000), inputAmountWithFee);
      const outputAmount = CurrencyAmount2.fromRawAmount(inputAmount.currency.equals(this.token0) ? this.token1 : this.token0, JSBI2.divide(JSBI2.multiply(JSBI2.divide(numerator, denominator), _90), _100));
      if (JSBI2.equal(outputAmount.quotient, ZERO)) {
        throw new InsufficientInputAmountError();
      }
      return [outputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))];
    } else {
      const inputAmountSwap = JSBI2.divide(JSBI2.multiply(inputAmount.quotient, _90), _100);
      const inputAmountWithFee = JSBI2.multiply(inputAmountSwap, LP_FEE[inputAmount.currency.chainId]);
      const numerator = JSBI2.multiply(inputAmountWithFee, outputReserve.quotient);
      const denominator = JSBI2.add(JSBI2.multiply(inputReserve.quotient, _10000), inputAmountWithFee);
      const outputAmount = CurrencyAmount2.fromRawAmount(inputAmount.currency.equals(this.token0) ? this.token1 : this.token0, JSBI2.divide(numerator, denominator));
      if (JSBI2.equal(outputAmount.quotient, ZERO)) {
        throw new InsufficientInputAmountError();
      }
      return [outputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))];
    }
  }
  getInputAmount(outputAmount) {
    invariant2(this.involvesToken(outputAmount.currency), "TOKEN");
    if (JSBI2.equal(this.reserve0.quotient, ZERO) || JSBI2.equal(this.reserve1.quotient, ZERO) || JSBI2.greaterThanOrEqual(outputAmount.quotient, this.reserveOf(outputAmount.currency).quotient)) {
      throw new InsufficientReservesError();
    }
    const outputReserve = this.reserveOf(outputAmount.currency);
    const inputReserve = this.reserveOf(outputAmount.currency.equals(this.token0) ? this.token1 : this.token0);
    const numerator = JSBI2.multiply(JSBI2.multiply(inputReserve.quotient, outputAmount.quotient), _10000);
    const denominator = JSBI2.multiply(JSBI2.subtract(outputReserve.quotient, outputAmount.quotient), LP_FEE[outputAmount.currency.chainId]);
    const inputAmount = CurrencyAmount2.fromRawAmount(outputAmount.currency.equals(this.token0) ? this.token1 : this.token0, JSBI2.add(JSBI2.divide(numerator, denominator), ONE));
    return [inputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))];
  }
  getInputAmountXOX(outputAmount) {
    invariant2(this.involvesToken(outputAmount.currency), "TOKEN");
    if (JSBI2.equal(this.reserve0.quotient, ZERO) || JSBI2.equal(this.reserve1.quotient, ZERO) || JSBI2.greaterThanOrEqual(outputAmount.quotient, this.reserveOf(outputAmount.currency).quotient)) {
      throw new InsufficientReservesError();
    }
    const outputReserve = this.reserveOf(outputAmount.currency);
    const inputReserve = this.reserveOf(outputAmount.currency.equals(this.token0) ? this.token1 : this.token0);
    if (outputAmount.currency.equals(this.token1)) {
      const outputAmountSwap = JSBI2.divide(JSBI2.multiply(outputAmount.quotient, _100), _90);
      const numerator = JSBI2.multiply(JSBI2.multiply(inputReserve.quotient, outputAmountSwap), _10000);
      const denominator = JSBI2.multiply(JSBI2.subtract(outputReserve.quotient, outputAmountSwap), LP_FEE[outputAmount.currency.chainId]);
      const inputAmount = CurrencyAmount2.fromRawAmount(outputAmount.currency.equals(this.token0) ? this.token1 : this.token0, JSBI2.add(JSBI2.divide(numerator, denominator), ONE));
      return [inputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))];
    } else {
      const numerator = JSBI2.multiply(JSBI2.multiply(inputReserve.quotient, outputAmount.quotient), _10000);
      const denominator = JSBI2.multiply(JSBI2.subtract(outputReserve.quotient, outputAmount.quotient), LP_FEE[outputAmount.currency.chainId]);
      const inputAmountSwap = JSBI2.add(JSBI2.divide(numerator, denominator), ONE);
      const inputAmount = CurrencyAmount2.fromRawAmount(outputAmount.currency.equals(this.token0) ? this.token1 : this.token0, JSBI2.divide(JSBI2.multiply(inputAmountSwap, _100), _90));
      return [inputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))];
    }
  }
  getLiquidityMinted(totalSupply, tokenAmountA, tokenAmountB) {
    invariant2(totalSupply.currency.equals(this.liquidityToken), "LIQUIDITY");
    const tokenAmounts = tokenAmountA.currency.sortsBefore(tokenAmountB.currency) ? [tokenAmountA, tokenAmountB] : [tokenAmountB, tokenAmountA];
    invariant2(tokenAmounts[0].currency.equals(this.token0) && tokenAmounts[1].currency.equals(this.token1), "TOKEN");
    let liquidity;
    if (JSBI2.equal(totalSupply.quotient, ZERO)) {
      liquidity = JSBI2.subtract(sqrt(JSBI2.multiply(tokenAmounts[0].quotient, tokenAmounts[1].quotient)), MINIMUM_LIQUIDITY);
    } else {
      const amount0 = JSBI2.divide(JSBI2.multiply(tokenAmounts[0].quotient, totalSupply.quotient), this.reserve0.quotient);
      const amount1 = JSBI2.divide(JSBI2.multiply(tokenAmounts[1].quotient, totalSupply.quotient), this.reserve1.quotient);
      liquidity = JSBI2.lessThanOrEqual(amount0, amount1) ? amount0 : amount1;
    }
    if (!JSBI2.greaterThan(liquidity, ZERO)) {
      throw new InsufficientInputAmountError();
    }
    return CurrencyAmount2.fromRawAmount(this.liquidityToken, liquidity);
  }
  getLiquidityValue(token, totalSupply, liquidity, feeOn = false, kLast) {
    invariant2(this.involvesToken(token), "TOKEN");
    invariant2(totalSupply.currency.equals(this.liquidityToken), "TOTAL_SUPPLY");
    invariant2(liquidity.currency.equals(this.liquidityToken), "LIQUIDITY");
    invariant2(JSBI2.lessThanOrEqual(liquidity.quotient, totalSupply.quotient), "LIQUIDITY");
    let totalSupplyAdjusted;
    if (!feeOn) {
      totalSupplyAdjusted = totalSupply;
    } else {
      invariant2(!!kLast, "K_LAST");
      const kLastParsed = JSBI2.BigInt(kLast);
      if (!JSBI2.equal(kLastParsed, ZERO)) {
        const rootK = sqrt(JSBI2.multiply(this.reserve0.quotient, this.reserve1.quotient));
        const rootKLast = sqrt(kLastParsed);
        if (JSBI2.greaterThan(rootK, rootKLast)) {
          const numerator = JSBI2.multiply(totalSupply.quotient, JSBI2.subtract(rootK, rootKLast));
          const denominator = JSBI2.add(JSBI2.multiply(rootK, FIVE), rootKLast);
          const feeLiquidity = JSBI2.divide(numerator, denominator);
          totalSupplyAdjusted = totalSupply.add(CurrencyAmount2.fromRawAmount(this.liquidityToken, feeLiquidity));
        } else {
          totalSupplyAdjusted = totalSupply;
        }
      } else {
        totalSupplyAdjusted = totalSupply;
      }
    }
    return CurrencyAmount2.fromRawAmount(token, JSBI2.divide(JSBI2.multiply(liquidity.quotient, this.reserveOf(token).quotient), totalSupplyAdjusted.quotient));
  }
};

// src/entities/route.ts
import invariant3 from "tiny-invariant";
import { Price as Price3 } from "@pancakeswap/swap-sdk-core";
var Route = class {
  constructor(pairs, input, output) {
    this._midPrice = null;
    invariant3(pairs.length > 0, "PAIRS");
    const chainId = pairs[0].chainId;
    invariant3(pairs.every((pair) => pair.chainId === chainId), "CHAIN_IDS");
    const wrappedInput = input.wrapped;
    invariant3(pairs[0].involvesToken(wrappedInput), "INPUT");
    invariant3(typeof output === "undefined" || pairs[pairs.length - 1].involvesToken(output.wrapped), "OUTPUT");
    const path = [wrappedInput];
    for (const [i, pair] of pairs.entries()) {
      const currentInput = path[i];
      invariant3(currentInput.equals(pair.token0) || currentInput.equals(pair.token1), "PATH");
      const output2 = currentInput.equals(pair.token0) ? pair.token1 : pair.token0;
      path.push(output2);
    }
    this.pairs = pairs;
    this.path = path;
    this.input = input;
    this.output = output;
  }
  get midPrice() {
    if (this._midPrice !== null)
      return this._midPrice;
    const prices = [];
    for (const [i, pair] of this.pairs.entries()) {
      prices.push(this.path[i].equals(pair.token0) ? new Price3(pair.reserve0.currency, pair.reserve1.currency, pair.reserve0.quotient, pair.reserve1.quotient) : new Price3(pair.reserve1.currency, pair.reserve0.currency, pair.reserve1.quotient, pair.reserve0.quotient));
    }
    const reduced = prices.slice(1).reduce((accumulator, currentValue) => accumulator.multiply(currentValue), prices[0]);
    return this._midPrice = new Price3(this.input, this.output, reduced.denominator, reduced.numerator);
  }
  get chainId() {
    return this.pairs[0].chainId;
  }
};

// src/entities/trade.ts
import invariant4 from "tiny-invariant";
import {
  ONE as ONE2,
  TradeType,
  ZERO as ZERO2,
  CurrencyAmount as CurrencyAmount3,
  Fraction,
  Price as Price4
} from "@pancakeswap/swap-sdk-core";
function inputOutputComparator(a, b) {
  invariant4(a.inputAmount.currency.equals(b.inputAmount.currency), "INPUT_CURRENCY");
  invariant4(a.outputAmount.currency.equals(b.outputAmount.currency), "OUTPUT_CURRENCY");
  if (a.outputAmount.equalTo(b.outputAmount)) {
    if (a.inputAmount.equalTo(b.inputAmount)) {
      return 0;
    }
    if (a.inputAmount.lessThan(b.inputAmount)) {
      return -1;
    } else {
      return 1;
    }
  } else {
    if (a.outputAmount.lessThan(b.outputAmount)) {
      return 1;
    } else {
      return -1;
    }
  }
}
function tradeComparator(a, b) {
  const ioComp = inputOutputComparator(a, b);
  if (ioComp !== 0) {
    return ioComp;
  }
  if (a.priceImpact.lessThan(b.priceImpact)) {
    return -1;
  } else if (a.priceImpact.greaterThan(b.priceImpact)) {
    return 1;
  }
  return a.route.path.length - b.route.path.length;
}
var Trade = class {
  static exactIn(route, amountIn) {
    return new Trade(route, amountIn, TradeType.EXACT_INPUT);
  }
  static exactOut(route, amountOut) {
    return new Trade(route, amountOut, TradeType.EXACT_OUTPUT);
  }
  constructor(route, amount, tradeType) {
    this.route = route;
    this.tradeType = tradeType;
    const tokenAmounts = new Array(route.path.length);
    if (tradeType === TradeType.EXACT_INPUT) {
      invariant4(amount.currency.equals(route.input), "INPUT");
      tokenAmounts[0] = amount.wrapped;
      for (let i = 0; i < route.path.length - 1; i++) {
        const pair = route.pairs[i];
        const [outputAmount] = pair.getOutputAmount(tokenAmounts[i]);
        tokenAmounts[i + 1] = outputAmount;
      }
      this.inputAmount = CurrencyAmount3.fromFractionalAmount(route.input, amount.numerator, amount.denominator);
      this.outputAmount = CurrencyAmount3.fromFractionalAmount(route.output, tokenAmounts[tokenAmounts.length - 1].numerator, tokenAmounts[tokenAmounts.length - 1].denominator);
    } else {
      invariant4(amount.currency.equals(route.output), "OUTPUT");
      tokenAmounts[tokenAmounts.length - 1] = amount.wrapped;
      for (let i = route.path.length - 1; i > 0; i--) {
        const pair = route.pairs[i - 1];
        const [inputAmount] = pair.getInputAmount(tokenAmounts[i]);
        tokenAmounts[i - 1] = inputAmount;
      }
      this.inputAmount = CurrencyAmount3.fromFractionalAmount(route.input, tokenAmounts[0].numerator, tokenAmounts[0].denominator);
      this.outputAmount = CurrencyAmount3.fromFractionalAmount(route.output, amount.numerator, amount.denominator);
    }
    this.executionPrice = new Price4(this.inputAmount.currency, this.outputAmount.currency, this.inputAmount.quotient, this.outputAmount.quotient);
    this.priceImpact = computePriceImpact(route.midPrice, this.inputAmount, this.outputAmount);
  }
  minimumAmountOut(slippageTolerance) {
    invariant4(!slippageTolerance.lessThan(ZERO2), "SLIPPAGE_TOLERANCE");
    if (this.tradeType === TradeType.EXACT_OUTPUT) {
      return this.outputAmount;
    } else {
      const slippageAdjustedAmountOut = new Fraction(ONE2).add(slippageTolerance).invert().multiply(this.outputAmount.quotient).quotient;
      return CurrencyAmount3.fromRawAmount(this.outputAmount.currency, slippageAdjustedAmountOut);
    }
  }
  maximumAmountIn(slippageTolerance) {
    invariant4(!slippageTolerance.lessThan(ZERO2), "SLIPPAGE_TOLERANCE");
    if (this.tradeType === TradeType.EXACT_INPUT) {
      return this.inputAmount;
    } else {
      const slippageAdjustedAmountIn = new Fraction(ONE2).add(slippageTolerance).multiply(this.inputAmount.quotient).quotient;
      return CurrencyAmount3.fromRawAmount(this.inputAmount.currency, slippageAdjustedAmountIn);
    }
  }
  static bestTradeExactIn(pairs, currencyAmountIn, currencyOut, { maxNumResults = 3, maxHops = 3 } = {}, currentPairs = [], nextAmountIn = currencyAmountIn, bestTrades = []) {
    invariant4(pairs.length > 0, "PAIRS");
    invariant4(maxHops > 0, "MAX_HOPS");
    invariant4(currencyAmountIn === nextAmountIn || currentPairs.length > 0, "INVALID_RECURSION");
    const amountIn = nextAmountIn.wrapped;
    const tokenOut = currencyOut.wrapped;
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];
      if (!pair.token0.equals(amountIn.currency) && !pair.token1.equals(amountIn.currency))
        continue;
      if (pair.reserve0.equalTo(ZERO2) || pair.reserve1.equalTo(ZERO2))
        continue;
      let amountOut;
      try {
        ;
        [amountOut] = pair.getOutputAmount(amountIn);
      } catch (error) {
        if (error.isInsufficientInputAmountError) {
          continue;
        }
        throw error;
      }
      if (amountOut.currency.equals(tokenOut)) {
        sortedInsert(bestTrades, new Trade(new Route([...currentPairs, pair], currencyAmountIn.currency, currencyOut), currencyAmountIn, TradeType.EXACT_INPUT), maxNumResults, tradeComparator);
      } else if (maxHops > 1 && pairs.length > 1) {
        const pairsExcludingThisPair = pairs.slice(0, i).concat(pairs.slice(i + 1, pairs.length));
        Trade.bestTradeExactIn(pairsExcludingThisPair, currencyAmountIn, currencyOut, {
          maxNumResults,
          maxHops: maxHops - 1
        }, [...currentPairs, pair], amountOut, bestTrades);
      }
    }
    return bestTrades;
  }
  worstExecutionPrice(slippageTolerance) {
    return new Price4(this.inputAmount.currency, this.outputAmount.currency, this.maximumAmountIn(slippageTolerance).quotient, this.minimumAmountOut(slippageTolerance).quotient);
  }
  static bestTradeExactOut(pairs, currencyIn, currencyAmountOut, { maxNumResults = 3, maxHops = 3 } = {}, currentPairs = [], nextAmountOut = currencyAmountOut, bestTrades = []) {
    invariant4(pairs.length > 0, "PAIRS");
    invariant4(maxHops > 0, "MAX_HOPS");
    invariant4(currencyAmountOut === nextAmountOut || currentPairs.length > 0, "INVALID_RECURSION");
    const amountOut = nextAmountOut.wrapped;
    const tokenIn = currencyIn.wrapped;
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];
      if (!pair.token0.equals(amountOut.currency) && !pair.token1.equals(amountOut.currency))
        continue;
      if (pair.reserve0.equalTo(ZERO2) || pair.reserve1.equalTo(ZERO2))
        continue;
      let amountIn;
      try {
        ;
        [amountIn] = pair.getInputAmount(amountOut);
      } catch (error) {
        if (error.isInsufficientReservesError) {
          continue;
        }
        throw error;
      }
      if (amountIn.currency.equals(tokenIn)) {
        sortedInsert(bestTrades, new Trade(new Route([pair, ...currentPairs], currencyIn, currencyAmountOut.currency), currencyAmountOut, TradeType.EXACT_OUTPUT), maxNumResults, tradeComparator);
      } else if (maxHops > 1 && pairs.length > 1) {
        const pairsExcludingThisPair = pairs.slice(0, i).concat(pairs.slice(i + 1, pairs.length));
        Trade.bestTradeExactOut(pairsExcludingThisPair, currencyIn, currencyAmountOut, {
          maxNumResults,
          maxHops: maxHops - 1
        }, [pair, ...currentPairs], amountIn, bestTrades);
      }
    }
    return bestTrades;
  }
};

// src/entities/native.ts
import invariant5 from "tiny-invariant";
import { NativeCurrency } from "@pancakeswap/swap-sdk-core";
var _Native = class extends NativeCurrency {
  constructor({
    chainId,
    decimals,
    name,
    symbol
  }) {
    super(chainId, decimals, symbol, name);
  }
  get wrapped() {
    const wnative = WNATIVE[this.chainId];
    invariant5(!!wnative, "WRAPPED");
    return wnative;
  }
  static onChain(chainId) {
    if (chainId in this.cache) {
      return this.cache[chainId];
    }
    invariant5(!!NATIVE[chainId], "NATIVE_CURRENCY");
    const { decimals, name, symbol } = NATIVE[chainId];
    return this.cache[chainId] = new _Native({ chainId, decimals, symbol, name });
  }
  equals(other) {
    return other.isNative && other.chainId === this.chainId;
  }
};
var Native = _Native;
Native.cache = {};

// src/entities/tradeXOX.ts
import invariant6 from "tiny-invariant";
import {
  ONE as ONE3,
  TradeType as TradeType2,
  ZERO as ZERO3,
  CurrencyAmount as CurrencyAmount4,
  Fraction as Fraction2,
  Price as Price5,
  _100 as _1002
} from "@pancakeswap/swap-sdk-core";
import JSBI3 from "jsbi";
function inputOutputComparatorXOX(a, b) {
  invariant6(a.inputAmount.currency.equals(b.inputAmount.currency), "INPUT_CURRENCY");
  invariant6(a.outputAmount.currency.equals(b.outputAmount.currency), "OUTPUT_CURRENCY");
  if (a.outputAmount.equalTo(b.outputAmount)) {
    if (a.inputAmount.equalTo(b.inputAmount)) {
      return 0;
    }
    if (a.inputAmount.lessThan(b.inputAmount)) {
      return -1;
    } else {
      return 1;
    }
  } else {
    if (a.outputAmount.lessThan(b.outputAmount)) {
      return 1;
    } else {
      return -1;
    }
  }
}
function tradeComparatorXOX(a, b) {
  const ioComp = inputOutputComparatorXOX(a, b);
  if (ioComp !== 0) {
    return ioComp;
  }
  if (a.priceImpact.lessThan(b.priceImpact)) {
    return -1;
  } else if (a.priceImpact.greaterThan(b.priceImpact)) {
    return 1;
  }
  return a.route.path.length - b.route.path.length;
}
var TradeXOX = class {
  static exactIn(route, amountIn) {
    return new TradeXOX(route, amountIn, TradeType2.EXACT_INPUT);
  }
  static exactOut(route, amountOut) {
    return new TradeXOX(route, amountOut, TradeType2.EXACT_OUTPUT);
  }
  constructor(route, amount, tradeType) {
    this.route = route;
    this.tradeType = tradeType;
    const tokenAmounts = new Array(route.path.length);
    if (tradeType === TradeType2.EXACT_INPUT) {
      invariant6(amount.currency.equals(route.input), "INPUT");
      tokenAmounts[0] = amount.wrapped;
      for (let i = 0; i < route.path.length - 1; i++) {
        const pair = route.pairs[i];
        const [outputAmount] = pair.getOutputAmountXOX(tokenAmounts[i]);
        tokenAmounts[i + 1] = outputAmount;
      }
      const inputAmountXOX = CurrencyAmount4.fromFractionalAmount(route.input, JSBI3.divide(JSBI3.multiply(amount.numerator, _90), _1002), amount.denominator);
      this.inputAmount = CurrencyAmount4.fromFractionalAmount(route.input, amount.numerator, amount.denominator);
      this.outputAmount = CurrencyAmount4.fromFractionalAmount(route.output, tokenAmounts[tokenAmounts.length - 1].numerator, tokenAmounts[tokenAmounts.length - 1].denominator);
      this.priceImpact = computePriceImpact(route.midPrice, inputAmountXOX, this.outputAmount);
    } else {
      invariant6(amount.currency.equals(route.output), "OUTPUT");
      tokenAmounts[tokenAmounts.length - 1] = amount.wrapped;
      for (let i = route.path.length - 1; i > 0; i--) {
        const pair = route.pairs[i - 1];
        const [inputAmount] = pair.getInputAmountXOX(tokenAmounts[i]);
        tokenAmounts[i - 1] = inputAmount;
      }
      this.inputAmount = CurrencyAmount4.fromFractionalAmount(route.input, tokenAmounts[0].numerator, tokenAmounts[0].denominator);
      this.outputAmount = CurrencyAmount4.fromFractionalAmount(route.output, amount.numerator, amount.denominator);
      const outputAmountXOX = CurrencyAmount4.fromFractionalAmount(route.output, JSBI3.divide(JSBI3.multiply(amount.numerator, _1002), _90), amount.denominator);
      this.priceImpact = computePriceImpact(route.midPrice, this.inputAmount, outputAmountXOX);
    }
    this.executionPrice = new Price5(this.inputAmount.currency, this.outputAmount.currency, this.inputAmount.quotient, this.outputAmount.quotient);
  }
  minimumAmountOut(slippageTolerance) {
    invariant6(!slippageTolerance.lessThan(ZERO3), "SLIPPAGE_TOLERANCE");
    if (this.tradeType === TradeType2.EXACT_OUTPUT) {
      return this.outputAmount;
    } else {
      const slippageAdjustedAmountOut = new Fraction2(ONE3).add(slippageTolerance).invert().multiply(this.outputAmount.quotient).quotient;
      return CurrencyAmount4.fromRawAmount(this.outputAmount.currency, slippageAdjustedAmountOut);
    }
  }
  maximumAmountIn(slippageTolerance) {
    invariant6(!slippageTolerance.lessThan(ZERO3), "SLIPPAGE_TOLERANCE");
    if (this.tradeType === TradeType2.EXACT_INPUT) {
      return this.inputAmount;
    } else {
      const slippageAdjustedAmountIn = new Fraction2(ONE3).add(slippageTolerance).multiply(this.inputAmount.quotient).quotient;
      return CurrencyAmount4.fromRawAmount(this.inputAmount.currency, slippageAdjustedAmountIn);
    }
  }
  static bestTradeExactIn(pairs, currencyAmountIn, currencyOut, { maxNumResults = 3, maxHops = 3 } = {}, currentPairs = [], nextAmountIn = currencyAmountIn, bestTrades = []) {
    invariant6(pairs.length > 0, "PAIRS");
    invariant6(maxHops > 0, "MAX_HOPS");
    invariant6(currencyAmountIn === nextAmountIn || currentPairs.length > 0, "INVALID_RECURSION");
    const amountIn = nextAmountIn.wrapped;
    const tokenOut = currencyOut.wrapped;
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];
      if (!pair.token0.equals(amountIn.currency) && !pair.token1.equals(amountIn.currency))
        continue;
      if (pair.reserve0.equalTo(ZERO3) || pair.reserve1.equalTo(ZERO3))
        continue;
      let amountOut;
      try {
        ;
        [amountOut] = pair.getOutputAmountXOX(amountIn);
      } catch (error) {
        if (error.isInsufficientInputAmountError) {
          continue;
        }
        throw error;
      }
      if (amountOut.currency.equals(tokenOut)) {
        sortedInsert(bestTrades, new TradeXOX(new Route([...currentPairs, pair], currencyAmountIn.currency, currencyOut), currencyAmountIn, TradeType2.EXACT_INPUT), maxNumResults, tradeComparatorXOX);
      } else if (maxHops > 1 && pairs.length > 1) {
        const pairsExcludingThisPair = pairs.slice(0, i).concat(pairs.slice(i + 1, pairs.length));
        TradeXOX.bestTradeExactIn(pairsExcludingThisPair, currencyAmountIn, currencyOut, {
          maxNumResults,
          maxHops: maxHops - 1
        }, [...currentPairs, pair], amountOut, bestTrades);
      }
    }
    return bestTrades;
  }
  worstExecutionPrice(slippageTolerance) {
    return new Price5(this.inputAmount.currency, this.outputAmount.currency, this.maximumAmountIn(slippageTolerance).quotient, this.minimumAmountOut(slippageTolerance).quotient);
  }
  static bestTradeExactOut(pairs, currencyIn, currencyAmountOut, { maxNumResults = 3, maxHops = 3 } = {}, currentPairs = [], nextAmountOut = currencyAmountOut, bestTrades = []) {
    invariant6(pairs.length > 0, "PAIRS");
    invariant6(maxHops > 0, "MAX_HOPS");
    invariant6(currencyAmountOut === nextAmountOut || currentPairs.length > 0, "INVALID_RECURSION");
    const amountOut = nextAmountOut.wrapped;
    const tokenIn = currencyIn.wrapped;
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];
      if (!pair.token0.equals(amountOut.currency) && !pair.token1.equals(amountOut.currency))
        continue;
      if (pair.reserve0.equalTo(ZERO3) || pair.reserve1.equalTo(ZERO3))
        continue;
      let amountIn;
      try {
        ;
        [amountIn] = pair.getInputAmountXOX(amountOut);
      } catch (error) {
        if (error.isInsufficientReservesError) {
          continue;
        }
        throw error;
      }
      if (amountIn.currency.equals(tokenIn)) {
        sortedInsert(bestTrades, new TradeXOX(new Route([pair, ...currentPairs], currencyIn, currencyAmountOut.currency), currencyAmountOut, TradeType2.EXACT_OUTPUT), maxNumResults, tradeComparatorXOX);
      } else if (maxHops > 1 && pairs.length > 1) {
        const pairsExcludingThisPair = pairs.slice(0, i).concat(pairs.slice(i + 1, pairs.length));
        TradeXOX.bestTradeExactOut(pairsExcludingThisPair, currencyIn, currencyAmountOut, {
          maxNumResults,
          maxHops: maxHops - 1
        }, [pair, ...currentPairs], amountIn, bestTrades);
      }
    }
    return bestTrades;
  }
};

// src/router.ts
import { TradeType as TradeType3 } from "@pancakeswap/swap-sdk-core";
import invariant7 from "tiny-invariant";
function toHex(currencyAmount) {
  return `0x${currencyAmount.quotient.toString(16)}`;
}
var ZERO_HEX = "0x0";
var Router = class {
  constructor() {
  }
  static swapCallParameters(trade, options) {
    const etherIn = trade.inputAmount.currency.isNative;
    const etherOut = trade.outputAmount.currency.isNative;
    invariant7(!(etherIn && etherOut), "ETHER_IN_OUT");
    invariant7(!("ttl" in options) || options.ttl > 0, "TTL");
    const to = validateAndParseAddress(options.recipient);
    const amountIn = toHex(trade.maximumAmountIn(options.allowedSlippage));
    const amountOut = toHex(trade.minimumAmountOut(options.allowedSlippage));
    const path = trade.route.path.map((token) => token.address);
    const deadline = "ttl" in options ? `0x${(Math.floor(new Date().getTime() / 1e3) + options.ttl).toString(16)}` : `0x${options.deadline.toString(16)}`;
    const useFeeOnTransfer = Boolean(options.feeOnTransfer);
    let methodName;
    let args;
    let value;
    switch (trade.tradeType) {
      case TradeType3.EXACT_INPUT:
        if (etherIn) {
          methodName = useFeeOnTransfer ? "swapExactETHForTokensSupportingFeeOnTransferTokens" : "swapExactETHForTokens";
          args = [amountOut, path, to, deadline];
          value = amountIn;
        } else if (etherOut) {
          methodName = useFeeOnTransfer ? "swapExactTokensForETHSupportingFeeOnTransferTokens" : "swapExactTokensForETH";
          args = [amountIn, amountOut, path, to, deadline];
          value = ZERO_HEX;
        } else {
          methodName = useFeeOnTransfer ? "swapExactTokensForTokensSupportingFeeOnTransferTokens" : "swapExactTokensForTokens";
          args = [amountIn, amountOut, path, to, deadline];
          value = ZERO_HEX;
        }
        break;
      case TradeType3.EXACT_OUTPUT:
        invariant7(!useFeeOnTransfer, "EXACT_OUT_FOT");
        if (etherIn) {
          methodName = "swapETHForExactTokens";
          args = [amountOut, path, to, deadline];
          value = amountIn;
        } else if (etherOut) {
          methodName = "swapTokensForExactETH";
          args = [amountOut, amountIn, path, to, deadline];
          value = ZERO_HEX;
        } else {
          methodName = "swapTokensForExactTokens";
          args = [amountOut, amountIn, path, to, deadline];
          value = ZERO_HEX;
        }
        break;
    }
    return {
      methodName,
      args,
      value
    };
  }
};

// src/routerXox.ts
import { TradeType as TradeType4 } from "@pancakeswap/swap-sdk-core";
import invariant8 from "tiny-invariant";
function toHex2(currencyAmount) {
  return `0x${currencyAmount.quotient.toString(16)}`;
}
var ZERO_HEX2 = "0x0";
var RouterXOX = class {
  constructor() {
  }
  static swapCallParameters(trade, options) {
    const etherIn = trade.inputAmount.currency.isNative;
    const etherOut = trade.outputAmount.currency.isNative;
    invariant8(!(etherIn && etherOut), "ETHER_IN_OUT");
    invariant8(!("ttl" in options) || options.ttl > 0, "TTL");
    const ref = validateAndParseAddress(options.ref);
    const amountIn = toHex2(trade.maximumAmountIn(options.allowedSlippage));
    const amountOut = toHex2(trade.minimumAmountOut(options.allowedSlippage));
    const path = trade.route.path.map((token) => token.address);
    const deadline = "ttl" in options ? `0x${(Math.floor(new Date().getTime() / 1e3) + options.ttl).toString(16)}` : `0x${options.deadline.toString(16)}`;
    const useFeeOnTransfer = Boolean(options.feeOnTransfer);
    let methodName;
    let args;
    let value;
    switch (trade.tradeType) {
      case TradeType4.EXACT_INPUT:
        methodName = "swapExactTokensForTokens";
        args = [amountIn, amountOut, path, ref, deadline];
        value = ZERO_HEX2;
        break;
      case TradeType4.EXACT_OUTPUT:
        invariant8(!useFeeOnTransfer, "EXACT_OUT_FOT");
        methodName = "swapTokensForExactTokens";
        args = [amountOut, amountIn, path, ref, deadline];
        value = ZERO_HEX2;
        break;
    }
    return {
      methodName,
      args,
      value
    };
  }
};

// src/index.ts
export * from "@pancakeswap/swap-sdk-core";
export {
  ChainId,
  ERC20Token,
  FACTORY_ADDRESS,
  FACTORY_ADDRESS_MAP,
  INIT_CODE_HASH,
  INIT_CODE_HASH_MAP,
  JSBI4 as JSBI,
  LINEA_TESTNET,
  NATIVE,
  NEO_EVM_TESTNET,
  Native,
  PAIR_XOX_BUSD,
  Pair,
  Route,
  Router,
  RouterXOX,
  Trade,
  TradeXOX,
  USD_ADDRESS,
  WBNB,
  WETH9,
  WMATIC,
  WNATIVE,
  XOX_ADDRESS,
  _90,
  _9970,
  computePairAddress,
  computePriceImpact,
  inputOutputComparator,
  inputOutputComparatorXOX,
  tradeComparator,
  tradeComparatorXOX
};
