import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="14.5" stroke="url(#paint0_linear_16_403)" />
      <path
        d="M19.6009 8H22.0544L16.6943 14.1262L23 22.4626H18.0627L14.1957 17.4066L9.77087 22.4626H7.31595L13.049 15.9099L7 8H12.0626L15.5581 12.6213L19.6009 8ZM18.7399 20.994H20.0993L11.3239 9.39137H9.86506L18.7399 20.994Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16_403"
          x1="-2.37624"
          y1="8.85217e-09"
          x2="36.7596"
          y2="3.64161"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B809B5" />
          <stop offset="0.510417" stopColor="#ED1C51" />
          <stop offset="1" stopColor="#FFB000" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Icon;
