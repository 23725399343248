import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21Z"
      stroke="url(#paint0_linear_6997_11597)"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M14.0904 13.5906L16.7243 7.76465L10.9085 10.4085L8.52197 15.977L14.0904 13.5906Z"
      stroke="url(#paint1_linear_6997_11597)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6997_11597"
        x1="3.5"
        y1="3"
        x2="24.1626"
        y2="6.9025"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6473FF" />
        <stop offset="1" stopColor="#A35AFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6997_11597"
        x1="8.52197"
        y1="7.76465"
        x2="17.9383"
        y2="9.54093"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6473FF" />
        <stop offset="1" stopColor="#A35AFF" />
      </linearGradient>
    </defs>
  </svg>
);

export default Icon;
