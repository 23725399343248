function TradeIcon() {
  return (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.1873 4.35359L13.0206 1.18692C12.9468 1.11311 12.8592 1.05456 12.7627 1.01461C12.6663 0.974663 12.5629 0.954102 12.4585 0.954102C12.2477 0.954102 12.0455 1.03785 11.8964 1.18692C11.7474 1.336 11.6636 1.53819 11.6636 1.74901C11.6636 1.95983 11.7474 2.16202 11.8964 2.31109L13.7173 4.12401H4.54186C4.3319 4.12401 4.13053 4.20742 3.98207 4.35588C3.8336 4.50435 3.75019 4.70571 3.75019 4.91567C3.75019 5.12564 3.8336 5.327 3.98207 5.47547C4.13053 5.62393 4.3319 5.70734 4.54186 5.70734H15.6252C15.7815 5.70656 15.9341 5.65953 16.0637 5.57219C16.1933 5.48484 16.2941 5.36108 16.3535 5.21651C16.4141 5.07234 16.4307 4.91344 16.4011 4.75986C16.3715 4.60629 16.2971 4.46492 16.1873 4.35359ZM12.4585 7.29067H1.37519C1.2189 7.29145 1.06633 7.33848 0.936718 7.42583C0.807106 7.51318 0.706249 7.63693 0.646858 7.78151C0.586233 7.92568 0.569669 8.08458 0.599257 8.23815C0.628845 8.39173 0.703259 8.5331 0.813108 8.64442L3.97978 11.8111C4.05337 11.8853 4.14093 11.9442 4.2374 11.9844C4.33387 12.0246 4.43735 12.0453 4.54186 12.0453C4.64637 12.0453 4.74984 12.0246 4.84631 11.9844C4.94279 11.9442 5.03035 11.8853 5.10394 11.8111C5.17814 11.7375 5.23704 11.6499 5.27723 11.5535C5.31742 11.457 5.33812 11.3535 5.33812 11.249C5.33812 11.1445 5.31742 11.041 5.27723 10.9446C5.23704 10.8481 5.17814 10.7605 5.10394 10.6869L3.28311 8.87401H12.4585C12.6685 8.87401 12.8699 8.7906 13.0183 8.64213C13.1668 8.49367 13.2502 8.2923 13.2502 8.08234C13.2502 7.87238 13.1668 7.67101 13.0183 7.52255C12.8699 7.37408 12.6685 7.29067 12.4585 7.29067Z"
        fill="black"
      />
    </svg>
  )
}

export default TradeIcon
