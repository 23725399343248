// chains/chains.ts
var avalandche = {
  id: 43114,
  name: "Avalanche C-Chain",
  network: "avalanche",
  rpcUrls: {
    default: "https://rpc.ankr.com/avalanche"
  },
  nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
  blockExplorers: {
    default: {
      name: "snowtrace",
      url: "https://snowtrace.io/"
    }
  }
};
var avalandcheFuji = {
  id: 43113,
  name: "Avalanche Fuji",
  network: "avalanche-fuji",
  rpcUrls: {
    default: "https://rpc.ankr.com/avalanche_fuji"
  },
  nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
  blockExplorers: {
    default: {
      name: "snowtrace",
      url: "https://testnet.snowtrace.io/"
    }
  },
  testnet: true
};
var fantomOpera = {
  id: 250,
  name: "Fantom Opera",
  network: "fantom",
  nativeCurrency: { name: "Fantom", symbol: "FTM", decimals: 18 },
  rpcUrls: {
    default: "https://rpc.ftm.tools"
  },
  blockExplorers: {
    default: {
      name: "FTMScan",
      url: "https://ftmscan.com"
    }
  }
};
var fantomTestnet = {
  id: 4002,
  name: "Fantom Testnet",
  network: "fantom-testnet",
  nativeCurrency: { name: "Fantom", symbol: "FTM", decimals: 18 },
  rpcUrls: {
    default: "https://rpc.testnet.fantom.network"
  },
  blockExplorers: {
    default: {
      name: "FTMScan",
      url: "https://testnet.ftmscan.com"
    }
  },
  testnet: true
};
var bscExplorer = { name: "BscScan", url: "https://bscscan.com" };
var bsc = {
  id: 56,
  name: "BNB Chain",
  network: "bsc",
  rpcUrls: {
    public: "https://bsc-dataseed.bnbchain.org",
    default: "https://bsc-dataseed.bnbchain.org"
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer
  },
  nativeCurrency: {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 15921452
  }
};
var bscTest = {
  id: 97,
  name: "BNB Chain Testnet",
  network: "bsc-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Binance Chain Native Token",
    symbol: "BNB"
  },
  rpcUrls: {
    public: "https://bsc-testnet-dataseed.bnbchain.org",
    default: "https://bsc-testnet-dataseed.bnbchain.org"
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://testnet.bscscan.com" }
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 17422483
  },
  testnet: true
};
var mainnet = {
  id: 1,
  name: "Ethereum",
  network: "mainnet",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://eth-mainnet.g.alchemy.com/v2/OUbNK0UxmBbmI2_G7a1Ytrw1kGA1-wXm",
    default: "https://eth-mainnet.g.alchemy.com/v2/OUbNK0UxmBbmI2_G7a1Ytrw1kGA1-wXm"
  },
  blockExplorers: {
    default: { name: "Etherscan", url: "https://etherscan.io/" }
  }
};
var goerli = {
  id: 5,
  name: "Goerli",
  network: "goerli",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://eth-goerli.g.alchemy.com/v2/ihTj-Za_e4vpSsy3R65jobkf6vo7ZoND",
    default: "https://eth-goerli.g.alchemy.com/v2/ihTj-Za_e4vpSsy3R65jobkf6vo7ZoND"
  },
  blockExplorers: {
    default: { name: "Etherscan", url: "https://goerli.etherscan.io/" }
  },
  testnet: true
};
var arbitrum = {
  id: 42161,
  name: "Arbitrum One",
  network: "arbitrum",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://arb1.arbitrum.io/rpc",
    default: "https://arb1.arbitrum.io/rpc"
  },
  blockExplorers: {
    default: { name: "Arbitrum", url: "https://arbiscan.io/" }
  }
};
var arbitrum_testnet = {
  id: 421613,
  name: "Arbitrum Goerli",
  network: "arbitrum-goerli",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "AGOR"
  },
  rpcUrls: {
    public: "https://endpoints.omniatech.io/v1/arbitrum/goerli/public",
    default: "https://endpoints.omniatech.io/v1/arbitrum/goerli/public"
  },
  blockExplorers: {
    default: { name: "Arbitrum", url: "https://goerli-rollup-explorer.arbitrum.io/" }
  }
};
var polygon = {
  id: 137,
  name: "Polygon Mainnet",
  network: "polygon",
  nativeCurrency: {
    decimals: 18,
    name: "MATIC",
    symbol: "MATIC"
  },
  rpcUrls: {
    public: "https://polygon-rpc.com",
    default: "https://polygon-rpc.com"
  },
  blockExplorers: {
    default: { name: "Polygon", url: "https://polygonscan.com/" }
  }
};
var poligon_testnet = {
  id: 80001,
  name: "Mumbai Testnet",
  network: "mumbai-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "MATIC",
    symbol: "MATIC"
  },
  rpcUrls: {
    public: "https://nd-050-902-684.p2pify.com/5ff61f3510b44606f81447f7ad54b0d6",
    default: "https://nd-050-902-684.p2pify.com/5ff61f3510b44606f81447f7ad54b0d6"
  },
  blockExplorers: {
    default: { name: "Polygon", url: "https://mumbai.polygonscan.com/" }
  }
};
var zksync = {
  id: 324,
  name: "zkSync Era Mainnet",
  network: "zksync",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://mainnet.era.zksync.io",
    default: "https://mainnet.era.zksync.io"
  },
  blockExplorers: {
    default: { name: "zkSync", url: "https://explorer.zksync.io/" }
  }
};
var zksync_testnet = {
  id: 280,
  name: "zkSync Era Testnet",
  network: "zksync-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://testnet.era.zksync.dev",
    default: "https://testnet.era.zksync.dev"
  },
  blockExplorers: {
    default: { name: "zkSync", url: "https://goerli.explorer.zksync.io/" }
  }
};
var optimism = {
  id: 10,
  name: "Optimism",
  network: "optimism",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://optimism-mainnet.public.blastapi.io",
    default: "https://optimism-mainnet.public.blastapi.io"
  },
  blockExplorers: {
    default: { name: "Optimism", url: "https://optimistic.etherscan.io/" }
  }
};
var optimism_testnet = {
  id: 420,
  name: "Optimism Goerli Testnet",
  network: "optimism-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://endpoints.omniatech.io/v1/op/goerli/public",
    default: "https://endpoints.omniatech.io/v1/op/goerli/public"
  },
  blockExplorers: {
    default: { name: "Optimism", url: "https://optimism.io/" }
  }
};
var linea_testnet = {
  id: 59140,
  name: "Linea Goerli Testnet",
  network: "linea-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://rpc.goerli.linea.build",
    default: "https://rpc.goerli.linea.build"
  },
  blockExplorers: {
    default: { name: "Linea", url: "https://goerli.lineascan.build" }
  }
};
var neo_evm_testnet = {
  id: 2970385,
  name: "NeoEVM Chain",
  network: "Neo devnet",
  nativeCurrency: {
    decimals: 18,
    name: "Gas",
    symbol: "GAS"
  },
  rpcUrls: {
    public: "https://evm.ngd.network:32331",
    default: "https://evm.ngd.network:32331"
  },
  blockExplorers: {
    default: { name: "NeoEVM", url: "https://evm.ngd.network" }
  }
};
var zeta_testnet = {
  id: 7001,
  name: "ZetaChain Testnet",
  network: "zeta-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "ZetaChain",
    symbol: "ZETA"
  },
  rpcUrls: {
    public: "https://zetachain-athens-evm.blockpi.network/v1/rpc/public",
    default: "https://zetachain-athens-evm.blockpi.network/v1/rpc/public"
  },
  blockExplorers: {
    default: { name: "ZetaChain", url: "https://explorer.zetachain.com/" }
  }
};
var manta_testnet = {
  id: 3441005,
  name: "Manta Testnet",
  network: "manta-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://pacific-rpc.testnet.manta.network/http",
    default: "https://pacific-rpc.testnet.manta.network/http"
  },
  blockExplorers: {
    default: { name: "Manta", url: "https://pacific-explorer.testnet.manta.network/" }
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 1017979
  }
};
var blast = {
  id: 81457,
  name: "Blast",
  network: "blast",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://rpc.blast.io",
    default: "https://rpc.blast.io"
  },
  blockExplorers: {
    default: { name: "Blast", url: "https://blastscan.io/" }
  },
  multicall: {
    address: "0x59Cb9b33d12b4bF4d5cF104748F80C01b7e4E56A",
    blockCreated: 929288
  }
};
var blast_testnet = {
  id: 168587773,
  name: "Blast Testnet",
  network: "blast-sepolia-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://blast-sepolia.drpc.org",
    default: "https://blast-sepolia.drpc.org"
  },
  blockExplorers: {
    default: { name: "Blast", url: "https://sepolia.blastscan.io/" }
  },
  multicall: {
    address: "0xCc7283a00481de9AdBE379c3c2459691a6ee274a",
    blockCreated: 2483937
  }
};
var base = {
  id: 8453,
  name: "Base",
  network: "base",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH"
  },
  rpcUrls: {
    public: "https://base.llamarpc.com",
    default: "https://base.llamarpc.com"
  },
  blockExplorers: {
    default: { name: "Base", url: "https://basescan.org/" }
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 4910339
  }
};
export {
  arbitrum,
  arbitrum_testnet,
  avalandche,
  avalandcheFuji,
  base,
  blast,
  blast_testnet,
  bsc,
  bscTest,
  fantomOpera,
  fantomTestnet,
  goerli,
  linea_testnet,
  mainnet,
  manta_testnet,
  neo_evm_testnet,
  optimism,
  optimism_testnet,
  poligon_testnet,
  polygon,
  zeta_testnet,
  zksync,
  zksync_testnet
};
