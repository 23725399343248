import { ChainId, JSBI, Percent, Token, WNATIVE } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import {
  bscTokens,
  bscTestnetTokens,
  USDC,
  USDT,
  BUSD,
  WBTC_ETH,
  BNB_ETHEREUM,
  MATIC_ETHEREUM,
  UNI_ETHEREUM,
  SUSHI_ETHEREUM,
  USDC_ETHEREUM,
  SHIB_ETHEREUM,
  MATIC_BSC,
  LINK_ETHEREUM,
  WBTC_GOERLI,
  BNB_GOERLI,
  MATIC_GOERLI,
  UNI_GOERLI,
  SUSHI_GOERLI,
  USDC_GOERLI,
  LINK_GOERLI,
  SHIB_GOERLI,
  BTCB_BSC_TESTNET,
  ETH_BSC_TESTNET,
  DOGE_BSC_TESTNET,
  MATIC_BSC_TESTNET,
  CAKE_BSC_TESTNET,
  LINK_BSC_TESTNET,
  ADA_BSC_TESTNET,
  XOX_BSC_TESTNET,
  XOX_GOERLI,
  USDT_BSC_TESTNET,
  USDC_NEO_TESTNET,
  XOX_NEO_TESTNET,
  USDC_ZETA_TESTNET,
  XOX_ZETA_TESTNET,
  XOX_MANTA_TESTNET,
  USDC_MANTA_TESTNET,
  XOX_BLAST_TESTNET,
  USD,
  XOX_BLAST,
  USDB_BLAST_TESTNET,
  USDB_BLAST,
} from '@pancakeswap/tokens'
import { ChainMap, ChainTokenList } from './types'

export const ROUTER_ADDRESS: ChainMap<string> = {
  [ChainId.ETHEREUM]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  [ChainId.RINKEBY]: '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
  // [ChainId.GOERLI]: '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
  [ChainId.GOERLI]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  [ChainId.BSC]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  [ChainId.BSC_TESTNET]: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
  [ChainId.NEO_EVM_TESTNET]: '0x92e72A02719122aB0E5A0323Db6b3579586db8AD',
  [ChainId.ZETA_TESTNET]: '0x9b8AAb91ea36857763e9552916874ceA18F35b56',
  [ChainId.MANTA_TESTNET]: '0x985c7865d1C525B2937AcFF711A61A826bd01139',
  [ChainId.BLAST]: '0x98994a9a7a2570367554589189dc9772241650f6',
  [ChainId.BLAST_TESTNET]: '0xef1Fb411B7bE8ADe21Cb58EBcD7b062E090A7EA1',
}
export const ROUTER_XOX: ChainMap<string> = {
  [ChainId.ETHEREUM]: '',
  [ChainId.RINKEBY]: '',
  [ChainId.GOERLI]: '0xB4f3c1C084c7fd5539991aB926e9c159a3E6311c',
  [ChainId.BSC]: '',
  [ChainId.BSC_TESTNET]: '0x0d06db96396b110cf0c1e191E444320746BA04DB',
  [ChainId.NEO_EVM_TESTNET]: '0x0Dd0461eEce2255894549FE3C6881995A955C252',
  [ChainId.ZETA_TESTNET]: '0xe5C7ED15BB2684EbAc8A7FF1034DF32F0BeFD7F5',
  [ChainId.MANTA_TESTNET]: '0xef1Fb411B7bE8ADe21Cb58EBcD7b062E090A7EA1',
  [ChainId.BLAST_TESTNET]: '',
}

export const XOX_ADDRESS: ChainMap<string> = {
  [ChainId.ETHEREUM]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  [ChainId.RINKEBY]: '',
  [ChainId.GOERLI]: '0x7C06f79a65Ff6d9520bf8604a37F20deA000A3d2',
  [ChainId.BSC]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  [ChainId.BSC_TESTNET]: '0xdd94e8a963BbD1513840aC38C5727fa5Da4a3095',
  [ChainId.ARBITRUM]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  [ChainId.ARBITRUM_TESTNET]: '0x90fded525b5E613c9dc0fE600d9b52a648386e2E',
  [ChainId.POLYGON]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  [ChainId.POLYGON_TESTNET]: '0x47869A5dcECd2593F02d27f65cB4A5278DE16CD2',
  [ChainId.ZKSYNC]: '0x7eCf006f7c45149B457e9116392279CC8A630F14',
  [ChainId.ZKSYNC_TESTNET]: '0x34a12190B65b684A5CC1ec27a6f8d924D1a317be',
  [ChainId.OPTIMISM]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  [ChainId.OPTIMISM_TESTNET]: '0xce221120F145B456ba41b370F11D5E536eCD2BcB',
  [ChainId.NEO_EVM_TESTNET]: '0x3B6dFC89760d5B6F64C043A74210ddF91264Beef',
  [ChainId.ZETA_TESTNET]: '0x3B6dFC89760d5B6F64C043A74210ddF91264Beef',
  [ChainId.MANTA_TESTNET]: '0x56B09E39168b141E43c49eeF261ed9A5f105A6DF',
  [ChainId.BLAST]: '0x0f5C78f152152dDA52a2ea45B0a8C10733010748',
  [ChainId.BLAST_TESTNET]: '0x90fded525b5E613c9dc0fE600d9b52a648386e2E',
}

export const XOX_LP: ChainMap<string> = {
  [ChainId.ETHEREUM]: '',
  [ChainId.RINKEBY]: '',
  [ChainId.GOERLI]: '0x4B6b6cAD103521832D78F140E89A22c70703d72c',
  [ChainId.BSC]: '',
  [ChainId.BSC_TESTNET]: '0x6e2f695A731DA11fce2F996eD26BffDd9E35e0AF',
  [ChainId.NEO_EVM_TESTNET]: '0xFd8D6370eb37B62194d5CC0Dcdeb24A61878c8e7',
  [ChainId.ZETA_TESTNET]: '0x76F4F0fcB3E39Df09D4A3617BF71BFFca528c5D5',
  [ChainId.MANTA_TESTNET]: '0xC1A128E71C799eDF22785e80fB33a77a87296C8f',
  [ChainId.BLAST_TESTNET]: '',
}

export const XOX_NATIVE_LP: ChainMap<string> = {
  [ChainId.ETHEREUM]: '0xA32F2cb7787696e75ffABEB6EBf88bdf72E65efE',
  [ChainId.RINKEBY]: '',
  [ChainId.GOERLI]: '0x4cDF5e879027da0151507aE3F9073c7B604a70C4',
  [ChainId.BSC]: '0x8d32796a536A73AC81208e8579047ba9D42cbb22',
  [ChainId.BSC_TESTNET]: '0xeBaA9Ab545de44F38170218082fE25ff30C69D7F',
  [ChainId.NEO_EVM_TESTNET]: '0xc08501Bc4031F559ac033C35e7Fa5850EDCb87Fa',
  [ChainId.ZETA_TESTNET]: '0x26395Ac8E0B0E3215b8967C5c90b3bc32F65ebFC',
  [ChainId.MANTA_TESTNET]: '0x588be3F16FB1969E4cd792eEAD63d69B02c3b36E',
  [ChainId.BLAST_TESTNET]: '',
}

export const USD_ADDRESS: ChainMap<string> = {
  [ChainId.ETHEREUM]: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  [ChainId.GOERLI]: '0xD898D309dAb33130EA57E8F106238ae4b76329f4',
  [ChainId.BSC]: '0x55d398326f99059fF775485246999027B3197955',
  [ChainId.BSC_TESTNET]: '0xc60a52351918c13eF3B27F72e5E71877ca3cB13A',
  [ChainId.POLYGON]: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  [ChainId.POLYGON_TESTNET]: '0x242D918B740358829b56b0CCb7b467323D21846D',
  [ChainId.ARBITRUM]: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
  [ChainId.ZKSYNC]: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
  [ChainId.OPTIMISM]: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
  [ChainId.NEO_EVM_TESTNET]: '0xce221120F145B456ba41b370F11D5E536eCD2BcB',
  [ChainId.ZETA_TESTNET]: '0x3e3d551CAB18feca293b059B281f3b8D7C688A0A',
  [ChainId.MANTA_TESTNET]: '0x9b8AAb91ea36857763e9552916874ceA18F35b56',
  [ChainId.BLAST]: '0x4300000000000000000000000000000000000003',
  [ChainId.BLAST_TESTNET]: '0x3B6dFC89760d5B6F64C043A74210ddF91264Beef',
}

export const USDC_ADDRESS: ChainMap<string> = {
  [ChainId.ETHEREUM]: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  [ChainId.GOERLI]: '0xD898D309dAb33130EA57E8F106238ae4b76329f4',
  [ChainId.BSC]: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  [ChainId.BSC_TESTNET]: '0xD0C27B75734784f8D2E351A70758b23753bCd525',
  [ChainId.POLYGON]: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  [ChainId.POLYGON_TESTNET]: '0x242D918B740358829b56b0CCb7b467323D21846D',
  [ChainId.ARBITRUM]: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
  [ChainId.ZKSYNC]: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
  [ChainId.OPTIMISM]: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
  [ChainId.NEO_EVM_TESTNET]: '0xce221120F145B456ba41b370F11D5E536eCD2BcB',
  [ChainId.ZETA_TESTNET]: '0x3e3d551CAB18feca293b059B281f3b8D7C688A0A',
  [ChainId.MANTA_TESTNET]: '0x9b8AAb91ea36857763e9552916874ceA18F35b56',
  [ChainId.BLAST_TESTNET]: '',
}

export const USDT_ADDRESS: ChainMap<string> = {
  [ChainId.ETHEREUM]: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  [ChainId.GOERLI]: '0x1b57aF4ab903fD70f96Ff033498dF44D7B61201C',
  [ChainId.BSC]: '0x55d398326f99059ff775485246999027b3197955',
  [ChainId.BSC_TESTNET]: '0xc60a52351918c13ef3b27f72e5e71877ca3cb13a',
  [ChainId.POLYGON]: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  [ChainId.POLYGON_TESTNET]: '0x773B68156b7EbEFD823A3eA4651f60c71A03A336',
  [ChainId.ARBITRUM]: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  [ChainId.ZKSYNC]: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
  [ChainId.OPTIMISM]: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
  [ChainId.NEO_EVM_TESTNET]: '0xce221120F145B456ba41b370F11D5E536eCD2BcB',
  [ChainId.ZETA_TESTNET]: '0x3e3d551CAB18feca293b059B281f3b8D7C688A0A',
  [ChainId.MANTA_TESTNET]: '0x9b8AAb91ea36857763e9552916874ceA18F35b56',
  [ChainId.BLAST_TESTNET]: '',
}

export const USD_DECIMALS: ChainMap<number> = {
  [ChainId.ETHEREUM]: 6,
  [ChainId.RINKEBY]: 6,
  [ChainId.GOERLI]: 6,
  [ChainId.BSC]: 18,
  [ChainId.BSC_TESTNET]: 18,
  [ChainId.POLYGON]: 6,
  [ChainId.POLYGON_TESTNET]: 6,
  [ChainId.ARBITRUM]: 6,
  [ChainId.ZKSYNC]: 6,
  [ChainId.OPTIMISM]: 6,
  [ChainId.NEO_EVM_TESTNET]: 6,
  [ChainId.ZETA_TESTNET]: 6,
  [ChainId.MANTA_TESTNET]: 6,
  [ChainId.BLAST_TESTNET]: 18,
  [ChainId.BLAST]: 18,
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.ETHEREUM]: [WNATIVE[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
  [ChainId.GOERLI]: [WNATIVE[ChainId.GOERLI]],
  [ChainId.RINKEBY]: [WNATIVE[ChainId.RINKEBY], USDC[ChainId.RINKEBY], BUSD[ChainId.RINKEBY]],
  [ChainId.BSC]: [
    bscTokens.wbnb,
    bscTokens.cake,
    bscTokens.busd,
    bscTokens.usdt,
    bscTokens.btcb,
    bscTokens.eth,
    bscTokens.usdc,
  ],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd],
  [ChainId.NEO_EVM_TESTNET]: [USDC[ChainId.NEO_EVM_TESTNET], WNATIVE[ChainId.NEO_EVM_TESTNET], XOX_NEO_TESTNET],
  [ChainId.ZETA_TESTNET]: [USDC[ChainId.ZETA_TESTNET], WNATIVE[ChainId.ZETA_TESTNET], XOX_NEO_TESTNET],
  [ChainId.MANTA_TESTNET]: [USDC[ChainId.MANTA_TESTNET], WNATIVE[ChainId.MANTA_TESTNET], XOX_MANTA_TESTNET],
  [ChainId.BLAST]: [USD[ChainId.BLAST], WNATIVE[ChainId.BLAST], XOX_BLAST],
  [ChainId.BLAST_TESTNET]: [USD[ChainId.BLAST_TESTNET], WNATIVE[ChainId.BLAST_TESTNET], XOX_BLAST_TESTNET],
}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {
    // SNFTS-SFUND
    [bscTokens.snfts.address]: [bscTokens.sfund],
  },
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.ETHEREUM]: [
    WBTC_ETH,
    BNB_ETHEREUM,
    MATIC_ETHEREUM,
    UNI_ETHEREUM,
    SUSHI_ETHEREUM,
    USDC_ETHEREUM,
    LINK_ETHEREUM,
    SHIB_ETHEREUM,
  ],
  [ChainId.RINKEBY]: [USDC[ChainId.RINKEBY], WNATIVE[ChainId.RINKEBY], BUSD[ChainId.RINKEBY]],
  [ChainId.GOERLI]: [
    WBTC_GOERLI,
    BNB_GOERLI,
    MATIC_GOERLI,
    UNI_GOERLI,
    SUSHI_GOERLI,
    USDC_GOERLI,
    LINK_GOERLI,
    SHIB_GOERLI,
    XOX_GOERLI,
  ],
  [ChainId.BSC]: [
    bscTokens.btcb,
    bscTokens.eth,
    bscTokens.usdt,
    bscTokens.doge,
    MATIC_BSC,
    bscTokens.cake,
    bscTokens.link,
    bscTokens.ada,
  ],
  [ChainId.BSC_TESTNET]: [
    BTCB_BSC_TESTNET,
    ETH_BSC_TESTNET,
    USDT_BSC_TESTNET,
    DOGE_BSC_TESTNET,
    MATIC_BSC_TESTNET,
    CAKE_BSC_TESTNET,
    LINK_BSC_TESTNET,
    ADA_BSC_TESTNET,
    XOX_BSC_TESTNET,
  ],
  [ChainId.NEO_EVM_TESTNET]: [USDC_NEO_TESTNET, XOX_NEO_TESTNET],
  [ChainId.ZETA_TESTNET]: [USDC_ZETA_TESTNET, XOX_ZETA_TESTNET],
  [ChainId.MANTA_TESTNET]: [USDC_MANTA_TESTNET, XOX_MANTA_TESTNET],
  [ChainId.BLAST]: [USDB_BLAST, XOX_BLAST],
  [ChainId.BLAST_TESTNET]: [USDB_BLAST_TESTNET, XOX_BLAST_TESTNET],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.ETHEREUM]: [USDC[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM], WBTC_ETH],
  [ChainId.RINKEBY]: [USDC[ChainId.RINKEBY], WNATIVE[ChainId.RINKEBY], BUSD[ChainId.RINKEBY]],
  [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.BSC]: [bscTokens.wbnb, bscTokens.dai, bscTokens.busd, bscTokens.usdt, bscTokens.cake],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd],
  [ChainId.NEO_EVM_TESTNET]: [USDC[ChainId.NEO_EVM_TESTNET], WNATIVE[ChainId.NEO_EVM_TESTNET]],
  [ChainId.ZETA_TESTNET]: [USDC[ChainId.ZETA_TESTNET], WNATIVE[ChainId.ZETA_TESTNET]],
  [ChainId.MANTA_TESTNET]: [USDC[ChainId.MANTA_TESTNET], WNATIVE[ChainId.MANTA_TESTNET]],
  [ChainId.BLAST]: [USD[ChainId.BLAST], WNATIVE[ChainId.BLAST]],
  [ChainId.BLAST_TESTNET]: [USD[ChainId.BLAST_TESTNET], WNATIVE[ChainId.BLAST_TESTNET]],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.ETHEREUM]: [
    [WNATIVE[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM]],
    [WBTC_ETH, WNATIVE[ChainId.ETHEREUM]],
    [WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
  ],
  [ChainId.BSC]: [
    [bscTokens.cake, bscTokens.wbnb],
    [bscTokens.busd, bscTokens.usdt],
    [bscTokens.dai, bscTokens.usdt],
  ],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE_BSC = new Percent(JSBI.BigInt(25), BIPS_BASE)
export const BASE_FEE_ETH = new Percent(JSBI.BigInt(30), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE_BSC = ONE_HUNDRED_PERCENT.subtract(BASE_FEE_BSC)
export const INPUT_FRACTION_AFTER_FEE_ETH = ONE_HUNDRED_PERCENT.subtract(BASE_FEE_ETH)
export const INPUT_FRACTION_AFTER_FEE: ChainMap<Percent> = {
  [ChainId.ETHEREUM]: INPUT_FRACTION_AFTER_FEE_ETH,
  [ChainId.RINKEBY]: INPUT_FRACTION_AFTER_FEE_ETH,
  [ChainId.GOERLI]: INPUT_FRACTION_AFTER_FEE_ETH,
  [ChainId.BSC]: INPUT_FRACTION_AFTER_FEE_BSC,
  [ChainId.BSC_TESTNET]: INPUT_FRACTION_AFTER_FEE_BSC,
  [ChainId.NEO_EVM_TESTNET]: INPUT_FRACTION_AFTER_FEE_ETH,
  [ChainId.ZETA_TESTNET]: INPUT_FRACTION_AFTER_FEE_ETH,
  [ChainId.MANTA_TESTNET]: INPUT_FRACTION_AFTER_FEE_ETH,
  [ChainId.BLAST]: INPUT_FRACTION_AFTER_FEE_ETH,
  [ChainId.BLAST_TESTNET]: INPUT_FRACTION_AFTER_FEE_ETH,
}

// BNB
export const DEFAULT_INPUT_CURRENCY = 'BNB'
// CAKE
export const DEFAULT_OUTPUT_CURRENCY = '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.xoxnet.io/products/pancakeswap-exchange/limit-orders'

export const EXCHANGE_PAGE_PATHS = ['/swap', '/limit-orders', 'liquidity', '/add', '/find', '/remove']

export const XOX_COINMARKETCAP_ID = 29087

export const SUGGESTED_BASES_ID = {
  // ETHEREUM

  [ChainId.ETHEREUM]: {
    '0X0F5C78F152152DDA52A2EA45B0A8C10733010748': 29087, // XOX
    ETH: 1027,
    '0XB8C77482E45F1F44DE1745F52C74426C631BDD52': 1839, // BNB
    '0X2260FAC5E5542A773AA44FBCFEDF7C193BC2C599': 3717, // WBTC
    '0X7D1AFA7B718FB893DB30A3ABC0CFC608AACFEBB0': 3890, // MATIC
    '0X1F9840A85D5AF5BF1D1762F925BDADDC4201F984': 7083, // UNI
    '0X6B3595068778DD592E39A122F4F5A5CF09C90FE2': 6758, // SUSHI
    '0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48': 3408, // USDC
    '0X514910771AF9CA656AF840DFF83E8264ECF986CA': 1975, // LINK
    '0X95AD61B0A150D79219DCF64E1E6CC01F0B64C4CE': 5994, // SHIB
    '0XDAC17F958D2EE523A2206206994597C13D831EC7': 825, // USDT
  },
  // BSC
  [ChainId.BSC]: {
    '0X0F5C78F152152DDA52A2EA45B0A8C10733010748': 29087, // XOX
    BNB: 1839,
    '0X2170ED0880AC9A755FD29B2688956BD959F933F8': 1027, // ETH
    '0X7130D2A12B9BCBFAE4F2634D864A1EE1CE3EAD9C': 4023, // BTCB
    '0X55D398326F99059FF775485246999027B3197955': 825, // USDT
    '0XBA2AE424D960C26247DD6C32EDC70B295C744C43': 74, // DOGE
    '0XCC42724C6683B7E57334C4E856F4C9965ED682BD': 3890, // MATIC BSC
    '0X0E09FABB73BD3ADE0A17ECC321FD13A19E81CE82': 7186, // CAKE
    '0XF8A0BF9CF54BB92F17374D9E9A321E6A111A51BD': 1975, // LINK BSC
    '0X3EE2200EFB3400FABB9AACF31297CBDD1D435D47': 2010, // ADA
    '0X8AC76A51CC950D9822D68B83FE1AD97B32CD580D': 3408, // USDC
  },
  // GOERLI
  [ChainId.GOERLI]: {
    '0X0F5C78F152152DDA52A2EA45B0A8C10733010748': 29087, // XOX
    ETH: 1027,
    '0X013CB3419DD78A3A24DF6F9151514752228C6960': 1839, // BNB
    '0X2A924BCD60450C7A354413D8B2C906A3173F9208': 3717, // WBTC TESTNET
    '0XB4BDF7ADDEE19C35ECCA122C852103FAE0B3FE0B': 3890, // MATIC TESTNET
    '0X1BC4803310B723B4F0A2C27411315432B7DB52DF': 7083, // UNI TESTNET
    '0X28362CD5D11A8CE0371B4768CD8EDF8A943CC2A9': 6758, // SUSHI TESTNET
    '0xD898D309dAb33130EA57E8F106238ae4b76329f4': 3408, // USDC TESTNET
    '0XD898D309DAB33130EA57E8F106238AE4B76329F4': 1975, // LINK TESTNET
    '0X5F933E0F9D17A4B28F229A2882E2E18DCEF266C7': 5994, // SHIB TESTNET
    '0X1B57AF4AB903FD70F96FF033498DF44D7B61201C': 825, // USDT
  },
  // BSC TESTNET
  [ChainId.BSC_TESTNET]: {
    '0X0F5C78F152152DDA52A2EA45B0A8C10733010748': 29087, // XOX
    BNB: 1839,
    '0XA3961366F8A7A5772C22A00788F1824D233B26ED': 4023, // BTCB  BSC TESTNET
    '0X65ECB72ADEB9B5DA68AA7DDD4A334546E7CD36E2': 1027, // ETH BSC TESTNET
    '0XC60A52351918C13EF3B27F72E5E71877CA3CB13A': 825, // USDT  BSC TESTNET
    '0XBB5A2336330C07C3B2B532210A1E5B93D747FB93': 74, // DOGE  BSC TESTNET
    '0X897F93CC7E82CC78F076C32AA45C723F84959A0D': 3890, // MATIC BSC TESTNET
    '0X638666506B6875A8755884090C41DEE985AC1F6D': 7186, // CAKE  BSC TESTNET
    '0X07BBDCD736CFC0406A3CED96B9F2289ACBCC4C76': 1975, // LINK BSC TESTNET
    '0X624BCFBB991CCEF8E790E58ED973D8E24CC66ECC': 2010, // ADA  BSC TESTNET
    '0XD0C27B75734784F8D2E351A70758B23753BCD525': 3408, // USDC
  },
  [ChainId.POLYGON]: {
    '0X0F5C78F152152DDA52A2EA45B0A8C10733010748': 29087, // XOX
    ETH: 1027,
    '0X2791BCA1F2DE4661ED88A30C99A7A9449AA84174': 3408, // USDC
    '0X8F3CF7AD23CD3CADBD9735AFF958023239C6A063': 4943,
    '0XA3FA99A148FA48D14ED51D610C367C61876997F1': 10238,
    '0X0D500B1D8E8EF31E21C99D1DB9A6444D3ADF1270': 8925,
    '0XC2132D05D31C914A87C6611C10748AEB04B58E8F': 825, // USDT
    '0X3A58A54C066FDC0F2D55FC9C89F0415C92EBF3C4': 18688,
    '0X9C2C5FD7B07E95EE044DDEBA0E97A665F142394F': 8104,
    '0X0621D647CECBFB64B79E44302C1933CB4F27054D': 6945,
  },
  [ChainId.POLYGON_TESTNET]: {
    ETH: 1027,
    '0X242D918B740358829B56B0CCB7B467323D21846D': 3408, // USDC
    '0X773B68156B7EBEFD823A3EA4651F60C71A03A336': 825, // USDT
  },
  [ChainId.ARBITRUM]: {
    ETH: 1027,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1': 2396,
    '0XAF88D065E77C8CC2239327C5EDB3A432268E5831': 3408, // USDC
    '0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': 4943,
    '0XFF970A61A04B1CA14834A43F5DE4533EBDDB5CC8': 3408, // USDC
    '0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': 825, // USDT
    '0X5979D7B546E38E414F7E9822514BE443A4800529': 12409,
    '0X912CE59144191C1204E64559FE8253A0E49E6548': 11841,
    '0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': 3717,
  },
  [ChainId.OPTIMISM]: {
    ETH: 1027,
    '0x4200000000000000000000000000000000000006': 2396,
    '0X7F5C764CBC14F9669B88837CA1490CCA17C31607': 3408, // USDC
    '0x4200000000000000000000000000000000000042': 11840,
    '0X94B008AA00579C1307B0EF2C499AD98A8CE58E58': 825, // USDT
    '0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': 4943,
    '0X1F32B1C2345538C0C6F582FCB022739C4A194EBB': 12409,
    '0XE0BB0D3DE8C10976511E5030CA403DBF4C25165B': 2837,
    '0X76FB31FB4AF56892A25E32CFC43DE717950C9278': 7278,
  },
  [ChainId.ZKSYNC]: {
    ETH: 1027,
    '0X5AEA5775959FBC2557CC8789BC1BF90A239D9A91': 2396,
    '0X3355DF6D4C9C3035724FD0E3914DE96A5A83AAF4': 3408, // USDC
    '0X493257FD37EDB34451F62EDF8D2A0C418852BA4C': 825, // USDT
    '0X8E86E46278518EFC1C5CED245CBA2C7E3EF11557': 20317,
    '0XBBEB516FB02A01611CBBE0453FE3C580D7281011': 3717,
    '0X6A5279E99CA7786FB13F827FC1FB4F61684933D6': 5805,
  },
  [ChainId.ARBITRUM_TESTNET]: {
    AGOR: 11841,
  },
  [ChainId.NEO_EVM_TESTNET]: {
    GAS: 1785,
    '0XCE221120F145B456BA41B370F11D5E536ECD2BCB': 3408, // USDC TESTNET
  },
  [ChainId.ZETA_TESTNET]: {
    // ZETA: 21259,
    '0X3E3D551CAB18FECA293B059B281F3B8D7C688A0A': 3408, // USDC TESTNET
  },
  [ChainId.MANTA_TESTNET]: {
    ETH: 1027,
    '0X9B8AAB91EA36857763E9552916874CEA18F35B56': 3408, // USDC TESTNET
  },
  [ChainId.BLAST_TESTNET]: {
    ETH: 1027,
    '0X90FDED525B5E613C9DC0FE600D9B52A648386E2E': 29087, // XOX TESTNET
    '0X3B6DFC89760D5B6F64C043A74210DDF91264BEEF': 29599, // USDB TESTNET
  },
  [ChainId.BLAST]: {
    ETH: 1027,
    '0X0F5C78F152152DDA52A2EA45B0A8C10733010748': 29087, // XOX
    '0X4300000000000000000000000000000000000003': 29599, // USDB
  },
}
