/* eslint-disable react/no-unknown-property */
import { SvgProps } from '@pancakeswap/uikit'
import React from 'react'

const TradingTerminal: React.FC<React.PropsWithChildren<SvgProps>> = () => {
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.35433 2.18847C3.06523 2.86974 1.396 4.99026 1.396 7.50065C1.396 10.011 3.06523 12.1316 5.35433 12.8128M17.2293 7.50065C17.2293 10.5612 14.7482 13.0423 11.6877 13.0423C8.62708 13.0423 6.146 10.5612 6.146 7.50065C6.146 4.44007 8.62708 1.95898 11.6877 1.95898C14.7482 1.95898 17.2293 4.44007 17.2293 7.50065Z"
        stroke="#515151"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default TradingTerminal
