/* eslint-disable react/no-unknown-property */
import { SvgProps } from '@pancakeswap/uikit'
import React from 'react'

const BulkSender: React.FC<React.PropsWithChildren<SvgProps>> = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 1.5L8.89937 10.1006M17.5 1.5L1.5 8.00678L8.89937 10.1006M17.5 1.5L10.9932 17.5L8.89937 10.1006"
        stroke="#515151"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default BulkSender
