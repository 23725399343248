function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
      <path
        fill="#515151"
        d="M8.5.188a8.313 8.313 0 10.001 16.626A8.313 8.313 0 008.5.187zm0 15.214a6.904 6.904 0 010-13.804 6.904 6.904 0 010 13.804zm.885-7.332l-.471-.11V5.468c.705.097 1.14.538 1.215 1.08.01.074.072.128.147.128h.833a.15.15 0 00.148-.163c-.113-1.156-1.065-1.898-2.336-2.026V3.88a.149.149 0 00-.148-.149H8.25a.149.149 0 00-.148.149v.612C6.789 4.62 5.76 5.346 5.76 6.7c0 1.254.924 1.86 1.895 2.091l.458.117v2.648c-.82-.11-1.28-.547-1.375-1.137a.148.148 0 00-.146-.123h-.858a.149.149 0 00-.148.162c.083 1.02.857 1.959 2.509 2.08v.582c0 .082.066.149.148.149h.527a.15.15 0 00.148-.15l-.003-.589c1.452-.128 2.492-.905 2.492-2.3-.002-1.288-.82-1.864-2.023-2.16zm-1.273-.301a2.713 2.713 0 01-.278-.093c-.627-.226-.919-.592-.919-1.063 0-.674.51-1.058 1.197-1.145V7.77zm.802 3.793V9.08c.057.016.11.03.163.04.878.268 1.173.639 1.173 1.208 0 .726-.546 1.162-1.336 1.232z"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_7"
          x1="-1.129"
          x2="20.558"
          y1="0.188"
          y2="2.206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#515151" />
          <stop offset="0.51" stopColor="#515151" />
          <stop offset="1" stopColor="##515151" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Icon
